import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from '@/components/shadcn/drawer.tsx';
import { AdDataProps } from '@/components/templates/AdCard';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { trpc } from '@/utils/trpc';
import { X } from '@mynaui/icons-react';
import { useState } from 'react';
import { TemplateDetailsAdBody } from './TemplateDetailsAdBody';
import { TemplateDetailsAdMedia } from './TemplateDetailsAdMedia';
import { TemplateDetailsLanderEmailBody } from './TemplateDetailsLanderEmailBody';
import { TemplateDetailsLanderEmailMedia } from './TemplateDetailsLanderEmailMedia';
import { Loader } from './custom-components/Loader';
import { ScrollArea } from './shadcn/scroll-area';
import { LandingAdDataProps } from './templates/LandingAdCard';

export default function TemplateDetails({
  open,
  onCloseDrawer,
  data,
  landingData,
  type,
}: {
  open: boolean;
  onCloseDrawer: () => void;
  data?: AdDataProps;
  type?: 'email' | 'landing-page';
  landingData?: LandingAdDataProps;
}) {
  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const [, copyToClipboard] = useCopyToClipboard();
  const { data: numberOfUsers } = trpc.getAdTemplateDownloads.useQuery({
    adId: (data?.Ad['Creative ID'] ?? landingData?.ID ?? '').toString(),
  });
  const { data: numberOfSaves } = trpc.getAdTemplateSaves.useQuery({
    adId: `${data?.Ad['Creative ID'] ?? landingData?.ID ?? ''}`,
  });

  const { data: adTemplateDetails, isLoading: isLoadingAdTemp } =
    trpc.posts.useQuery(
      {
        adID: data ? `${data.Ad?.atID}` : null,
        limit: 1,
        Ready: true,
      },
      {
        enabled: type == undefined,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    );
  const { data: emailTemplateDetails, isLoading: isLoadingEmailTemp } =
    trpc.getEmailTemplateById.useQuery(
      {
        emailTemplateId: landingData ? parseInt(`${landingData.ID}`) : 0,
      },
      {
        enabled: type == 'email',
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    );
  const { data: landerTemplateDetails, isLoading: isLoadingLanderTemp } =
    trpc.getLandingPageTemplateById.useQuery(
      {
        landingPageTemplateId: landingData ? parseInt(`${landingData.ID}`) : 0,
      },
      {
        enabled: type == 'landing-page',
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    );

  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    return `Posted ${date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}`;
  };

  return (
    <Drawer open={open} onOpenChange={onCloseDrawer}>
      {isLoadingEmailTemp || isLoadingLanderTemp || isLoadingAdTemp ? (
        <DrawerContent className={'h-[90vh] min-h-[90vh] max-h-[90vh] w-full'}>
          <div className="flex flex-col justify-center items-center w-full min-h-[90vh] h-[90vh] max-h-[90vh]">
            <Loader />
          </div>
        </DrawerContent>
      ) : data ? (
        <DrawerContent
          aria-describedby={undefined}
          className={'h-[90vh] p-1 flex flex-col-reverse lg:flex-row w-full'}
          onInteractOutside={saveOpen ? (e) => e.preventDefault() : undefined}
        >
          <DrawerHeader className='hidden'>
            <DrawerTitle></DrawerTitle>
            <DrawerDescription></DrawerDescription>
          </DrawerHeader>
          <ScrollArea
            className={'bg-themeaccent dark:bg-themeaccentdark rounded h-full w-full hidden lg:flex'}
          >
            {adTemplateDetails && adTemplateDetails.ATRecords.length > 0 ? (
              <TemplateDetailsAdMedia
                data={{
                  Ad: adTemplateDetails.ATRecords[0] ?? data.Ad,
                  IsLocked: data.IsLocked,
                  IsPublic: data.IsPublic,
                  brandName: data.brandName,
                  shouldInvalidateCache: data.shouldInvalidateCache,
                  DesiredWidth: data.DesiredWidth,
                }}
                saveOpen={saveOpen}
                setSaveOpen={setSaveOpen}
              />
            ) : null}
          </ScrollArea>
          <div className="hidden lg:flex">
            <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
              <div className={'flex justify-end h-4 min-h-4'}>
                <span
                  onClick={() => onCloseDrawer()}
                  className={'p-1 cursor-pointer'}
                >
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>

              <ScrollArea className="h-full">
                {adTemplateDetails && adTemplateDetails.ATRecords.length > 0 ? (
                  <TemplateDetailsAdBody
                    dateSaved={formatDate(data.Ad.Created)}
                    data={{
                      Ad: adTemplateDetails.ATRecords[0] ?? data.Ad,
                      IsLocked: data.IsLocked,
                      IsPublic: data.IsPublic,
                      brandName: data.brandName,
                      shouldInvalidateCache: data.shouldInvalidateCache,
                      DesiredWidth: data.DesiredWidth,
                    }}
                    onCloseDrawer={onCloseDrawer}
                    saveOpen={saveOpen}
                    setSaveOpen={setSaveOpen}
                    copyToClipboard={copyToClipboard}
                    numberOfUsers={numberOfUsers ?? 0}
                    numberOfSaves={numberOfSaves ?? 0}
                  />
                ) : null}
              </ScrollArea>
            </div>
          </div>
          <ScrollArea className="flex lg:hidden h-full">
            <div className="flex flex-col rounded lg:h-full w-full lg:max-w-[28rem]">
              <div className={'flex justify-end h-4 min-h-4'}>
                <span
                  onClick={() => onCloseDrawer()}
                  className={'p-1 cursor-pointer'}
                >
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>
              <TemplateDetailsAdBody
                dateSaved={formatDate(data.Ad.Created)}
                data={data}
                onCloseDrawer={onCloseDrawer}
                saveOpen={saveOpen}
                setSaveOpen={setSaveOpen}
                copyToClipboard={copyToClipboard}
                numberOfUsers={numberOfUsers ?? 0}
                numberOfSaves={numberOfSaves ?? 0}
                mediaComponent={
                  <ScrollArea
                    className={
                      'bg-themeaccent rounded h-full w-full lg:min-h-[65vh]'
                    }
                  >
                    <TemplateDetailsAdMedia
                      data={data}
                      saveOpen={saveOpen}
                      setSaveOpen={setSaveOpen}
                    />
                  </ScrollArea>
                }
              />
            </div>
          </ScrollArea>
        </DrawerContent>
      ) : landingData ? (
        <DrawerContent
          className={'h-[90vh] p-1 flex flex-col-reverse lg:flex-row w-full'}
          onInteractOutside={saveOpen ? (e) => e.preventDefault() : undefined}
        >
          <ScrollArea
            className={'bg-themeaccent rounded h-full w-full hidden lg:flex'}
          >
            {emailTemplateDetails && (
              <TemplateDetailsLanderEmailMedia
                type={type}
                landingData={{
                  created: emailTemplateDetails.Created ?? landingData.created,
                  ID: emailTemplateDetails['Email ID'].toString(),
                  id: emailTemplateDetails['Email ID'],
                  Screenshot: emailTemplateDetails['Email Screenshot'],
                  category:
                    emailTemplateDetails.Category ?? landingData.category,
                  isSaved: emailTemplateDetails.isSaved ?? landingData.isSaved,
                  imageUrl:
                    emailTemplateDetails.imageUrl ?? landingData.imageUrl,
                  brandId: emailTemplateDetails.brandId ?? landingData.brandId,
                  brandName:
                    emailTemplateDetails.brandName ?? landingData.brandName,
                  brandImage:
                    emailTemplateDetails.brandImage ?? landingData.brandImage,
                }}
                saveOpen={saveOpen}
                setSaveOpen={setSaveOpen}
              />
            )}
            {landerTemplateDetails && (
              <TemplateDetailsLanderEmailMedia
                type={type}
                landingData={{
                  created: landerTemplateDetails.Created ?? landingData.created,
                  ID: landerTemplateDetails['LP ID'].toString(),
                  Screenshot: landerTemplateDetails['Landing Page Screenshot'],
                  category:
                    landerTemplateDetails.Category ?? landingData.category,
                  isSaved: landerTemplateDetails.isSaved ?? landingData.isSaved,
                  imageUrl:
                    landerTemplateDetails.imageUrl ?? landingData.imageUrl,
                  brandId: landerTemplateDetails.brandId ?? landingData.brandId,
                  brandName:
                    landerTemplateDetails.brandName ?? landingData.brandName,
                  brandImage:
                    landerTemplateDetails.brandImage ?? landingData.brandImage,
                }}
                saveOpen={saveOpen}
                setSaveOpen={setSaveOpen}
              />
            )}
          </ScrollArea>
          <div className="hidden lg:flex">
            <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
              <div className={'flex justify-end h-4 min-h-4'}>
                <span
                  onClick={() => onCloseDrawer()}
                  className={'p-1 cursor-pointer'}
                >
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>

              <ScrollArea className="h-full">
                <TemplateDetailsLanderEmailBody
                  type={type}
                  landingData={landingData}
                  dateSaved={formatDate(landingData.created)}
                  onCloseDrawer={onCloseDrawer}
                  copyToClipboard={copyToClipboard}
                  numberOfUsers={numberOfUsers ?? 0}
                />
              </ScrollArea>
            </div>
          </div>
          <ScrollArea className="flex lg:hidden h-full">
            <div className="flex flex-col rounded lg:h-full w-full lg:max-w-[28rem]">
              <div className={'flex justify-end h-4 min-h-4'}>
                <span
                  onClick={() => onCloseDrawer()}
                  className={'p-1 cursor-pointer'}
                >
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>
              <TemplateDetailsLanderEmailBody
                type={type}
                landingData={landingData}
                dateSaved={formatDate(landingData.created)}
                onCloseDrawer={onCloseDrawer}
                copyToClipboard={copyToClipboard}
                numberOfUsers={numberOfUsers ?? 0}
                mediaComponent={
                  <ScrollArea
                    className={
                      'bg-themeaccent rounded h-full w-full lg:min-h-[65vh]'
                    }
                  >
                    <TemplateDetailsLanderEmailMedia
                      type={type}
                      landingData={landingData}
                      saveOpen={saveOpen}
                      setSaveOpen={setSaveOpen}
                    />
                  </ScrollArea>
                }
              />
            </div>
          </ScrollArea>
        </DrawerContent>
      ) : null}
    </Drawer>
  );
}
