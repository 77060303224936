const NotFoundBox = () => {
    return ( <div className="flex flex-col justify-center items-center">
        <p className="text-center w-4/5 lg:w-1/2 mb-5">
          Looks like you've gone down a path with no inspiration...this is
          your fault! All we do is win...but really, maybe try a different
          configuration of filters - we got you!
        </p>
        <img src="/giphy.webp" width="480" height="270" alt="" />
      </div> );
}
 
export default NotFoundBox;