import { defaultUseQueryRefetchOptions } from '@/_shared/constants';
import { trpc } from '@/utils/trpc';
import { Link } from '@tanstack/react-router';
import { Skeleton } from './shadcn/skeleton';

const SearchDialogBrandsTab = () => {
  const { data: brands, isLoading: brandsIsLoading } = trpc.getBrands.useQuery(
    undefined,
    defaultUseQueryRefetchOptions,
  );

  return (
    <div className="space-y-2.5">
      <div className="space-y-1.5">
        <h6 className="text-gray-500 font-medium text-sm">All Brands</h6>
      </div>
      <div>
        {brandsIsLoading ? (
          <div className="space-y-3">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="flex justify-between gap-3.5 p-3.5">
                <div className="flex items-center gap-3">
                  <Skeleton className="w-16 h-16 rounded-full" />
                  <div>
                    <Skeleton className="h-4 w-24 mb-1" />
                  </div>
                </div>
                <div className="text-xs text-slate-500 text-right flex flex-col justify-between">
                  <Skeleton className="h-3 w-12 mb-1" />
                  <Skeleton className="h-3 w-12 mb-1" />
                  <Skeleton className="h-3 w-12" />
                </div>
              </div>
            ))}
          </div>
        ) : (
          brands
            ?.sort(
              (brand) => (brand.totalAdCount ?? 0) - (brand.totalAdCount ?? 0),
            )
            .map((item) => (
              <Link
                to={`/feeds/brands/$brandID`}
                params={{ brandID: item.brandId }}
                key={item.brandId}
                className="flex justify-between gap-3.5 p-3.5"
              >
                <div className="flex items-center gap-3">
                  <img
                    src={item.brandImage ?? ''}
                    className="w-12 md:w-16 h-12 md:h-16 bg-slate-100 rounded-full"
                    key={item.brandId}
                    alt={item.brandName}
                  />
                  <div>
                    <h5 className="text-sm">{item.brandName}</h5>
                  </div>
                </div>
                <div className="text-xs text-slate-500 text-right flex flex-col space-y-2">
                  {item.totalAdCount == 0 ? null : (
                    <p>{item.totalAdCount ?? 0} Ads</p>
                  )}
                  {item.totalEmailCount == 0 ? null : (
                    <p>{item.totalEmailCount ?? 0} Emails</p>
                  )}
                  {item.totalLanderCount == 0 ? null : (
                    <p>{item.totalLanderCount ?? 0} Landers</p>
                  )}
                </div>
              </Link>
            ))
        )}
      </div>
    </div>
  );
};

export default SearchDialogBrandsTab;
