import { Badge } from '@/components/shadcn/badge';
import { Button } from '@/components/shadcn/button';
import { PRO_PLAN, PlanDetail, STANDARD_PLAN } from '@/utils/data/plans.ts';
import { CheckCircle } from '@mynaui/icons-react';

export default function UpgradeBox({
  boxData,
  userPlans,
  period,
  descriptionDetailsToShow,
  setSelected,
  setUpgradeOpen,
}: {
  boxData: PlanDetail;
  userPlans: string[];
  period?: string;
  descriptionDetailsToShow: 'description' | 'features';
  setUpgradeOpen: (opened: boolean) => void;
  setSelected: (plan: PlanDetail) => void;
}) {
  const userIsOnProPlan =
    userPlans.includes(PRO_PLAN.plan_id) ||
    userPlans.includes(PRO_PLAN.no_trial_plan_id) ||
    userPlans.includes(PRO_PLAN.yearly_plan_id);

  const isProPlanBox =
    boxData.plan_id === PRO_PLAN.plan_id ||
    boxData.plan_id === PRO_PLAN.no_trial_plan_id ||
    boxData.plan_id === PRO_PLAN.yearly_plan_id;

  const isStandardPlanBox =
    boxData.plan_id === STANDARD_PLAN.plan_id ||
    boxData.plan_id === STANDARD_PLAN.no_trial_plan_id ||
    boxData.plan_id === STANDARD_PLAN.yearly_plan_id;

  const userPlansContainCurrentBoxDataPlans =
    userPlans.includes(boxData.plan_id) ||
    userPlans.includes(boxData.no_trial_plan_id) ||
    userPlans.includes(boxData.yearly_plan_id);

  return userIsOnProPlan && !isProPlanBox ? (
    <></>
  ) : (
    <div
      className={`p-4 border-2	rounded-lg ${userPlansContainCurrentBoxDataPlans ? 'border-themeborder' : 'border-themedestructive'} gap-1`}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className=" text-base leading-6 font-medium">
            {boxData.name}
          </span>
          {isProPlanBox && !userPlansContainCurrentBoxDataPlans && (
            <Badge className="text-themedestructive text-xs leading-4 font-medium bg-purple-100 hover:bg-purple-100">
              {userPlans.length == 0 ? 'Recommended' : 'Premium'}
            </Badge>
          )}
          {(
            <Badge className="text-xs leading-4 font-medium text-themeforeground bg-thememuted hover:bg-thememuted">
              Current Plan
            </Badge>
          )}
        </div>
        <div className="flex items-center gap-2">
          <span className=" text-base leading-6 font-bold">
            {period === 'annually' ? boxData.annual_price : boxData.price} /{' '}
            {period === 'annually' ? 'yr' : 'mo'}
          </span>
          {!userPlansContainCurrentBoxDataPlans && (
            <Button
              variant={'destructivePurple'}
              size={'sm'}
              onClick={() => {
                setSelected(boxData);
                setUpgradeOpen(true);
              }}
            >
              {isProPlanBox && userPlans.length == 0
                ? 'Get Pro'
                : isStandardPlanBox && userPlans.length == 0
                  ? 'Get Started'
                  : 'Upgrade'}
            </Button>
          )}
        </div>
      </div>
      {!userPlansContainCurrentBoxDataPlans && (
        <div>
          {descriptionDetailsToShow === 'features' ? (
            <div className="flex flex-col gap-2">
              <span className="text-sm font-normal leading-5">
                {period === 'annually'
                  ? boxData.annual_monthly_price
                  : boxData.price}{' '}
                per month, cancel anytime{' '}
              </span>
              <>
                {boxData.features.map((feature, index) => (
                  <div
                    key={index}
                    className="flex gap-1 justify-start items-center text-thememutedforeground"
                  >
                    <CheckCircle className="w-4 h-4" />
                    <span className="text-sm font-normal leading-5 ">
                      {feature}
                    </span>
                  </div>
                ))}
              </>
            </div>
          ) : (
            <p className={'text-sm font-light'}>{boxData.description}</p>
          )}
        </div>
      )}
    </div>
  );
}
