import OnboardingHeader from "@/components/onboarding/OnboardingHeader.tsx";
import { Button } from "@/components/shadcn/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/form";
import { Input } from "@/components/shadcn/input";
import { Label } from "@/components/shadcn/label.tsx";
import showToastNotification from "@/hooks/useShowToast";
import { trpc } from "@/utils/trpc.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";
import { ArrowLeftIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";

export const Route = createFileRoute("/password-reset")({
  component: PasswordReset,
});

function PasswordReset() {
  const navigate = useNavigate();

  const { mutate, isPending } = trpc.sendResetPasswordEmail.useMutation();

  const resetPasswordFormSchema = z.object({
    email: z.string({ required_error: "Email address is required" }).email({
      message: "Please enter a valid email address.",
    }),
  });

  type ResetPasswordFormValues = z.infer<typeof resetPasswordFormSchema>;

  const defaultValues: Partial<ResetPasswordFormValues> = {};

  const form = useForm<ResetPasswordFormValues>({
    resolver: zodResolver(resetPasswordFormSchema),
    defaultValues,
  });

  async function onSubmit({ email }: ResetPasswordFormValues) {
    try {
      mutate(
        { email },
        {
          onSuccess: () => {
            // let's keep the email in
            showToastNotification("success", {
              message: "Request sent successfully!",
              description:
                "We have sent you an email to complete the password reset",
            });
          },
          onError: (error) => {
            showToastNotification("error", {
              message: error.message,
            });
          },
        },
      );
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div
      className={"bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen"}
    >
      <div>
        <OnboardingHeader />
      </div>
      <div
        className={
          "flex-1 flex flex-col justify-center items-center py-[7.75rem]"
        }
      >
        <div
          className={
            "rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border"
          }
        >
          <div className={"flex flex-col items-start gap-2"}>
            <Button
              onClick={() =>
                navigate({
                  to: "/login",
                })
              }
              variant={"ghost"}
              className={"flex gap-1.5 items-center"}
            >
              <ArrowLeftIcon size={"1rem"} />
              <span>Back</span>
            </Button>
            <h4
              className={
                "text-2xl text-center text-themeforeground font-semibold"
              }
            >
              Reset your password
            </h4>
            <p>
              We’ll email you a secure link to reset the password for your
              account.
            </p>
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <div className={"flex flex-col gap-6"}>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <Label>Email Address</Label>
                      <FormControl>
                        <Input
                          placeholder="name@example.com"
                          type={"email"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <Button disabled={isPending} type={"submit"} className="w-full">
                  {isPending ? "Submitting..." : "Send Link"}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
