import { defaultUseQueryRefetchOptions } from '@/_shared/constants';
import { BoardDialog } from '@/components/BoardDialog.tsx';
import FreeTrialEnding from '@/components/FreeTrialEnding';
import { Stack, Text } from '@/components/custom-components';
import { Loader } from '@/components/custom-components/Loader';
import { ErrorDisplay } from '@/components/error.tsx';
import { trpc } from '@/utils/trpc.ts';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { ExpertsGridView } from './feeds.collections.experts.index';

type SearchParams = {
  sideBarOpen?: boolean;
  searchTerm?: string;
};

export const Route = createFileRoute('/feeds/search/experts')({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;
    const searchTerm = search?.searchTerm as string | undefined;

    return {
      sideBarOpen,
      searchTerm,
    };
  },
});

function All() {
  const { searchTerm: querySearchTerm } = Route.useSearch();
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const { data: experts, isLoading: expertsIsLoading } =
    trpc.getExpertsWithTemplates.useQuery({}, defaultUseQueryRefetchOptions);

  useEffect(() => {
    if (!isLoadingPermission && !permissionData?.userCanAccessEverything) {
      setUpgradeDialogOpen(true);
    }
  }, [permissionData?.userCanAccessEverything, isLoadingPermission]);

  const {
    data: collectionsWithImages,
    isLoading,
    isError,
  } = trpc.searchExperts.useQuery(
    {
      limit: 20,
      query: querySearchTerm ?? '',
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  if (isError) {
    return <ErrorDisplay />;
  }

  if (isLoading || expertsIsLoading) {
    return (
      <div
        className={'flex justify-center items-center w-full h-screen m-auto'}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
        />
      )}
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="gap-3 lg:gap-6">
        <Stack className="gap-3 lg:gap-8">
          <Stack className="gap-3 lg:gap-6">
            <Text weight="semibold" size={'xxl'} className="w-fit">
              Your Discovery Results {collectionsWithImages ? `(${collectionsWithImages?.totalHits})`:""}:
              <span className="opacity-45">“{querySearchTerm}”</span>
            </Text>

            <div className={'flex justify-end gap-6 items-center'}>
              <div className="gap-2 items-center hidden md:flex">
                <Text
                  weight="normal"
                  size={'base'}
                  className="text-thememutedforeground"
                >
                  {(collectionsWithImages?.data ?? []).length}
                  {(collectionsWithImages?.data ?? []).length > 1
                    ? ' Collections'
                    : ' Collection'}
                </Text>
              </div>
            </div>
          </Stack>
        </Stack>
        {collectionsWithImages &&
          experts &&
          (collectionsWithImages?.data ?? []).length > 0 &&
          !isLoadingPermission &&
          permissionData?.userCanAccessEverything && (
            <ExpertsGridView
              collections={experts?.data.filter((product) =>
                collectionsWithImages.data
                  .map((collectionsWithImage) => collectionsWithImage.name)
                  .includes(product.name),
              )}
            />
          )}
      </Stack>
    </>
  );
}
