import { defaultUseQueryRefetchOptions } from "@/_shared/constants.ts";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { Loader } from "@/components/custom-components/Loader";
import TemplatesFilterPopover from "@/components/custom-components/TemplateFilterPopover";
import { ErrorDisplay } from "@/components/error.tsx";
import { LandingAdGridView } from "@/components/templates/LandingAdGridView";
import { AdFeedFilterOption } from "@/hooks/useFilterFeed.tsx";
import { trpc } from "@/utils/trpc.ts";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { z } from "zod";
import { orderFilter as OrderFilterTypes } from "../../../../src/shared/airtable/index.ts";
import { FilterOption } from "./feeds.templates.index.tsx";

type SearchParams = {
  cacheBuster?: number;
  loadAdsCreatedAfter?: number;
  sideBarOpen?: boolean;
  categories?: string;
  brands?: string;
  orderFilter?: z.infer<typeof OrderFilterTypes>;
  searchTerm?: string;
};

export type SelectedTemplateFilters = {
  categories?: string[];
  brands?: string[];
};

export const Route = createFileRoute("/feeds/search/templates/landing-pages")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const cacheBuster = (search?.cacheBuster as number) ?? Math.random();
    const sideBarOpen = search?.sideBarOpen as boolean;
    const categories = search?.categories as string | undefined;
    const brands = search?.brands as string | undefined;
    const searchTerm = search?.searchTerm as string | undefined;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterTypes>
      | undefined;

    return {
      cacheBuster: cacheBuster,
      sideBarOpen,
      searchTerm,
      categories,
      brands,
      orderFilter,
    };
  },
});

function All() {
  const {
    orderFilter,
    categories: queryCategories,
    searchTerm: querySearchTerm,
    brands: queryBrands,
  } = Route.useSearch();
  const navigate = useNavigate();

  // Get the template collections to be passed to the filter
  const { data: landingCategories } =
    trpc.getLandingPageTemplateCategories.useQuery();
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedTemplateFilters>({
      categories: queryCategories ? queryCategories.split(",") : undefined,
      brands: queryBrands ? (queryBrands.split(",") as string[]) : undefined,
    });
  const { data: brands } = trpc.getBrands.useQuery(undefined, defaultUseQueryRefetchOptions);

  const updateQueryString = useCallback(
    (params: { categories?: string; brands?: string; searchTerm?: string }) => {
      const searchParams = new URLSearchParams();

      if (params.categories) searchParams.set("categories", params.categories);
      if (params.brands) searchParams.set("brands", params.brands);

      navigate({
        to: "/feeds/search/templates/landing-pages",
        replace: true,
        search: (old) => {
          const newSearch = {
            ...old,
            ...params,
            orderFilter,
            cacheBuster: Math.random(),
          };
          return JSON.stringify(newSearch) === JSON.stringify(old)
            ? old
            : newSearch; // Prevent redundant updates
        },
      });
    },
    [navigate, orderFilter]
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedCategories: string[] = [];
      const selectedBrands: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Category") selectedCategories.push(item.label);
            if (group.title === "Brand") selectedBrands.push(`${item.id}`);
          }
        });
      });

      setSelectedFilters({
        categories:
          selectedCategories.length > 0 ? selectedCategories : undefined,
        brands: selectedBrands.length > 0 ? selectedBrands : undefined,
      });

      updateQueryString({
        categories:
          selectedCategories.length > 0
            ? selectedCategories.join(",")
            : undefined,
        brands:
          selectedBrands.length > 0 ? selectedBrands.join(",") : undefined,
        searchTerm: querySearchTerm,
      });
    },
    [updateQueryString]
  );

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => ({
      ...option,
      optionItems: option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === "Category" &&
            selectedFilters.categories?.includes(item.label)) ||
          (option.title === "Brand" &&
            selectedFilters.brands?.includes(item.id ?? "")),
      })),
    }));

    setFilterOptions((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(updatedOptions)) return prev; // Prevent unnecessary updates
      return updatedOptions;
    });
  }, [filterOptions, selectedFilters]);

  useEffect(() => {
    if (
      landingCategories &&
      landingCategories.length > 0 &&
      brands &&
      brands.length > 0
    ) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Prevent redundant updates
        return [
          {
            title: "Category",
            counter: 0,
            optionItems: landingCategories.map((i) => ({
              label: i.category,
              value: false,
            })),
          },
          {
            title: "Brand",
            counter: 0,
            optionItems: brands.map((i) => ({
              label: i.brandName,
              value: false,
              id: i.brandId,
              logo: i.brandImage ?? undefined,
            })),
          },
        ];
      });
    }
  }, [landingCategories, brands]);


    const [cursor, setCursor] = useState(1);
    const [allFetchedData, setAllFetchedData] = useState<
      {
        imageUrl: string;
        Created: string;
        createdTimestamp: number;
        "LP ID": number;
        brandName?: string | null | undefined;
        brandImage?: string | null | undefined;
        brandId?: string | null | undefined;
        isSaved?: boolean | undefined;
        downloads?: number | undefined;
        Category?: string | undefined;
      }[]
    >([]);
  
    const {
      data: filteredTemplatesByCollections,
      isLoading,
      isError,
      refetch,
    } = trpc.searchLandingPageTemplate.useQuery(
      {
        limit: 20,
        query: querySearchTerm ?? "",
        cursor,
      },
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );
  
    const { ref: scrollRef, inView } = useInView({
      threshold: 0,
      trackVisibility: true,
      delay: 100,
      initialInView: false,
    });
  
    useEffect(() => {
      if (inView && (filteredTemplatesByCollections?.data.length ?? 0) >= 20) {
        setCursor((prevCursor) => {
          const newCursor = prevCursor + 1;
          return newCursor;
        });
      }
    }, [
      inView,
      refetch,
      querySearchTerm,
      filteredTemplatesByCollections,
      cursor,
    ]);
  
    useEffect(() => {
      refetch();
    }, [cursor]);
  
    useEffect(() => {
      if (filteredTemplatesByCollections?.data) {
        setAllFetchedData((prevData) => [
          ...prevData,
          ...filteredTemplatesByCollections.data.map((ad) => ({
            ...ad,
            Category: ad.Category ?? undefined,
          })),
        ]);
      }
    }, [filteredTemplatesByCollections]);
  

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  useEffect(() => {
    if (
      !isLoadingPermission &&
      permissionData?.userCanAccessEverything === false &&
      !upgradeDialogOpen
    ) {
      setUpgradeDialogOpen(true); // Only update if `upgradeDialogOpen` is false
    }
  }, [
    permissionData?.userCanAccessEverything,
    isLoadingPermission,
    upgradeDialogOpen,
  ]);

  return (
    <>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="gap-3 lg:gap-6 relative">
        <Stack className="gap-3 lg:gap-8">
          <FeatureTabs
            tabItems={[
              {
                name: "Ads",
                link: "/feeds/search/templates/ads",
                searchParams: { searchTerm: querySearchTerm },
              },
              {
                name: "Emails",
                link: "/feeds/search/templates/emails",
                  isPremiumFeature: true,
                searchParams: { searchTerm: querySearchTerm },
              },
              {
                name: "Landers",
                link: "/feeds/search/templates/landing-pages",
                  isPremiumFeature: true,
                searchParams: { searchTerm: querySearchTerm },
              },
            ]}
          />
          <Stack className="gap-3 lg:gap-6">
            <div className={"flex justify-between gap-5 items-center"}>
              <Text weight="semibold" size={"xxl"} className="w-fit">
                Your Discovery Results {filteredTemplatesByCollections ? `(${filteredTemplatesByCollections?.totalHits})`:""}:
                <span className="opacity-45">“{querySearchTerm}”</span>
              </Text>
              <div className={"hidden gap-2 items-center flex-wrap justify-end"}>
                <div className="lg:hidden">
                  <OrderFilterSelect
                    defaultFilter={orderFilter || "Random"}
                    options={["Random", "Recent", "Popular", "Oldest"]}
                  />
                </div>
              </div>
            </div>
          </Stack>
        </Stack>
        <Stack className="w-full gap-3">
          <Text weight="medium" size={"sm"} className="w-fit hidden">
            Narrow your search using filters:
          </Text>

          <div
            className={`z-10 lg:sticky lg:top-0 lg:bg-themebackground dark:lg:bg-themebackgrounddark  lg:py-2 hidden ${filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-center`}
          >
            <div>
              {filterOptions && filterOptions.length > 0 && (
                <TemplatesFilterPopover
                  initialOptions={filterOptions}
                  onOptionsChange={handleOptionsChange}
                  placeholder={"Filter Ad Templates"}
                />
              )}
            </div>

            <div className={"hidden lg:flex"}>
              <OrderFilterSelect
                defaultFilter={orderFilter || "Random"}
                options={["Random", "Recent", "Popular", "Oldest"]}
              />
            </div>
          </div>
        </Stack>
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-[70vh]">
            <Loader />
          </div>
        ) : isError ? (
          <ErrorDisplay />
        ) : allFetchedData.length === 0 ? (
          <div className="flex flex-col justify-center items-center">
            <p className="text-center w-4/5 lg:w-1/2 mb-5">
              Looks like you've gone down a path with no templates...this is
              your fault! All we do is win...but really, maybe try a different
              configuration of filters - we got you!
            </p>
            <img src="/giphy.webp" width="480" height="270" alt="" />
          </div>
        ) : (
          <LandingAdGridView
            Cards={allFetchedData.map((data) => ({
              created: data.Created,
              ID: data["LP ID"].toString(),
              id: data["LP ID"],
              Screenshot: data.imageUrl,
              type: "landing-page",
              category: data.Category ?? "",
              isSaved: data.isSaved,
              imageUrl: data.imageUrl,
              brandName: data.brandName,
              brandImage: data.brandImage,
              brandId: data.brandId,
            }))}
            HasAccess={true}
            type={"landing-page"}
          />
        )}

        <div ref={scrollRef} className="flex justify-center w-full min-h-14 h-14">
                {isLoading && allFetchedData.length >0 && <Loader />}
              </div>
      </Stack>
    </>
  );
}
