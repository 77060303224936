import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip.tsx';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import showToastNotification from '@/hooks/useShowToast';
import { Download } from '@mynaui/icons-react';
import { Link as LinkIcon } from '@mynaui/icons-react';
import { Text } from './custom-components';
import { Button } from './shadcn/button';
import {
  LandingAdDataProps,
  ManuallySaveLandingAdToBoard,
} from './templates/LandingAdCard';

export const TemplateDetailsLanderEmailMedia = ({
  landingData,
  type,
  saveOpen,
  setSaveOpen,
}: {
  landingData: LandingAdDataProps;
  type?: 'email' | 'landing-page';
  saveOpen: boolean;
  setSaveOpen: (x: boolean) => void;
}) => {
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <div className="p-3 lg:p-4 w-full flex flex-col items-center">
      <div className={'flex gap-5 justify-between w-full'}>
        <div className={`flex gap-1 items-center`}>
        <>
            <img
              alt={"CreativeOS"}
              src={"/images/icon_dark.png"}
              className={" dark:block hidden bg-[#BCBCBC] rounded-full w-8 h-8 object-contain"}
            />
            <img
              alt={"CreativeOS"}
              src={"/images/icon_light.png"}                
              className={" dark:hidden block bg-[#BCBCBC] rounded-full w-8 h-8 object-contain"}
            />
          </>
          <div>
            <h4 className={`truncate font-[500] text-sm`}>
              {type == 'email' ? 'Email' : 'Lander'} Template
            </h4>

            <p className={'text-xs text-thememutedforeground'}>by CreativeOS</p>
          </div>
        </div>

        <div className={'flex gap-2'}>
          {landingData && (
            <ManuallySaveLandingAdToBoard
              open={saveOpen}
              onOpenChange={setSaveOpen}
              data={landingData}
              variant={'outline'}
              type={type ?? 'email'}
            />
          )}

          {(landingData.imageUrl ?? landingData.Screenshot) && (
            <Tooltip>
              <TooltipTrigger asChild>
                <a
                  href={landingData.imageUrl ?? landingData.Screenshot}
                  download={'image.png'}
                >
                  <Button variant={'outline'} size={'icon'}>
                    <Download className="w-5 h-5" />
                  </Button>
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <p>Download as image</p>
              </TooltipContent>
            </Tooltip>
          )}

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => {
                  copyToClipboard(
                    `${window.location.origin}/share/templates/${type === 'email' ? 'emails' : type === 'landing-page' ? 'landing-pages' : ''}/${landingData.ID}`,
                  );
                  showToastNotification('success', {
                    message: 'Copied!',
                  });
                }}
                variant="outline"
                size={'icon'}
              >
                <LinkIcon />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Share a public link</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
      <div
        className={`${type === 'email' ? 'lg:max-w-[30.5rem]' : ''} w-full border border-themeborder bg-white rounded my-4`}
      >
        <div className={'px-2 py-3'}>
          <Text
            weight={'semibold'}
            size={'sm'}
            className={'text-foreground truncate m-3'}
          >
            Template By CreativeOS
          </Text>
        </div>
        <div>
          <img
            className="w-full"
            alt="ad display"
            loading="lazy"
            src={landingData.imageUrl ?? landingData.Screenshot}
          />
        </div>
      </div>
    </div>
  );
};
