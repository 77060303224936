import { useElementSize } from '@/hooks/useElementSize.tsx';
import { useEffect, useState } from 'react';

const useCardLayoutWithImpressions = () => {
  const [squareRef, { width = 0 }] = useElementSize();
  const [desiredCardWidth, setDesiredCardWidth] = useState<number>(0);
  const [columns, setColumns] = useState<number | undefined>(undefined);

  const breakpoints = {
    600: 1,
    750: 2,
    900: 3,
    1050: 4,
  };
  const gutterWidth = 24;

  // Calculate the desired card width and column count based on container width
  useEffect(() => {
    if (!width) return;

    let newWidth = 0;
    let cols = 1;

    if (width > 1050) {
      cols = 4;
      newWidth =
        width / breakpoints[1050] - gutterWidth * (breakpoints[1050] - 1);
    } else if (width > 900) {
      cols = 3;
      newWidth =
        width / breakpoints[900] - gutterWidth * (breakpoints[900] - 1);
    } else if (width > 750) {
      cols = 2;
      newWidth =
        width / breakpoints[750] - gutterWidth * (breakpoints[750] - 1);
    } else if (width > 0) {
      cols = 1;
      newWidth =
        width / breakpoints[600] - gutterWidth * (breakpoints[600] - 1);
    }

    setDesiredCardWidth(newWidth);
    setColumns(cols);
  }, [width]);

  return {
    gutterWidth,
    squareRef,
    desiredCardWidth,
    columns,
  };
};

export default useCardLayoutWithImpressions;
