import {
  ChartLine,
  CheckWaves,
  Layout,
  Search,
  Store,
} from '@mynaui/icons-react';
import { ChangeEvent, useState } from 'react';
import { useEffect } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import SearchDialogBrandsTab from './SearchDialogBrandsTab';
import SearchDialogExpertsTab from './SearchDialogExpertsTab';
import SearchDialogInspirationTab from './SearchDialogInspirationTab';
import SearchDialogPopularTab from './SearchDialogPopularTab';
import SearchDialogTemplateSection from './SearchDialogTemplatesSection';
import TypingSearchView from './TypingSearchView';
import { Button } from './shadcn/button';
import { CustomLightBulb } from './shadcn/customLightBulb';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from './shadcn/dialog';
import { Input } from './shadcn/input';
import { ScrollArea } from './shadcn/scroll-area';
import { Separator } from './shadcn/separator';

export type PermissionData = {
  ID: string;
  email: string;
  userCanAccessAds: boolean;
  userCanAccessEverything: boolean;
  userCanAccessExperts: boolean;
  usersPlans: string[];
  limitLeft: number;
  hasRequestLimitReached: boolean;
  requestLimit: number;
  limitNextUpdateAt: string;
};

const SearchDialog = ({
  open,
  onOpenChange,
  permissions,
}: {
  open: boolean;
  onOpenChange: () => void;
  permissions: PermissionData;
}) => {
  const sidebarOptions = {
    nav: [
      { name: 'Popular', icon: ChartLine },
      { name: 'Templates', icon: Layout },
      { name: 'Inspiration', icon: CustomLightBulb },
      { name: 'Brands', icon: Store },
      { name: 'Experts', icon: CheckWaves },
    ],
  };
  const [subSection, setSubSection] = useState<string>(
    sidebarOptions.nav[0].name,
  );

  const [searchTerm, setSearchTerm] = useDebounceValue('', 800);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

  useEffect(() => {
    setSearchTerm(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDebouncedSearchTerm(e.target.value); // Update debounced term first
  };

  return (
    <Dialog modal={false} open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className={`overflow-hidden p-0 pl-6 pt-4 max-h-[95svh] !rounded-3xl w-11/12 md:max-w-[700px] lg:max-w-3xl lg:[&>button]:hidden z-[31] bg-themebackground dark:bg-themebackgrounddark`}
      >
        <DialogHeader>
          <DialogTitle></DialogTitle>
          <DialogDescription></DialogDescription>
          <div className="outline-none border-none flex gap-3 items-center w-11/12 lg:w-auto">
            {/* Search Icon positioned on the left */}
            <Search
              className="h-7 w-7"
              aria-hidden="true"
            />
            <Input
              type={'text'}
              value={debouncedSearchTerm}
              onChange={handleSearchChange}
              placeholder="Templates, Inspiration, Experts, Collections, and more..."
              className="p-0 lg:w-full outline-none border-0 focus-visible:ring-offset-0 focus-visible:ring-0 shadow-none" // Padding left to make space for the icon
            />
          </div>
        </DialogHeader>
        <div className="pr-6">
          <Separator />
        </div>
        {debouncedSearchTerm ? (
          <TypingSearchView
            searchTerm={searchTerm}
            debouncedSearchTerm={debouncedSearchTerm}
          />
        ) : (
          <div className="flex flex-col lg:flex-row gap-2.5 w-full pb-5">
            <div className=" w-full lg:max-w-48">
              <div
                className={
                  'flex flex-row flex-wrap lg:flex-nowrap lg:flex-col gap-2 w-full'
                }
              >
                {sidebarOptions.nav.map((item) => (
                  <Button
                    key={item.name}
                    onClick={() => setSubSection(item.name)}
                    variant={item.name === subSection ? 'secondary' : 'ghost'}
                    className={
                      'flex gap-2 items-center justify-start text-sm md:text-base'
                    }
                  >
                    <item.icon className="w-4 lg:min-w-5" />
                    <span>{item.name}</span>
                  </Button>
                ))}
              </div>
            </div>
            <ScrollArea
              type="always"
              className="max-w-[85vw] w-full lg:w-1 max-h-[60svh] lg:max-h-[85svh] flex-1"
            >
              {subSection === 'Popular' ? (
                <SearchDialogPopularTab searchItem={setDebouncedSearchTerm} />
              ) : subSection === 'Templates' ? (
                <SearchDialogTemplateSection permissions={permissions} />
              ) : subSection === 'Inspiration' ? (
                <SearchDialogInspirationTab />
              ) : subSection === 'Brands' ? (
                <SearchDialogBrandsTab />
              ) : subSection === 'Experts' ? (
                <SearchDialogExpertsTab permissions={permissions} />
              ) : null}
            </ScrollArea>
          </div>
        )}
      </DialogContent>
      <div
        className="fixed inset-0 bg-black/10 backdrop-blur-sm z-[30]"
        onClick={() => onOpenChange()}
      />
    </Dialog>
  );
};

export default SearchDialog;
