import OnboardingSteppers from "@/components/onboarding/OnboardingSteppers.tsx";
import { Button } from "@/components/shadcn/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "@/components/shadcn/form";
import {
  OnboardingRadioGroupItem,
  RadioGroup,
} from "@/components/shadcn/radio";
import showToastNotification from "@/hooks/useShowToast";
import {
  OnboardingFormSchema,
  SectionProps,
  UseCaseType,
  useCases,
} from "@/routes/get-started.tsx";
import { trpc } from "@/utils/trpc.ts";
import { useNavigate } from "@tanstack/react-router";
import { ArrowLeftIcon } from "lucide-react";
import { z } from "zod";

export default function ClientUseCaseBrief(props: SectionProps) {
  const navigate = useNavigate();

  const { mutate, isPending } = trpc.completeOnboardingStep.useMutation();

  async function onSubmit(data: z.infer<typeof OnboardingFormSchema>) {
    const selected = data.use_cases as UseCaseType;

    try {
      mutate(
        {
          brief: selected ? selected : undefined,
        },
        {
          onSuccess: (data) => {
            if (data && data.id) {
              navigate({
                to: "/get-started",
                search: { step: "lead" },
              });
            }
          },
          onError: (error) => {
            showToastNotification("error", {
              message: error.message,
              description:
                "Please select one of the options above or skip to proceed",
            });
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div
      className={
        "rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border"
      }
    >
      <div className={"flex flex-col gap-6"}>
        <div className={"flex gap-5 justify-between"}>
          <Button
            onClick={() =>
              navigate({
                to: "/get-started",
                search: { step: "client-info" },
              })
            }
            variant={"ghost"}
            className={"flex gap-1.5 items-center"}
          >
            <ArrowLeftIcon size={"1rem"} />
            <span>Back</span>
          </Button>
          <OnboardingSteppers stepIndex={1} />
        </div>
        <div>
          <h4 className={"text-2xl text-themeforeground font-semibold mb-2"}>
            What’s your brief?
          </h4>
          <p>We’ll setup your experience and curate content accordingly</p>
        </div>
      </div>
      <div>
        <Form {...props.form}>
          <form
            onSubmit={props.form.handleSubmit(onSubmit)}
            className="space-y-6"
          >
            <div className={"flex flex-col gap-3"}>
              <FormField
                control={props.form.control}
                name="use_cases"
                render={({ field }) => (
                  <RadioGroup
                    onValueChange={field.onChange}
                    value={field.value}
                    className="flex flex-col space-y-1"
                  >
                    {useCases &&
                      useCases.map((item) => (
                        <FormControl key={item.id}>
                          <FormItem>
                            <OnboardingRadioGroupItem
                              checked={field.value === item.value}
                              value={item.value}
                              onChange={() => field.onChange(item.value)}
                              id={item.label}
                            >
                              {item.label}
                            </OnboardingRadioGroupItem>
                          </FormItem>
                        </FormControl>
                      ))}
                  </RadioGroup>
                )}
              />
            </div>
            <div className={"flex justify-end items-center space-x-2"}>
              <Button
                type={"button"}
                className={"px-8"}
                variant={"outline"}
                size={"lg"}
                onClick={() =>
                  navigate({
                    to: "/feeds/templates",
                    search: {
                      orderFilter: "Recent",
                      cacheBuster: Math.random(),
                      getStarted: "ready",
                    },
                  })
                }
                disabled={isPending}
              >
                {"Skip"}
              </Button>
              <Button
                type={"submit"}
                className={"px-8"}
                variant={"default"}
                size={"lg"}
                disabled={isPending}
                loading={isPending}
              >
                {isPending ? "Submitting..." : "Next"}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
