import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";
import { useState } from "react";

type CheckboxProps = {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  id?: string; // Add id prop for better accessibility
} & React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>;

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, checked, onChange, id, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer h-[1.25rem] w-[1.25rem] shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      checked ? "bg-black text-white" : "", // apply styles based on checked state
      className,
    )}
    checked={checked}
    onChange={() => onChange && onChange(!checked)} // toggle checked state
    id={id} // assign id for label association
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center")}
    >
      <Check className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

const OnboardCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, checked, onChange, id, ...props }, ref) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxChange = (newChecked: boolean) => {
    setIsChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <label
      htmlFor={id}
      className={cn(
        "bg-white min-h-[5rem] flex flex-row items-center space-x-5 space-y-0 rounded-[0.938rem] border-2 px-5 py-5",
        isChecked ? "border-[#A259FF]" : "border-gray-300",
      )}
    >
      <span className="flex items-center space-x-3 flex-1">
        <CheckboxPrimitive.Root
          ref={ref}
          id={id}
          checked={isChecked}
          onCheckedChange={handleCheckboxChange}
          className={cn(
            "peer h-[1.25rem] w-[1.25rem] shrink-0 rounded-full border border-[#7324A2] ring-offset-background focus-visible:outline-checkgrad focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-checkgrad",
            className,
          )}
          {...props}
        >
          <CheckboxPrimitive.Indicator className="flex items-center justify-center">
            <Check className="h-4 w-4" />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        <span className="text-gray-900">{id}</span>
      </span>
    </label>
  );
});

OnboardCheckbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox, OnboardCheckbox };
