import { BoardDialog } from "@/components/BoardDialog.tsx";
import { DeleteBoardDialog } from "@/components/DeleteBoard.tsx";
import { Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs";
import { Loader } from "@/components/custom-components/Loader";
import { ErrorDisplay } from "@/components/error.tsx";
import { Button } from "@/components/shadcn/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn/select";
import {
  LandingAdCard,
  LandingFeedAdCard,
} from "@/components/templates/LandingAdCard";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import showToastNotification from "@/hooks/useShowToast";
import { trpc } from "@/utils/trpc.ts";
import { Link as LinkIcon, Pencil, Trash } from "@mynaui/icons-react";
import { Link, createFileRoute } from "@tanstack/react-router";
import { ArrowLeftIcon } from "lucide-react";
import { useState } from "react";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/boards/$boardID/ad-landers")({
  component: BoardLanders,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function BoardLanders() {
  const { boardID } = Route.useParams();
  const [editBoardDialogOpen, setEditBoardDialogOpen] =
    useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<
    "All Saved" | "Only Templates"
  >("All Saved");
  const [deleteBoardDialogOpen, setDeleteBoardDialogOpen] =
    useState<boolean>(false);
  const [, copyBoardAdToClipboard] = useCopyToClipboard();
  const {
    data: boardDetails,
    isLoading,
    error,
  } = trpc.getBoardDetails.useQuery(
    {
      boardId: boardID,
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  return (
    <>
      {editBoardDialogOpen && boardDetails && (
        <BoardDialog
          data={{
            name: boardDetails.name,
            id: boardDetails.id,
            description: boardDetails.description || "",
          }}
          open={editBoardDialogOpen}
          onOpenChange={() => setEditBoardDialogOpen(false)}
        />
      )}
      {deleteBoardDialogOpen && boardDetails && (
        <DeleteBoardDialog
          data={{
            name: boardDetails.name,
            id: boardDetails.id,
            description: boardDetails.description || "",
          }}
          open={deleteBoardDialogOpen}
          onOpenChange={() => setDeleteBoardDialogOpen(false)}
        />
      )}
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-8">
          <Stack className="gap-3 lg:gap-2">
            <Link to={"/feeds/boards"} className="flex gap-1.5 w-10">
              <Button
                variant={"link"}
                className="p-0 gap-2 items-center"
                size={"sm"}
              >
                <ArrowLeftIcon size={"1rem"} className="pt-1" />
                <span>All My Collections</span>
              </Button>
            </Link>
            {boardDetails ? (
              <div className={"flex justify-between gap-5 items-start"}>
                <Stack className="gap-2">
                  <Text weight="semibold" size={"xxl"}>
                    {boardDetails?.name}
                  </Text>
                  <div className={`flex gap-1 items-center`}>
                    <Text
                      className="text-thememutedforeground w-fit"
                      size={"base"}
                      weight={"normal"}
                    >
                      {boardDetails?.description}
                    </Text>
                  </div>
                </Stack>
                <Stack className="gap-3 items-end">
                  <div className="flex items-center justify-end gap-6">
                    <FeatureTabs
                      tabItems={[
                        {
                          name: "Ads",
                          link: `/feeds/boards/${boardID}/ads`,
                        },
                        {
                          name: "Landers",
                          link: `/feeds/boards/${boardID}/ad-landers`,
                        },
                        {
                          name: "Emails",
                          link: `/feeds/boards/${boardID}/emails`,
                        },
                      ]}
                    />
                    <Select
                      value={selectValue}
                      onValueChange={(value: "All Saved" | "Only Templates") =>
                        setSelectValue(value)
                      }
                    >
                      <SelectTrigger className="w-33 h-8 max-h-8">
                        <SelectValue
                          placeholder="Ad Type"
                          className="rounded-md"
                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {["All Saved", "Only Templates"].map((item, idx) => (
                            <SelectItem
                              key={idx}
                              value={item}
                              className="py-1.5"
                            >
                              {item}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex gap-1.5 h-8">
                    <Button
                      onClick={() => {
                        copyBoardAdToClipboard(
                          `${window.location.origin}/feeds/boards/${boardDetails?.id}/ads`
                        );
                        showToastNotification("success", {
                          message: "Copied!",
                        });
                      }}
                      className="hidden lg:flex gap-1.5"
                      variant={"outline"}
                      size={"sm"}
                    >
                      <LinkIcon className="w-5 h-5" />
                      Share
                    </Button>
                    <Button
                      onClick={() => {
                        copyBoardAdToClipboard(
                          `${window.location.origin}/feeds/boards/${boardDetails?.id}/ads`
                        );
                        showToastNotification("success", {
                          message: "Copied!",
                        });
                      }}
                      className="lg:hidden flex gap-1.5"
                      variant={"outline"}
                      size={"iconMid"}
                    >
                      <LinkIcon className="w-5 h-5" />
                    </Button>
                    <Button
                      onClick={() => setEditBoardDialogOpen(true)}
                      className="gap-1.5"
                      variant={"outline"}
                      size={"iconMid"}
                    >
                      <Pencil className="w-5 h-5" />
                    </Button>
                    <Button
                      onClick={() => setDeleteBoardDialogOpen(true)}
                      className="gap-1.5"
                      variant={"outline"}
                      size={"iconMid"}
                    >
                      <Trash className="w-5 h-5" />
                    </Button>
                  </div>
                  <Text
                    className="text-thememutedforeground w-fit text-nowrap hidden lg:flex"
                    size={"base"}
                    weight={"normal"}
                  >
                    {selectValue == "All Saved"
                      ? (boardDetails?.ads.length ?? 0) +
                        (boardDetails?.adTemplates ?? []).length
                      : (boardDetails?.adTemplates ?? []).length}
                    {(selectValue == "All Saved"
                      ? (boardDetails?.ads.length ?? 0) +
                        (boardDetails?.adTemplates ?? []).length
                      : (boardDetails?.adTemplates ?? []).length) == 1
                      ? " Item"
                      : " Items"}
                  </Text>
                </Stack>
              </div>
            ) : null}
          </Stack>
        </Stack>

        {isLoading ? (
          <div
            className={
              "flex justify-center items-center w-full h-screen m-auto"
            }
          >
            <Loader />
          </div>
        ) : error ? (
          <ErrorDisplay />
        ) : (
          <div className={""}>
            {boardDetails && (
              <>
                {(selectValue == "All Saved"
                  ? (boardDetails.landers ?? []).length == 0
                  : (boardDetails.landers ?? []).filter((data) => data.template)
                      .length == 0) &&
                (boardDetails.landingPageTemplates ?? []).length == 0 ? (
                  <div className={"lg:h-96 flex justify-center items-center"}>
                    <p>No lander added to this collection</p>
                  </div>
                ) : (
                  <div
                    className={
                      "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2.5"
                    }
                  >
                    {boardDetails.landingPageTemplates.map((card) => (
                      <LandingAdCard
                        key={card["LP ID"]}
                        adData={{
                          Screenshot: card["Landing Page Screenshot"],
                          ID: card["LP ID"],
                          created: card.Created,
                          category: card.Category,
                          isSaved: card.isSaved,
                          imageUrl: card.imageUrl,
                        }}
                        showTemplateButton
                        type={"landing-page"}
                      />
                    ))}
                    {boardDetails.landers.map((card) =>
                      selectValue == "All Saved" && !card.template ? (
                        <LandingFeedAdCard key={card.landerId} adData={card} />
                      ) : card.template ? (
                        <LandingAdCard
                          key={card.template["LP ID"]}
                          adData={{
                            Screenshot:
                              card.template["Landing Page Screenshot"],
                            ID: card.template["LP ID"],
                            created: card.template.Created,
                            category: card.template.Category,
                            isSaved: card.template.isSaved,
                            imageUrl: card.template.imageUrl,
                          }}
                          showTemplateButton
                          type={"landing-page"}
                        />
                      ) : (
                        <></>
                      )
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Stack>
    </>
  );
}
