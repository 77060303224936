import { useNavigate } from "@tanstack/react-router";
import { FC, ReactNode, useEffect, useState } from "react";

export const ProtectedArea: FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("_wos_user");

    // Check if the authToken exists
    if (user) {
      setIsAuthenticated(true);
    } else {
      // Navigate to login page if not authenticated
      navigate({ to: "/login" });
    }
  }, [navigate]);

  // Show loading or a placeholder while authentication is being checked
  if (!isAuthenticated) return <div></div>;

  // If authenticated, render the children (protected content)
  return <>{children}</>;
};
