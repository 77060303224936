import { trpc } from '@/App';
import FreeTrialEnding from '@/components/FreeTrialEnding';
import {
  MasonryComponent,
  TypedResponsiveMasonry,
} from '@/components/ResponsiveMasonryWrapper.tsx';
import { Stack, Text } from '@/components/custom-components';
import { Loader } from '@/components/custom-components/Loader';
import { ErrorDisplay } from '@/components/error';
import { Button } from '@/components/shadcn/button';
import { StartFreeTrialAdCard } from '@/components/templates/AdGridView';
import { LandingAdCard } from '@/components/templates/LandingAdCard';
import useCardLayoutWithImpressions from '@/hooks/useCardLayoutWithImpressions';
import { showTemplateRecentButton } from '@/utils/data/feeds.ts';
import { PRO_PLAN } from '@/utils/data/plans';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

export const Route = createFileRoute('/share/templates/landing-pages/$adID')({
  component: SharedLandingPageTemplate,
});

function SharedLandingPageTemplate() {
  const navigate = useNavigate({
    from: '/share/templates/landing-pages/$adID',
  });
  const { adID } = Route.useParams();
  const { cacheBuster } = Route.useSearch();

  if (!cacheBuster) {
    navigate({
      search: (old) => {
        return {
          ...old,
          cacheBuster: Math.random(),
        };
      },
      params: (old) => {
        return {
          ...old,
        };
      },
    });
  }

  const { data: authUser, isLoading: isLoadingMe } = trpc.me.useQuery(null);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);
  const { squareRef, columns, gutterWidth } = useCardLayoutWithImpressions();

  const {
    data: landerTemplates,
    isLoading: isLoadingLanderTemplate,
    error: landerTemplateError,
  } = trpc.getSharedLandingPageTemplate.useQuery({
    landingPageTemplateId: parseInt(adID),
  });

  const { data: permissionData } = trpc.permissions.useQuery(null, {});

  return (
    <div className={'relative h-[97vh]'}>
      {authUser && upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="!mb-6 gap-8">
        <Stack className="gap-3">
          <div
            className={
              'flex justify-between lg:justify-start gap-5 items-center'
            }
          >
            <Text weight="semibold" size={'xxl'}>
              Lander Templates
            </Text>
            {!authUser ? (
              <div className="flex gap-2 items-center">
                <a target={'_blank'} href={'https://app.creativeos.io/login'}>
                  <Button size={'sm'} variant={'ghost'} className="flex">
                    Log In
                  </Button>
                </a>

                <a target={'_blank'} href={'https://www.creativeos.io/#sign'}>
                  <Button
                    size={'sm'}
                    variant={'destructivePurple'}
                    className="flex"
                  >
                    Get Started
                  </Button>
                </a>
              </div>
            ) : authUser &&
              !(
                permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
              ) ? (
              <Button
                onClick={() => setUpgradeDialogOpen(true)}
                variant={'destructivePurple'}
                size={'sm'}
              >
                Upgrade
              </Button>
            ) : (
              <></>
            )}
          </div>
          <Text
            className="text-thememutedforeground w-fit"
            size={'base'}
            weight={'normal'}
          >
            Somebody shared a Landing Page template with you!
            {!authUser
              ? ` Sign up for CreativeOS Pro Plan to access this and our full library of Ad, Email, and Landing Page Templates, and so much more `
              : !(
                    permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                    permissionData?.usersPlans.includes(
                      PRO_PLAN.no_trial_plan_id,
                    )
                  )
                ? ' Upgrade to CreativeOS Pro to access our full library of Email and Landing Page Templates.'
                : ''}
            {!authUser && (
              <a
                className="text-black underline"
                target={'_blank'}
                href="https://www.creativeos.io/"
              >
                Learn more about CreativeOS.
              </a>
            )}
          </Text>
        </Stack>

        {isLoadingLanderTemplate || isLoadingMe ? (
          <div
            className={
              'flex justify-center items-center w-full h-screen m-auto'
            }
          >
            <Loader />
          </div>
        ) : landerTemplateError ? (
          <ErrorDisplay />
        ) : (
          landerTemplates && (
            <div ref={squareRef} className={'relative w-full pb-10'}>
              <div>
                <TypedResponsiveMasonry
                  columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                >
                  <MasonryComponent gutter={gutterWidth / 16 + 'rem'}>
                    {[
                      landerTemplates[0] && (
                        <LandingAdCard
                          IsShared
                          key={landerTemplates[0]['LP ID']}
                          openDetailsOnInit={
                            permissionData &&
                            authUser &&
                            (permissionData.usersPlans.includes(
                              PRO_PLAN.plan_id,
                            ) ||
                              permissionData.usersPlans.includes(
                                PRO_PLAN.no_trial_plan_id,
                              ))
                          }
                          adData={{
                            Screenshot:
                              landerTemplates[0]['Landing Page Screenshot'],
                            ID: landerTemplates[0]['LP ID'].toString(),
                            created: landerTemplates[0].Created,
                            category: landerTemplates[0].Category,
                            isSaved: landerTemplates[0].isSaved,
                            imageUrl: landerTemplates[0].imageUrl,
                          }}
                          showRecentButton={showTemplateRecentButton(
                            landerTemplates[0].Created,
                          )}
                          IsLocked={
                            !(
                              permissionData &&
                              authUser &&
                              (permissionData.usersPlans.includes(
                                PRO_PLAN.plan_id,
                              ) ||
                                permissionData.usersPlans.includes(
                                  PRO_PLAN.no_trial_plan_id,
                                ))
                            )
                          }
                          type={'landing-page'}
                        />
                      ),
                      ...(!(
                        permissionData &&
                        (permissionData.usersPlans.includes(PRO_PLAN.plan_id) ||
                          permissionData.usersPlans.includes(
                            PRO_PLAN.no_trial_plan_id,
                          ))
                      )
                        ? [
                            <StartFreeTrialAdCard
                              key="startFreeTrial"
                              fullWidthButton
                              titleText={
                                !authUser ? undefined : 'Upgrade to Unlock'
                              }
                              bodyText={
                                !authUser
                                  ? undefined
                                  : 'Level up your ad workflow with CreativeOS Pro'
                              }
                              buttonText={
                                !authUser ? 'Start Free Trial' : 'Upgrade Now'
                              }
                              href={
                                !authUser
                                  ? 'https://www.creativeos.io/#sign'
                                  : undefined
                              }
                              onClick={
                                authUser
                                  ? () => setUpgradeDialogOpen(true)
                                  : undefined
                              }
                            />,
                          ]
                        : []),
                      ...landerTemplates.slice(1).map((landingPageTemplate) => (
                        <LandingAdCard
                          key={landingPageTemplate['LP ID']}
                          adData={{
                            Screenshot:
                              landingPageTemplate['Landing Page Screenshot'],
                            ID: landingPageTemplate['LP ID'].toString(),
                            created: landingPageTemplate.Created,
                            category: landingPageTemplate.Category,
                            isSaved: landingPageTemplate.isSaved,
                            imageUrl: landingPageTemplate.imageUrl,
                          }}
                          showRecentButton={showTemplateRecentButton(
                            landingPageTemplate.Created,
                          )}
                          IsLocked={
                            !(
                              permissionData &&
                              authUser &&
                              (permissionData.usersPlans.includes(
                                PRO_PLAN.plan_id,
                              ) ||
                                permissionData.usersPlans.includes(
                                  PRO_PLAN.no_trial_plan_id,
                                ))
                            )
                          }
                          type={'landing-page'}
                        />
                      )),
                    ]}
                  </MasonryComponent>
                </TypedResponsiveMasonry>
              </div>
            </div>
          )
        )}
      </Stack>
    </div>
  );
}
