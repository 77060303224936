import FreeTrialEnding from '@/components/FreeTrialEnding.tsx';
import { Stack, Text } from '@/components/custom-components';
import { Loader } from '@/components/custom-components/Loader';
import { ErrorDisplay } from '@/components/error.tsx';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/shadcn/breadcrumb';
import { Button } from '@/components/shadcn/button';
import { Ad, DiscoverGridView } from '@/components/templates/DiscoverGridView';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard.tsx';
import showToastNotification from '@/hooks/useShowToast';
import { trpc } from '@/utils/trpc.ts';
import { Link as LinkIcon } from '@mynaui/icons-react';
import { createFileRoute } from '@tanstack/react-router';
import { Link } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute('/feeds/hidden-gems/')({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [allData, setAllData] = useState<Ad[] | undefined>(undefined);

  const [, copyToClipboard] = useCopyToClipboard();

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const {
    data: filteredAdFeed,
    fetchNextPage,
    isLoading,
    isError,
    isRefetching,
  } = trpc.filterAdFeed.useInfiniteQuery(
    { timeLive: ['hidden_gems'] },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (!inView || !filteredAdFeed || !allData || !allData.length) return;
    if (
      filteredAdFeed.pages[filteredAdFeed.pages.length - 1].ads.length === 0
    ) {
      return;
    }
    fetchNextPage();
  }, [inView, filteredAdFeed, allData, fetchNextPage]);

  useEffect(() => {
    if (!filteredAdFeed) return;

    setAllData(() => undefined);
    const records = [] as Ad[];
    for (const page of filteredAdFeed.pages) {
      records.push(...page.ads);
    }
    setAllData(() => records);
  }, [filteredAdFeed]);

  useEffect(() => {
    if (!isLoadingPermission && !permissionData?.userCanAccessEverything) {
      setUpgradeDialogOpen(true);
    }
  }, [permissionData?.userCanAccessEverything, isLoadingPermission]);

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }

  return (
    <>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-2">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to={'/feeds/live-trends'}>Collections</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Live Trends</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <div className={'flex justify-between gap-5 items-center'}>
            <div className="flex gap-2 items-center">
              <Text weight="semibold" size={'xxl'}>
                Find Treasure. Ignite Wonder.
              </Text>
            </div>

            <Button
              onClick={() => {
                copyToClipboard(`${window.location.origin}/feeds/hidden-gems`);
                showToastNotification('success', { message: 'Copied!' });
              }}
              className={'flex'}
              variant={'outline'}
              size={'iconSm'}
            >
              <LinkIcon />
            </Button>
          </div>
          <Text
            weight="normal"
            size={'lg'}
            className=" text-thememutedforeground"
          >
            {'Uncover hidden gems that elevate your creative edge.'}
          </Text>
        </Stack>
        {!allData || isLoading || isRefetching ? (
          <div className="flex justify-center items-center w-full h-screen">
            <Loader />
          </div>
        ) : (
          <div>
            {allData.length === 0 ? (
              <div className={'flex justify-center items-center'}>
                <p className={'text-center w-4/5 lg:w-1/2'}>
                  Oops! It looks like there are no ad inspirations matching your
                  filtered options right now. Please check back later for
                  updates.
                </p>
              </div>
            ) : (
              !isLoadingPermission &&
              permissionData?.userCanAccessEverything && (
                <DiscoverGridView data={allData} scrollRef={scrollRef} />
              )
            )}
          </div>
        )}
      </Stack>
    </>
  );
}

export default All;
