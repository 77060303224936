import { Stack, Text } from "@/components/custom-components";
import {
  AdDataProps,
  GetInspirationTemplate,
} from "@/components/templates/AdCard";
import { CalendarUp, Heart, Layout, User } from "@mynaui/icons-react";
import { Dispatch, SetStateAction } from "react";
import { Badge } from "./shadcn/badge";

export const TemplateDetailsAdBody = ({
  data,
  numberOfUsers,
  numberOfSaves,
  dateSaved,
  mediaComponent,
}: {
  data: AdDataProps;
  onCloseDrawer: () => void;
  saveOpen: boolean;
  setSaveOpen: Dispatch<SetStateAction<boolean>>;
  copyToClipboard: (x: string) => void;
  numberOfUsers: number;
  numberOfSaves: number;
  dateSaved: string;
  mediaComponent?: JSX.Element;
}) => (
  <div className="px-3 flex flex-col lg:px-8 py-3 pt-0 lg:py-8 lg:pt-4 gap-8 lg:gap-6">
    <div className="px-0 gap-2  flex flex-col">
      <div className="flex gap-2 items-center">
        <Text weight={"semibold"} size={"lg"} className="leading-7 w-fit">
          {data.brandName ?? "Ad"} Template
        </Text>
        <Badge variant={"secondary"} className="px-2.5 py-1 flex gap-1">
          <Layout className="w-4 h-4 min-w-4" /> {"Template"}
        </Badge>
      </div>
      <div
        className={"flex items-center gap-4 text-thememutedforeground text-sm"}
      >
        <div className={"flex items-center gap-1"}>
          <span>
            <User className={"w-4.5"} />
          </span>
          <span>{numberOfUsers || 0} users</span>
        </div>
        <div className={"flex items-center gap-1"}>
          <Heart className="w-4 h-4" />
          {numberOfSaves} saves
        </div>
      </div>
    </div>
    <GetInspirationTemplate
      buttonVersion
      Ad={data.Ad}
      IsPublic={data.IsPublic}
    />
    {mediaComponent}

    <Stack className={"flex flex-col gap-4 text-sm"}>
      <Text weight={"medium"} size="sm" className="leading-5">
        Template Details
      </Text>
      <div className={"flex justify-between items-center gap-4"}>
        <Text
          weight={"thin"}
          size="sm"
          className="leading-5 text-thememutedforeground w-24 min-w-24"
        >
          Created On
        </Text>
        <div className="flex items-center gap-1 max-w-full">
          <CalendarUp className="w-4 h-4 min-w-4" />

          <span
            className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap`}
          >
            {dateSaved}
          </span>
        </div>
      </div>
      <div className={"flex justify-between items-center gap-4"}>
        <Text
          weight={"thin"}
          size="sm"
          className="leading-5 text-thememutedforeground w-24 min-w-24"
        >
          Tags
        </Text>
        <Badge className="font-medium text-nowrap w-fit" variant={"outline"}>
          {data.Ad.Tags}
        </Badge>
      </div>
    </Stack>
  </div>
);
