import { defaultUseQueryRefetchOptions } from "@/_shared/constants";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { AdFilter, Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { InspirationRequestNotifier } from "@/components/custom-components/InspirationRequestNotifier";
import { Loader } from "@/components/custom-components/Loader";
import NotFoundBox from "@/components/custom-components/NotFound";
import { ErrorDisplay } from "@/components/error.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import {
  LandingFeedAdCard,
  LandingFeedProps,
} from "@/components/templates/LandingAdCard";
import { PostHogEvents } from "@/hooks/useCapturePosthogEvent.tsx";
import {
  AdFeedFilterOption,
  AdLanderSelectedFilters,
  LandingAdSearchParams,
} from "@/hooks/useFilterFeed.tsx";
import { featureFlagKeys } from "@/utils/data/featureFlags.ts";
import { getInspirationTabs } from "@/utils/data/feeds.ts";
import { trpc } from "@/utils/trpc.ts";
import { Shuffle } from "@mynaui/icons-react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useFeatureFlagEnabled, usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { z } from "zod";
import { landerFeedOrderFilter } from "../../../../src/shared/airtable";

export const Route = createFileRoute("/feeds/inspiration/landing-pages/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): LandingAdSearchParams => {
    const industry = search?.industry as string | undefined;
    const brands = search?.brands as string | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof landerFeedOrderFilter>
      | undefined;

    return {
      industry,
      brands,
      sideBarOpen,
      orderFilter,
    };
  },
});

function All() {
  const navigate = useNavigate();

  const {
    industry: queryIndustry,
    brands: queryBrands,
    orderFilter,
  } = Route.useSearch();

  const [allData, setAllData] = useState<LandingFeedProps[] | undefined>(
    undefined
  );
  const [filterOptions, setFilterOptions] = useState<AdFeedFilterOption[]>([]);
  const [cursor, setCursor] = useState(1);

  const posthog = usePostHog();

  const [selectedFilters, setSelectedFilters] =
    useState<AdLanderSelectedFilters>({
      categories: queryIndustry ? queryIndustry.split(",") : undefined,
      brands: queryBrands ? (queryBrands.split(",") as string[]) : undefined,
    });

  // Get the page categories to be passed to the filter
  const { data: pageIndustry } = trpc.getPageCategories.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });
  const { data: brands } = trpc.getBrands.useQuery(
    undefined,
    defaultUseQueryRefetchOptions
  );

  useEffect(() => {
    if (pageIndustry && brands && filterOptions.length === 0) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Avoid redundant updates
        return [
          {
            title: "Industry",
            counter: 0,
            optionItems: pageIndustry.map((i) => ({
              label: i.name,
              value: false,
            })),
          },
          {
            title: "Brand",
            counter: 0,
            optionItems: (brands ?? []).map((i) => ({
              label: i.brandName,
              logo: i.brandImage,
              value: false,
              id: i.brandId,
            })),
          },
        ];
      });
    }
  }, [pageIndustry, brands, filterOptions.length]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => ({
      ...option,
      optionItems: option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === "Industry" &&
            selectedFilters.categories?.includes(item.label)) ||
          (option.title === "Brand" &&
            selectedFilters.brands?.includes(item.id ?? "")),
      })),
    }));

    setFilterOptions((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(updatedOptions)) return prev; // Avoid unnecessary updates
      return updatedOptions;
    });
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: { industry?: string; brands?: string }) => {
      const searchParams = new URLSearchParams();

      if (params.industry) searchParams.set("industry", params.industry);
      if (params.brands) searchParams.set("industry", params.brands);

      navigate({
        to: "/feeds/inspiration/landing-pages",
        replace: true,
        search: (old) => {
          return { ...old, ...params, orderFilter };
        },
      });
    },
    [navigate]
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedIndustry: string[] = [];
      const selectedBrands: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Industry") selectedIndustry.push(item.label);
            if (group.title === "Brand") selectedBrands.push(`${item.id}`);
          }
        });
      });

      setSelectedFilters({
        categories: selectedIndustry.length > 0 ? selectedIndustry : undefined,
        brands: selectedBrands.length > 0 ? selectedBrands : undefined,
      });

      updateQueryString({
        industry:
          selectedIndustry.length > 0 ? selectedIndustry.join(",") : undefined,
        brands:
          selectedBrands.length > 0 ? selectedBrands.join(",") : undefined,
      });
    },
    [updateQueryString]
  );

  useEffect(() => {
    if (!selectedFilters) return;

    const params: Record<string, string | undefined> = {};

    if (selectedFilters.categories)
      params.categories = selectedFilters.categories.join(",");
  }, [selectedFilters]);

  // update cursor whenever selectedFilters change
  useEffect(() => {
    if (selectedFilters) {
      setCursor((prevCursor) => {
        if (prevCursor === 1) return prevCursor; // Avoid unnecessary reset
        return 1;
      });
    }
  }, [selectedFilters]);

  const {
    data: landingPages,
    fetchNextPage,
    refetch,
    isLoading,
    isError,
    isRefetching,
    hasNextPage,
    isFetchingNextPage,
  } = trpc.getAllLandingPages.useInfiniteQuery(
    {
      limit: 20,
      filters: {
        categories: selectedFilters.categories,
        brands: selectedFilters.brands,
        sort: orderFilter as z.infer<typeof landerFeedOrderFilter>,
      },
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      initialCursor: cursor,
    }
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      landingPages &&
      allData &&
      allData.length &&
      landingPages.pages[landingPages.pages.length - 1]?.results.length > 0
    ) {
      fetchNextPage();
    }
  }, [
    inView,
    hasNextPage,
    fetchNextPage,
    landingPages,
    isFetchingNextPage,
    allData,
  ]);

  useEffect(() => {
    if (!landingPages) return;

    const uniqueResults = new Map<number, LandingFeedProps>();
    landingPages.pages.forEach((page) => {
      page.results.forEach((result) =>
        uniqueResults.set(result.landerId, result)
      );
    });

    setAllData((prevData) => {
      const newData = Array.from(uniqueResults.values());
      if (JSON.stringify(prevData) === JSON.stringify(newData)) return prevData; // Prevent redundant updates
      return newData;
    });
  }, [landingPages]);

  const flagEnabled = useFeatureFlagEnabled(featureFlagKeys.emailFeeds);
  const [isResolved, setIsResolved] = useState(false);

  useEffect(() => {
    if (flagEnabled !== undefined) {
      setIsResolved(true); // Mark the flag as resolved once its value is known
    }
  }, [flagEnabled]);

  if (!isResolved) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-6">
          <div
            className={
              "flex justify-between lg:justify-start gap-5 items-start lg:items-center"
            }
          >
            <Text weight="semibold" size={"xxl"}>
              Get Inspired
            </Text>
            <div className={"flex gap-2 flex-wrap"}>
              <FeatureTabs
                defaultOrderValue={"Random"}
                tabItems={getInspirationTabs({ showEmailFeeds: flagEnabled })}
              />
              <div className="flex items-center justify-end gap-2">
                <div className="lg:hidden">
                  <OrderFilterSelect
                    defaultFilter={orderFilter || "Random"}
                    options={["Random", "Newest", "Popular", "Most Requested"]}
                  />
                </div>
                <Button
                  size={"sm"}
                  className="h-8"
                  variant={"brandGradient"}
                  onClick={() => {
                    if (orderFilter !== "Random") {
                      navigate({
                        search: (old) => {
                          return {
                            ...old,
                            orderFilter: "Random",
                          };
                        },
                        params: (old) => {
                          return {
                            ...old,
                          };
                        },
                      });
                      posthog?.capture(
                        PostHogEvents.USER_SHUFFLED_LANDERS_INSPIRATION
                      );
                    } else {
                      refetch();
                    }
                  }}
                >
                  <Shuffle className="h-5" />
                  Shuffle
                </Button>
              </div>
            </div>
          </div>
        </Stack>
      </Stack>
      <div
        className={`lg:sticky lg:top-[3.5rem] lg:z-10 lg:bg-themebackground dark:lg:bg-themebackgrounddark  lg:py-2 flex ${pageIndustry && filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-center gap-1.5`}
      >
        {pageIndustry && filterOptions && filterOptions.length > 0 && (
          <AdFilter
            initialOptions={filterOptions}
            onOptionsChange={handleOptionsChange}
          />
        )}

        <div className={"hidden lg:flex"}>
          <OrderFilterSelect
            defaultFilter={orderFilter || "Random"}
            options={["Random", "Newest", "Popular", "Most Requested"]}
          />
        </div>
      </div>
      <InspirationRequestNotifier />

      {isLoading || isRefetching ? (
        <div className="flex justify-center items-center w-full h-screen">
          <Loader />
        </div>
      ) : (
        <div>
          {landingPages && allData && allData.length === 0 ? (
            <NotFoundBox />
          ) : (
            <div
              className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"}
            >
              {landingPages &&
                allData &&
                allData.length > 0 &&
                allData.map((card) => (
                  <LandingFeedAdCard key={card.landerId} adData={card} />
                ))}
            </div>
          )}
        </div>
      )}
      <div ref={scrollRef} className="flex justify-center w-full min-h-14 h-14">
        {isFetchingNextPage && <Loader />}
      </div>
    </Stack>
  );
}

export default All;
