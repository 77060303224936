import { useState } from "react";
import { EmailFeedContent } from "../../../../src/server/services/emails/definitions";
import EmailInspirationDetails from "./EmailInspirationDetails";
import { Badge } from "./shadcn/badge";

const SearchDialogEmailInspirationImage = ({
  item,
  userIsTyping = false,
  collections = [],
}: {
  item: EmailFeedContent;
  collections?: string[];
  userIsTyping?: boolean;
}) => {
  const [detailsShow, setDetailsShow] = useState<boolean>(false);

  return (
    <div
      className={`${userIsTyping ? "" : "bg-slate-100"} rounded-2xl`}
      key={item.id}
    >
      {detailsShow && (
        <EmailInspirationDetails
          open={detailsShow}
          onOpenChange={() => setDetailsShow(false)}
          data={item}
          IsPublic={false}
        />
      )}
      {userIsTyping ? (
        <div className="flex gap-3 items-center justify-between w-full ">
          <div className="flex gap-2 items-center">
            <img
              src={
                item.imageUrl ??
                "https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs"
              }
              alt={`${item.brandName ?? "CreativeOs"} Image`}
              onClick={() => setDetailsShow(true)}
              loading="eager"
              className="min-w-[5rem] w-[5rem] h-[5rem] rounded-2xl object-cover cursor-pointer"
            />
            <div className="flex gap-1 items-center flex-wrap">
              {collections.map((collection) => (
                <Badge variant={"outline"} className="px-2.5 py-1 flex gap-1">
                  {collection}
                </Badge>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[11.4063rem] min-w-[11.4063rem] w-[11.4063rem] relative overflow-hidden rounded-md">
          <img
            src={
              item.imageUrl ??
              "https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs"
            }
            alt={`${item.brandName ?? "CreativeOs"} Image`}
            onClick={() => setDetailsShow(true)}
            loading="eager"
            className="min-w-[11.4063rem] w-[11.4063rem] h-[11.4063rem] rounded-2xl object-cover object-center scale-[200%] origin-top cursor-pointer hvr-grow"
          />
        </div>
      )}
    </div>
  );
};

export default SearchDialogEmailInspirationImage;
