export const otherFilterOptions = [
  {
    title: 'Format',
    counter: 0,
    optionItems: [
      { label: 'image', value: false },
      { label: 'video', value: false },
    ],
  },
  {
    title: 'Status',
    counter: 0,
    optionItems: [
      { label: 'live', value: false },
      { label: 'not_live', value: false },
    ],
  },
  {
    title: 'Time Live',
    counter: 0,
    optionItems: [
      { label: 'under_7_days', value: false },
      { label: 'over_7_days', value: false },
      { label: 'over_30_days', value: false },
      { label: 'over_3_months', value: false },
      { label: 'over_6_months', value: false },
      { label: 'over_12_months', value: false },
    ],
  },
];
