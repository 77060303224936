import { Stack, Text } from '@/components/custom-components';
import OnboardingSteppers from '@/components/onboarding/OnboardingSteppers.tsx';
import { Button } from '@/components/shadcn/button.tsx';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/shadcn/form.tsx';
import { Input } from '@/components/shadcn/input.tsx';
import showToastNotification from '@/hooks/useShowToast';
import {
  OnboardingFormSchema,
  SectionProps,
  companyTypes,
  roles,
} from '@/routes/get-started.tsx';
import { trpc } from '@/utils/trpc.ts';
import { useNavigate } from '@tanstack/react-router';
import { z } from 'zod';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../shadcn/select';

export default function ClientInfo(props: SectionProps) {
  const navigate = useNavigate();

  const { mutate: completeOnboardingStep, isPending } =
    trpc.completeOnboardingStep.useMutation();

  async function onSubmit(data: z.infer<typeof OnboardingFormSchema>) {
    const selected = data.company_types;
    const role = data.roles;

    completeOnboardingStep(
      {
        company: selected ? selected : undefined,
        companyName: data.company_name,
        role,
      },
      {
        onSuccess: (data) => {
          if (data && data.id) {
            navigate({
              to: '/get-started',
              search: { step: 'brief' },
            });
          }
        },
        onError: (error) => {
          showToastNotification('error', {
            message: error.message,
            description:
              'Please select one of the options above or skip to proceed',
          });
        },
      },
    );
  }
  return (
    <div
      className={
        'rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border'
      }
    >
      <div className={'flex flex-col gap-6'}>
        <div className={'flex gap-5 justify-end'}>
          <OnboardingSteppers stepIndex={0} />
        </div>
        <Stack className="gap-2">
          <Text size={'xxl'} weight={'semibold'}>
            Welcome to CreativeOS!
          </Text>
          <Text size={'base'} weight={'normal'}>
            Tell us a bit about yourself
          </Text>
        </Stack>
      </div>
      <div>
        <Form {...props.form}>
          <form
            onSubmit={props.form.handleSubmit(onSubmit)}
            className="space-y-3"
          >
            <FormField
              control={props.form.control}
              name="company_name"
              render={({ field }) => (
                <FormControl>
                  <FormItem>
                    <Stack className={'gap-1'}>
                      <Text size={'sm'} weight={'medium'}>
                        Company Name
                      </Text>
                      <Input
                        className="shadow-none w-full"
                        placeholder={'Company Name'}
                        {...field}
                      />
                      <FormMessage />
                    </Stack>
                  </FormItem>
                </FormControl>
              )}
            />
            <FormField
              control={props.form.control}
              name="company_types"
              render={({ field }) => (
                <FormControl>
                  <FormItem>
                    <Stack className={'gap-1'}>
                      <Text size={'sm'} weight={'medium'}>
                        Company Type
                      </Text>
                      <Select
                        value={field.value}
                        onValueChange={(value) => field.onChange(value)}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Company Type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {companyTypes.map((item) => (
                              <SelectItem
                                value={item.value}
                                className="px-2 py-1.5"
                              >
                                {item.label}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </Stack>
                  </FormItem>
                </FormControl>
              )}
            />
            <FormField
              control={props.form.control}
              name="roles"
              render={({ field }) => (
                <FormControl>
                  <FormItem>
                    <Stack className={'gap-1'}>
                      <Text size={'sm'} weight={'medium'}>
                        Role
                      </Text>
                      <Select
                        value={field.value}
                        onValueChange={(value) => field.onChange(value)}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Role" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {roles.map((item) => (
                              <SelectItem
                                value={item.value}
                                className="px-2 py-1.5"
                              >
                                {item.label}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </Stack>
                  </FormItem>
                </FormControl>
              )}
            />

            <div className={'flex justify-end items-center space-x-2'}>
              <Button
                type={'submit'}
                className={'px-8 h-10'}
                variant={'default'}
                size={'lg'}
                disabled={isPending}
                loading={isPending}
              >
                {isPending ? 'Submitting...' : 'Next'}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
