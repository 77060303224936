import { Button, Text } from "@/components/custom-components";
import { Loader } from "@/components/custom-components/Loader";
import OnboardingHeader from "@/components/onboarding/OnboardingHeader.tsx";
import { trpc } from "@/utils/trpc.ts";
import { useNavigate } from "@tanstack/react-router";

export default function OnboardingComplete() {
  const navigate = useNavigate();

  const { mutateAsync: completeOnboarding, isPending } =
    trpc.completeOnboarding.useMutation();

  if (isPending) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={"bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen"}
    >
      <div>
        <OnboardingHeader />
      </div>
      <div
        className={
          "flex-1 flex flex-col justify-center items-center py-[7.75rem]"
        }
      >
        <div
          className={
            "flex flex-col justify-center items-center text-white text-center"
          }
        >
          <Text
            weight={"semibold"}
            className={"text-4xl mb-5"}
            align={"center"}
            as={"h2"}
          >
            Get a head start
          </Text>
          <Text weight={"medium"} size={"lg"} align={"center"} as={"p"}>
            Get up to speed on how to use our most impactful features
          </Text>
          <Button
            onClick={async () => {
              await completeOnboarding();
              await navigate({
                to: "/feeds/templates",
                search: {
                  orderFilter: "Recent",
                  cacheBuster: Math.random(),
                  getStarted: "ready",
                },
              });
            }}
            className={
              "min-w-[13.125rem] text-xl self-center mt-10 border-2 border-white"
            }
            variant={"secondary"}
          >
            <span className={"hidden lg:flex"}>Start Creating</span>
            <span className={"flex lg:hidden"}>I'm ready</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
