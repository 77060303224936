export default function OnboardingHeader() {
  return (
    <div
      className={
        'bg-transparent px-5 pt-5 lg:px-10 lg:pt-10 lg:pb-5 flex gap-5'
      }
    >
      <div>
        
         <img
              alt={"CreativeOS"}
              src={"/images/logo-full-dark_mode.png"}
              className={'max-w-40'}
              />
      </div>
    </div>
  );
}
