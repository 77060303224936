import { Link } from "@tanstack/react-router";
import { useState } from "react";
import { AirTableAdRecord } from "../../../../src/shared/airtable";
import TemplateDetails from "./TemplateDetails";
import { Badge } from "./shadcn/badge";

const SearchDialogAdTemplateImage = ({
  item,
  userIsTyping = false,
  collections = [],
}: {
  item: AirTableAdRecord;
  collections?: {collectionName: string, collectionId: string}[];
  userIsTyping?: boolean;
}) => {
  const [detailsShow, setDetailsShow] = useState<boolean>(false);

  return (
    <div
      className={`${userIsTyping ? "" : "bg-slate-100"} rounded-2xl`}
      key={item["Creative ID"]}
    >
      {detailsShow && item && (
        <TemplateDetails
          data={{
            Ad: item,
            IsLocked: false,
            IsPublic: false,
            brandName: undefined,
            shouldInvalidateCache: false,
            DesiredWidth: 0,
          }}
          open={detailsShow}
          onCloseDrawer={() => setDetailsShow(false)}
        />
      )}
      {userIsTyping ? (
        <div className="flex gap-3 items-center justify-between w-full ">
          <div className="flex gap-2 items-center">
            <img
              src={
                item.imageUrl ??
                "https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOS"
              }
              alt={`${item.brandName ?? "CreativeOs"} Image`}
              onClick={() => setDetailsShow(true)}
              loading="eager"
              className="min-w-[5rem] w-[5rem] h-[5rem] rounded-2xl object-cover cursor-pointer"
            />
            <div className="flex gap-1 items-center flex-wrap">
              {collections.map((collection) => (
                <Link
                  className="text-slate-500"
                  to="/feeds/collections/$collectionID"
                  params={{ collectionID: collection.collectionId }}
                >
                  <Badge variant={"outline"} className="px-2.5 py-1 flex gap-1">
                    {collection.collectionName}
                  </Badge>
                </Link>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <img
          src={
            item.imageUrl ??
            "https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs"
          }
          alt={`${item.brandName ?? "CreativeOs"} Image`}
          onClick={() => setDetailsShow(true)}
          loading="eager"
          className="min-w-[10.7rem] w-[10.7rem] h-[10.7rem] rounded-2xl object-cover cursor-pointer hvr-grow"
        />
      )}
    </div>
  );
};

export default SearchDialogAdTemplateImage;
