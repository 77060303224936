import { Stack, Text } from "@/components/custom-components";
import { Loader } from "@/components/custom-components/Loader";
import { ErrorDisplay } from "@/components/error.tsx";
import {
  BrandDataProps,
  BrandsGridView,
} from "@/components/templates/BrandsGridView";
import { trpc } from "@/utils/trpc.ts";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";

type SearchParams = {
  sideBarOpen?: boolean;
  searchTerm?: string;
};

export const Route = createFileRoute("/feeds/search/brands")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;
    const searchTerm = search?.searchTerm as string | undefined;

    return {
      sideBarOpen,
      searchTerm,
    };
  },
});

function All() {
  const { searchTerm: querySearchTerm } = Route.useSearch();
  const {
    data: brands,
    isLoading,
    error,
  } = trpc.searchBrands.useQuery(
    {
      limit: 20,
      query: querySearchTerm ?? "",
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  useEffect(() => {
    if (brands)
      setFilteredBrands(
        brands.data.map((brand) => ({
          ...brand,
          brandImage: brand.brandImage ?? null,
          fbPageId: "",
          totalActiveAdCount: 0,
        }))
      );
  }, [brands]);

  const [filteredBrands, setFilteredBrands] = useState<BrandDataProps[]>([]);

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="gap-3 lg:gap-6 lg:sticky lg:top-0 lg:z-10 lg:bg-themebackground lg:dark:bg-themebackgrounddark lg:py-2 flex justify-between items-center w-full">
        <div className={"flex justify-between gap-5 items-center w-full"}>
          <Text weight="semibold" size={"xxl"} className="w-fit">
            Your Discovery Results {brands ? ` (${brands?.totalHits})` : ""}:
            <span className="opacity-45">“{querySearchTerm}”</span>
          </Text>
        </div>
      </Stack>

      {brands && filteredBrands && <BrandsGridView data={filteredBrands} />}
    </Stack>
  );
}
