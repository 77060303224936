import { useUserbackContext } from '@/contexts/CustomUserbackContext';
import { Label } from './shadcn/label';
import { Switch } from './shadcn/switch';

const FeedbackToggle = () => {
  const { isVisible, toggleUserback } = useUserbackContext();

  return (
    <div className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
      <Label htmlFor="feedback">Display Feedback Button</Label>
      <Switch
        checked={isVisible}
        onCheckedChange={toggleUserback}
        id="feedback"
      />
    </div>
  );
};

export default FeedbackToggle;
