import { OrderFilterSelect } from '@/components/ad-inspiration/OrderFilterSelect.tsx';
import { AdFilter, Stack, Text } from '@/components/custom-components';
import { FeatureTabs } from '@/components/custom-components/FeatureTabs';
import { Loader } from '@/components/custom-components/Loader';
import NotFoundBox from '@/components/custom-components/NotFound';
import { ErrorDisplay } from '@/components/error.tsx';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/shadcn/breadcrumb.tsx';
import { Button } from '@/components/shadcn/button.tsx';
import { Ad, DiscoverGridView } from '@/components/templates/DiscoverGridView';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard.tsx';
import {
  AdFeedFilterOption,
  AdSelectedFilters,
  TemplateAdSearchParams,
} from '@/hooks/useFilterFeed.tsx';
import { featureFlagKeys } from '@/utils/data/featureFlags';
import { otherFilterOptions } from '@/utils/data/filters.ts';
import { trpc } from '@/utils/trpc.ts';
import { Link as LinkIcon } from '@mynaui/icons-react';
import { Link, createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { toast } from 'sonner';
import { z } from 'zod';
import {
  FormatFilter,
  StatusFilters,
  TimeLiveFilters,
} from '../../../../src/server/types';
import { orderFilter as OrderFilterType } from '../../../../src/shared/airtable';

export const Route = createFileRoute('/feeds/brands/$brandID/')({
  component: BrandAds,
  validateSearch: (search: Record<string, unknown>): TemplateAdSearchParams => {
    const formats = search?.formats as string | undefined;
    const timeLive = search?.timeLive as string | undefined;
    const statuses = search?.statuses as string | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      formats,
      timeLive,
      statuses,
      sideBarOpen,
      orderFilter,
    };
  },
});

function BrandAds() {
  const navigate = useNavigate();

  const { brandID } = Route.useParams();

  const BRAND_TABS = [
    {
      name: 'Ads',
      link: `/feeds/brands/${brandID}`,
    },
    {
      name: 'Emails',
      link: `/feeds/brands/${brandID}/emails`,
    },
    {
      name: 'Landers',
      link: `/feeds/brands/${brandID}/landing-pages`,
    },
  ];

  const getBrandTabs = ({
    showEmailFeeds = false,
  }: {
    showEmailFeeds?: boolean;
  }) => {
    return showEmailFeeds
      ? BRAND_TABS
      : BRAND_TABS.filter((tab) => tab.name !== 'Emails');
  };

  const [, copyToClipboard] = useCopyToClipboard();

  // Filter for brand => will refactor into hook later

  const {
    formats: queryFormats,
    timeLive: queryTimeLive,
    statuses: queryStatus,
    orderFilter,
  } = Route.useSearch();

  const [allData, setAllData] = useState<Ad[] | undefined>(undefined);
  const [filterOptions, setFilterOptions] = useState<AdFeedFilterOption[]>([]);
  const [cursor, setCursor] = useState<number>(1);

  const [selectedFilters, setSelectedFilters] = useState<AdSelectedFilters>({
    formats: queryFormats
      ? (queryFormats.split(',') as FormatFilter[])
      : undefined,
    timeLive: queryTimeLive
      ? (queryTimeLive.split(',') as TimeLiveFilters[])
      : undefined,
    statuses: queryStatus
      ? (queryStatus.split(',') as StatusFilters[])
      : undefined,
  });

  useEffect(() => {
    if (filterOptions.length === 0) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Prevent redundant updates
        return [...otherFilterOptions];
      });
    }
  }, [filterOptions.length]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => ({
      ...option,
      optionItems: option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === 'Format' &&
            selectedFilters.formats?.includes(item.label as FormatFilter)) ||
          (option.title === 'Time Live' &&
            selectedFilters.timeLive?.includes(
              item.label as TimeLiveFilters,
            )) ||
          (option.title === 'Status' &&
            selectedFilters.statuses?.includes(item.label as StatusFilters)),
      })),
    }));

    setFilterOptions((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(updatedOptions)) return prev; // Prevent unnecessary updates
      return updatedOptions;
    });
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: { formats?: string; timeLive?: string; statuses?: string }) => {
      const searchParams = new URLSearchParams();

      if (params.formats) searchParams.set('formats', params.formats);
      if (params.timeLive) searchParams.set('timeLive', params.timeLive);
      if (params.statuses) searchParams.set('statuses', params.statuses);

      navigate({
        to: '/feeds/brands/$brandID',
        replace: true,
        search: (old) => {
          return { ...old, ...params, orderFilter };
        },
        params: {
          brandID,
        },
      });
    },
    [navigate],
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedFormats: FormatFilter[] = [];
      const selectedTimeLive: TimeLiveFilters[] = [];
      const selectedStatus: StatusFilters[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === 'Format')
              selectedFormats.push(item.label as FormatFilter);
            if (group.title === 'Time Live')
              selectedTimeLive.push(item.label as TimeLiveFilters);
            if (group.title === 'Status')
              selectedStatus.push(item.label as StatusFilters);
          }
        });
      });

      setSelectedFilters({
        formats: selectedFormats.length > 0 ? selectedFormats : undefined,
        timeLive: selectedTimeLive.length > 0 ? selectedTimeLive : undefined,
        statuses: selectedStatus.length > 0 ? selectedStatus : undefined,
      });

      updateQueryString({
        formats:
          selectedFormats.length > 0 ? selectedFormats.join(',') : undefined,
        timeLive:
          selectedTimeLive.length > 0 ? selectedTimeLive.join(',') : undefined,
        statuses:
          selectedStatus.length > 0 ? selectedStatus.join(',') : undefined,
      });
    },
    [updateQueryString],
  );

  useEffect(() => {
    if (!selectedFilters) return;

    const params: Record<string, string | undefined> = {};

    if (selectedFilters.formats)
      params.formats = selectedFilters.formats.join(',');
    if (selectedFilters.timeLive)
      params.timeLive = selectedFilters.timeLive.join(',');
    if (selectedFilters.statuses)
      params.statuses = selectedFilters.statuses.join(',');
  }, [selectedFilters]);

  // update cursor whenever selectedFilters change
  useEffect(() => {
    if (selectedFilters) {
      setCursor((prevCursor) => {
        if (prevCursor === 1) return prevCursor; // Avoid unnecessary reset
        return 1;
      });
    }
  }, [selectedFilters]);

  const {
    data: brandAds,
    fetchNextPage,
    isLoading,
    isRefetching,
    error,
  } = trpc.getBrandAds.useInfiniteQuery(
    {
      brandId: brandID,
      limit: 20,
      ...selectedFilters,
      order: orderFilter,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      initialCursor: cursor,
    },
  );

  // Ref for trigger to fetch next page
  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (
      inView &&
      brandAds &&
      allData &&
      allData.length &&
      brandAds.pages[brandAds.pages.length - 1]?.ads.length > 0
    ) {
      fetchNextPage();
    }
  }, [inView, brandAds, allData, fetchNextPage]);

  // Syncs react state with trpc state
  useEffect(() => {
    if (!brandAds) return;

    const uniqueAds = new Map<number, Ad>();
    brandAds.pages.forEach((page) => {
      page.ads.forEach((ad) => uniqueAds.set(ad.id, ad));
    });

    setAllData((prevData) => {
      const newData = Array.from(uniqueAds.values());
      if (JSON.stringify(prevData) === JSON.stringify(newData)) return prevData; // Prevent redundant updates
      return newData;
    });
  }, [brandAds]);

  const flagEnabled = useFeatureFlagEnabled(featureFlagKeys.brandEmails);
  const [isResolved, setIsResolved] = useState(false);

  useEffect(() => {
    if (flagEnabled !== undefined) {
      setIsResolved(true); // Mark the flag as resolved once its value is known
    }
  }, [flagEnabled]);

  useEffect(() => {
    if (isResolved && flagEnabled === false) {
      navigate({ to: `/feeds/brands/$brandID`, params: { brandID } });
    }
  }, [isResolved, flagEnabled, navigate]);

  if (!isResolved) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <ErrorDisplay />;
  }

  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-2">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to={'/feeds/brands'}>Brands</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>
                  {(brandAds?.pages ?? []).length > 0
                    ? brandAds?.pages[0].brandName
                    : ''}
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <div className={'flex justify-between gap-5 items-center'}>
            {(brandAds?.pages ?? []).length > 0 && (
              <div className="flex gap-2 items-center">
                <img
                  className={'rounded-full min-w-12 w-12 h-12 bg-themeaccent'}
                  src={
                    brandAds?.pages[0].brandImage
                      ? brandAds.pages[0].brandImage
                      : '/bg_gradient.jpg'
                  }
                  alt={brandAds?.pages[0].brandName || ''}
                />
                <Text weight="semibold" size={'xxl'}>
                  {brandAds?.pages[0].brandName || ''}
                </Text>
              </div>
            )}
            <Button
              onClick={() => {
                copyToClipboard(
                  `${window.location.origin}/feeds/brands/${brandID}`,
                );
                toast.success('Copied!');
              }}
              className={'flex'}
              variant={'outline'}
              size={'iconSm'}
            >
              <LinkIcon />
            </Button>
          </div>
        </Stack>
        <div
          className={'flex justify-between lg:justify-start gap-5 items-center'}
        >
          <FeatureTabs
            defaultOrderValue={'Random'}
            tabItems={getBrandTabs({ showEmailFeeds: flagEnabled })}
          />
          <div className={'flex lg:hidden'}>
            <OrderFilterSelect
              defaultFilter={orderFilter || 'Random'}
              options={['Random', 'Recent', 'Popular', 'Most Requested']}
            />
          </div>
        </div>
      </Stack>
      <div
        className={`lg:sticky lg:top-0 lg:z-10 lg:bg-themebackground dark:lg:bg-themebackgrounddark  lg:py-2 flex ${filterOptions && filterOptions.length > 0 ? 'justify-between' : 'justify-end'} items-center gap-1.5`}
      >
        {filterOptions && filterOptions.length > 0 && (
          <AdFilter
            initialOptions={filterOptions}
            onOptionsChange={handleOptionsChange}
          />
        )}

        <div className={'hidden lg:flex'}>
          <OrderFilterSelect
            defaultFilter={orderFilter || 'Random'}
            options={['Random', 'Recent', 'Popular', 'Most Requested']}
          />
        </div>
      </div>
      {isLoading || isRefetching ? (
        <div className="flex justify-center items-center w-full h-screen">
          <Loader />
        </div>
      ) : (
        <div>
          {brandAds && allData && allData.length === 0 ? (
            <NotFoundBox />
          ) : (
            brandAds &&
            allData &&
            allData.length > 0 && (
              <DiscoverGridView data={allData} scrollRef={scrollRef} />
            )
          )}
        </div>
      )}
    </Stack>
  );
}
