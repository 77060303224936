import { trpc } from '@/utils/trpc';
import { ArrowRight, Lock } from '@mynaui/icons-react';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';
import FreeTrialEnding from './FreeTrialEnding';
import { PermissionData } from './SearchDialog';
import SearchDialogAdTemplateImage from './SearchDialogAdTemplateImage';
import SearchDialogEmailLanderTemplateImage from './SearchDialogEmailTemplateImage';
import { Badge } from './shadcn/badge';
import { ScrollArea, ScrollBar } from './shadcn/scroll-area';
import { Skeleton } from './shadcn/skeleton';

const placeholderEmails = [
  '/placeholder/sample-email1.jpg',
  '/placeholder/sample-email2.jpg',
  '/placeholder/sample-email3.jpg',
];

const placeholderLanders = [
  '/placeholder/sample-lander1.jpg',
  '/placeholder/sample-lander2.jpg',
  '/placeholder/sample-lander3.jpg',
];

const SearchDialogTemplateSection = ({
  permissions,
}: {
  permissions: PermissionData;
}) => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: randomAdTemplates, isLoading: adTemplatesIsLoading } =
    trpc.posts.useQuery({
      sortingOptions: 'Recent',
      Tags: undefined,
      limit: 3,
      Ready: true,
    });

  const { data: randomEmailTemplates, isLoading: emailTemplatesIsLoading } =
    trpc.emails.useQuery({
      sortingOptions: 'Recent',
      limit: 3,
    });

  const { data: randomLandersTemplates, isLoading: landerTemplatesIsLoading } =
    trpc.landingPages.useQuery({
      sortingOptions: 'Recent',
      limit: 3,
    });

  return (
    <div className="flex flex-col gap-2 pb-6">
      {upgradeDialogOpen && permissions && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissions}
        />
      )}
      <div className="flex flex-col gap-0">
        <h6 className="text-sm">Ads</h6>
        <div className="flex gap-2 h-[12.8063rem]">
          {adTemplatesIsLoading ? (
            <div className="flex space-x-2.5">
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  className="min-w-[11.4063rem] w-[11.4063rem] h-[11.4063rem] rounded-2xl"
                />
              ))}
            </div>
          ) : (
            <ScrollArea className="whitespace-nowrap space-y-2.5">
              <div className="flex space-x-2.5 pr-5">
                {randomAdTemplates?.ATRecords.map((item) => (
                  <SearchDialogAdTemplateImage
                    key={item['Creative ID']}
                    item={{
                      ...item,
                      form: '',
                      'Example Image': [],
                      'Template Image': [],
                      Status: '',
                      AdDbId: null,
                      Expert: undefined,
                      Tags: undefined,
                      Liked: item.Liked ?? false,
                      'Canva Template LInk': item['Canva Template LInk'] ?? '',
                    }}
                  />
                ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </div>

        <div className="flex justify-end mr-3">
          <Link className="text-slate-500" to="/feeds/templates">
            <span className="flex items-center gap-2 text-sm">
              <span>See all</span> <ArrowRight size={'1rem'} />
            </span>
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-0">
        <h6 className="text-sm">Emails</h6>
        <div className="flex gap-2 h-[12.8063rem]">
          {emailTemplatesIsLoading ? (
            <div className="flex space-x-2.5">
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  className="min-w-[11.4063rem] w-[11.4063rem] h-[11.4063rem] rounded-2xl"
                />
              ))}
            </div>
          ) : (
            <ScrollArea className="whitespace-nowrap space-y-2.5">
              <div className="flex space-x-2.5 pr-5">
                {!randomEmailTemplates?.HasAccess ? (
                  <div className="flex space-x-2.5">
                    {placeholderEmails?.map((emailImg) => (
                      <div
                        className="relative cursor-pointer"
                        onClick={() => setUpgradeDialogOpen(true)}
                        key={emailImg}
                      >
                        <img
                          src={emailImg}
                          alt={`Image`}
                          loading="eager"
                          className="min-w-[11.4063rem] w-[11.4063rem] h-[11.4063rem]  rounded-2xl object-cover cursor-pointer hvr-grow filter blur-sm pointer-events-none"
                        />
                        <Badge
                          variant={'outline'}
                          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-themedestructive border-themedestructive"
                        >
                          <Lock size={'1rem'} /> PRO
                        </Badge>
                      </div>
                    ))}
                  </div>
                ) : (
                  randomEmailTemplates?.Emails.map((item) => (
                    <SearchDialogEmailLanderTemplateImage
                      key={item['Email ID']}
                      item={{
                        Screenshot: item['Email Screenshot'],
                        ID: item['Email ID'].toString(),
                        created: item.Created,
                        category: item.Category ?? '',
                        isSaved: item.isSaved,
                        imageUrl: item.imageUrl,
                        brandId: item.brandId,
                        brandName: item.brandName,
                        brandImage: item.brandImage,
                      }}
                      type="email"
                    />
                  ))
                )}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </div>

        <div className="flex justify-end mr-3">
          <Link
            className="text-slate-500"
            to="/feeds/templates/emails"
            search={true}
          >
            <span className="flex items-center gap-2 text-sm">
              <span>See all</span> <ArrowRight size={'1rem'} />
            </span>
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-0">
        <h6 className="text-sm">Landers</h6>
        <div className="flex gap-2 h-[12.8063rem]">
          {landerTemplatesIsLoading ? (
            <div className="flex space-x-2.5">
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  className="min-w-[11.4063rem] w-[11.4063rem] h-[11.4063rem] rounded-2xl"
                />
              ))}
            </div>
          ) : (
            <ScrollArea className="whitespace-nowrap space-y-2.5">
              <div className="flex space-x-2.5 pr-5">
                {!randomLandersTemplates?.HasAccess ? (
                  <div className="flex space-x-2.5">
                    {placeholderLanders?.map((landerImg) => (
                      <div
                        className="relative cursor-pointer"
                        onClick={() => setUpgradeDialogOpen(true)}
                        key={landerImg}
                      >
                        <img
                          src={landerImg}
                          alt={`Image`}
                          loading="eager"
                          className="min-w-[11.4063rem] w-[11.4063rem] h-[11.4063rem]  rounded-2xl object-cover cursor-pointer hvr-grow filter blur-sm pointer-events-none"
                        />
                        <Badge
                          variant={'outline'}
                          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-themedestructive border-themedestructive"
                        >
                          <Lock size={'1rem'} /> PRO
                        </Badge>
                      </div>
                    ))}
                  </div>
                ) : (
                  randomLandersTemplates?.LandingPages.map((item) => (
                    <SearchDialogEmailLanderTemplateImage
                      key={item['LP ID']}
                      item={{
                        Screenshot: item['Landing Page Screenshot'],
                        ID: item['LP ID'].toString(),
                        created: item.Created,
                        category: item.Category ?? '',
                        isSaved: item.isSaved,
                        imageUrl: item.imageUrl,
                        brandId: item.brandId,
                        brandName: item.brandName,
                        brandImage: item.brandImage,
                      }}
                      type="landing-page"
                    />
                  ))
                )}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </div>

        <div className="flex justify-end mr-3">
          <Link className="text-slate-500" to="/feeds/templates/landing-pages">
            <span className="flex items-center gap-2 text-sm">
              <span>See all</span> <ArrowRight size={'1rem'} />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchDialogTemplateSection;
