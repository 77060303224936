import { Lock } from '@mynaui/icons-react';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';
import FreeTrialEnding from './FreeTrialEnding';
import { PermissionData } from './SearchDialog';
import { Stack, Text } from './custom-components';
import { Badge } from './shadcn/badge';

const SearchDialogExpertImage = ({
  name,
  totalAdCount,
  totalEmailCount,
  atId,
  expertImage,
  isLocked = true,
  permissions,
}: {
  name: string;
  speciality: string[];
  totalAdCount: number;
  totalEmailCount: number;
  description: string;
  atId: string;
  expertImage?: string | null;
  isLocked?: boolean;
  permissions?: PermissionData;
}) => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  return (
    <Link
      to="/feeds/collections/experts/ad/$expert"
      params={{ expert: name }}
      onClick={(e) => {
        if (isLocked) {
          e.preventDefault();
          setUpgradeDialogOpen(true);
        }
      }}
      className={`rounded-2xl cursor-pointer`}
      key={atId}
    >
      <div className="flex gap-3 items-center justify-between w-full ">
        {upgradeDialogOpen && permissions && (
          <FreeTrialEnding
            open={upgradeDialogOpen}
            onOpenChange={() => setUpgradeDialogOpen(false)}
            permissionData={permissions}
          />
        )}
        <div className="flex gap-2 items-center">
          <img
            src={
              expertImage ??
              'https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs'
            }
            alt={`${name ?? 'CreativeOs'} Image`}
            className={`min-w-[4rem] w-[4rem] h-[4rem] rounded-full object-cover cursor-pointer ${isLocked ? 'filter blur-sm pointer-events-none relative' : ''}`}
          />
          <div className="flex gap-1 items-center flex-wrap">
            <Text
              size={'xs'}
              weight={'medium'}
              className="text-themeforeground w-fit !mr-3"
            >
              {name}
            </Text>
          </div>
        </div>

        {isLocked ? (
          <Badge
            variant={'outline'}
            className="border-themedestructive text-themedestructive flex gap-1 mr-5"
          >
            <Lock className="w-3 h-3" /> Pro
          </Badge>
        ) : (
          <div className="flex items-center gap-1 mr-5">
            <Stack className="items-end">
              {totalAdCount == 0 ? null : (
                <Text
                  size={'xs'}
                  weight={'thin'}
                  className="text-gray-400 w-fit"
                >
                  {totalAdCount}
                </Text>
              )}
              {totalEmailCount == 0 ? null : (
                <Text
                  size={'xs'}
                  weight={'thin'}
                  className="text-gray-400 w-fit"
                >
                  {totalEmailCount}
                </Text>
              )}
            </Stack>
            <Stack>
              {totalAdCount == 0 ? null : (
                <Text
                  size={'xs'}
                  weight={'thin'}
                  className="text-gray-400 text-start w-9"
                >
                  Ads
                </Text>
              )}
              {totalEmailCount == 0 ? null : (
                <Text
                  size={'xs'}
                  weight={'thin'}
                  className="text-gray-400 w-9 text-start"
                >
                  Emails
                </Text>
              )}
            </Stack>
          </div>
        )}
      </div>
    </Link>
  );
};

export default SearchDialogExpertImage;
