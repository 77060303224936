import FreeTrialEnding from '@/components/FreeTrialEnding';
import { Stack, Text } from '@/components/custom-components';
import { CustomDiamondIcon } from '@/components/custom-components/DiamondIcon';
import { FeatureTabs } from '@/components/custom-components/FeatureTabs';
import { Loader } from '@/components/custom-components/Loader';
import { LockTagLarge } from '@/components/custom-components/LockTag';
import { CustomTestTubeIcon } from '@/components/custom-components/TestTubeIcon';
import { trpc } from '@/utils/trpc.ts';
import { Tree } from '@mynaui/icons-react';
import { Link, createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

type LiveTrendsDataProps = {
  liveTrends: TrendData[];
  userCanAccessEverything: boolean;
  showLockIcon: boolean;
  setShowLockIcon: () => void;
};

type TrendData = {
  trendTitle: string;
  trendTitleIcon: JSX.Element;
  trendImageSrc: string;
  trendUrl: string;
};

export const Route = createFileRoute('/feeds/live-trends/')({
  component: () => <All />,
});

function All() {
  const [showLockIcon, setShowLockIcon] = useState<boolean>(false);
  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  if (isLoadingPermission) {
    return (
      <div
        className={'flex justify-center items-center w-full h-screen m-auto'}
      >
        <Loader />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-6">
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="gap-3 lg:gap-8">
        
        
        <div className='flex gap-2 justify-between'>
        <Stack className="gap-3 lg:gap-2">
          <Text weight="semibold" size={'xxl'}>
            Creations. In the Moment.
          </Text>
          <Text
            weight="normal"
            size={'lg'}
            className="text-thememutedforeground hidden lg:flex"
          >
            One place, endless possibilities—discover hidden gems, new ads, and
            evergreens.
          </Text>
        </Stack>
        <FeatureTabs
          tabItems={[
            {
              name: 'Curated',
              link: !(permissionData?.userCanAccessEverything ?? false)
                ? '/feeds/collections/creativeos'
                : '/feeds/collections',
            },
            {
              name: 'Live Trends',
              link: '/feeds/live-trends',
            },
          ]}
        />
        </div>
      </Stack>
      <LiveTrendsGridView
        setShowLockIcon={() => {
          setShowLockIcon(true);
          setUpgradeDialogOpen(true);
        }}
        showLockIcon={showLockIcon}
        userCanAccessEverything={
          permissionData?.userCanAccessEverything ?? false
        }
        liveTrends={[
          {
            trendTitle: 'Hidden Gems',
            trendImageSrc: '/images/live_trends/hiddengems_banner.png',
            trendUrl: '/feeds/hidden-gems',
            trendTitleIcon: <CustomDiamondIcon />,
          },
          {
            trendTitle: 'Evergreen Ads',
            trendImageSrc: '/images/live_trends/evergreen_banner.png',
            trendUrl: '/feeds/evergreen',
            trendTitleIcon: <Tree className="w-6 h-6" />,
          },
          {
            trendTitle: 'Testing Lab',
            trendImageSrc: '/images/live_trends/testinglabs_banner.png',
            trendUrl: '/feeds/testing-labs',
            trendTitleIcon: <CustomTestTubeIcon />,
          },
        ]}
      />
    </Stack>
  );
}

const LiveTrendsGridView = (props: LiveTrendsDataProps) => {
  return (
    <div className={'grid lg:grid-cols-2 gap-6 mb-10'}>
      {props.liveTrends && props.liveTrends.length > 0
        ? props.liveTrends.map((item) => (
            <TrendItem
              showLockIcon={props.showLockIcon}
              setShowLockIcon={props.setShowLockIcon}
              userCanAccessEverything={props.userCanAccessEverything}
              key={item.trendTitle}
              data={item}
            />
          ))
        : props.liveTrends.length === 0 && (
            <div
              className={
                'h-full w-full lg:col-span-2 flex justify-center items-center'
              }
            >
              <p>No collections created</p>
            </div>
          )}
    </div>
  );
};

const TrendItem = ({
  data,
  userCanAccessEverything,
  setShowLockIcon,
  showLockIcon,
}: {
  data: TrendData;
  userCanAccessEverything: boolean;
  showLockIcon: boolean;
  setShowLockIcon: () => void;
}) => {
  return (
    <Link
      to={data.trendUrl}
      onClick={(e) => {
        if (!userCanAccessEverything) {
          setShowLockIcon();
          e.preventDefault();
        }
      }}
    >
      <div
        className={
          'rounded-lg max-h-96 bg-thememuted px-6 pt-6 overflow-hidden flex flex-col justify-between'
        }
      >
        <div className="flex gap-2 justify-between">
          <div
            className={`flex gap-2 items-center mb-3 ${showLockIcon ? 'opacity-50' : ''}`}
          >
            {data.trendTitleIcon}
            <Text
              size={'xxl'}
              weight={'medium'}
              className="text-themeforeground"
            >
              {data.trendTitle}
            </Text>
          </div>
          {showLockIcon && <LockTagLarge />}
        </div>
        <img src={data.trendImageSrc} alt="" className="object-cover w-full" />
      </div>
    </Link>
  );
};
