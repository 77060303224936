import FreeTrialEnding from "@/components/FreeTrialEnding";
import { Tabs, TabsList, TabsTrigger } from "@/components/shadcn/tabs";
import { trpc } from "@/utils/trpc";
import { Link } from "@tanstack/react-router";
import { useState } from "react";
import { z } from "zod";
import {
  orderFilter as OrderFilterTypes,
  landerFeedOrderFilter,
} from "../../../../../../src/shared/airtable";
import { LockTagSmall } from "../LockTag";

export const FeatureTabs = ({
  tabItems,
  urlSegmentCountToCompare,
  defaultOrderValue = "Random",
}: {
  tabItems: Array<{ name: string; link: string; isPremiumFeature?: boolean, searchParams?: Record<string, string | undefined> }>;
  urlSegmentCountToCompare?: number;
  defaultOrderValue?:
    | z.infer<typeof landerFeedOrderFilter>
    | z.infer<typeof OrderFilterTypes>;
}) => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  return (
    <>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Tabs
          className="w-fit"
          value={
            urlSegmentCountToCompare
              ? location.pathname
                  .split("/")
                  .filter(Boolean)
                  .slice(0, urlSegmentCountToCompare)
                  .join("/")
              : location.pathname
          }
        >
          <TabsList className="rounded-full">
            {tabItems.map((item) => (
              <TabsTrigger
                key={item.name}
                value={
                  urlSegmentCountToCompare
                    ? item.link
                        .split("/")
                        .filter(Boolean)
                        .slice(0, urlSegmentCountToCompare)
                        .join("/")
                    : item.link
                }
                className="rounded-full"
              >
                <Link
                  onClick={(e) => {
                    // If a user navigates before loading is complete, choose to just navigate
                    // This optimizes for users who ARE allowed access and doesn't force them to
                    // wait until we have the data back from the server if they have access

                    if (
                      item.isPremiumFeature &&
                      !isLoadingPermission &&
                      !permissionData?.userCanAccessEverything
                    ) {
                      setUpgradeDialogOpen(true);
                      e.preventDefault();
                    }
                  }}
                  to={item.link}
                  search={(d) => ({
                    ...d,
                    cacheBuster: Math.random(),
                    orderFilter: defaultOrderValue,
                    sideBarOpen: false,
                    ...item.searchParams,
                  })}
                  className="flex items-center gap-1.5"
                  activeOptions={{
                    exact: true,
                    includeSearch: false,
                  }}
                  key={item.name}
                >
                  {item.name}
                  {item.isPremiumFeature &&
                    !isLoadingPermission &&
                    !permissionData?.userCanAccessEverything && (
                      <div className="opacity-50">{<LockTagSmall />}</div>
                    )}
                </Link>
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
    </>
  );
};
