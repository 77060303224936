import { Lock } from '@mynaui/icons-react';
import { useState } from 'react';
import FreeTrialEnding from './FreeTrialEnding';
import { PermissionData } from './SearchDialog';
import TemplateDetails from './TemplateDetails';
import { Badge } from './shadcn/badge';
import { LandingAdDataProps } from './templates/LandingAdCard';

const SearchDialogEmailLanderTemplateImage = ({
  item,
  type,
  userIsTyping = false,
  collections = [],
  isLocked = true,
  permissions,
}: {
  item: LandingAdDataProps;
  type?: 'email' | 'landing-page';
  collections?: string[];
  userIsTyping?: boolean;
  isLocked?: boolean;
  permissions?: PermissionData;
}) => {
  const [detailsShow, setDetailsShow] = useState<boolean>(false);

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  return (
    <div
      className={`${userIsTyping ? '' : 'bg-slate-100'} rounded-2xl`}
      key={item.ID}
    >
      {detailsShow && item ? (
        <TemplateDetails
          landingData={item}
          type={type}
          open={detailsShow}
          onCloseDrawer={() => setDetailsShow(false)}
        />
      ) : null}
      {upgradeDialogOpen && permissions && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissions}
        />
      )}
      {userIsTyping ? (
        <div className="flex gap-3 items-center justify-between w-full ">
          <div className="flex gap-2 items-center">
            <img
              src={
                item.imageUrl ??
                'https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs'
              }
              onClick={
                isLocked
                  ? () => setUpgradeDialogOpen(true)
                  : () => setDetailsShow(true)
              }
              alt={`${item.brandName ?? 'CreativeOs'} Image`}
              loading="eager"
              className="min-w-[5rem] w-[5rem] h-[5rem] rounded-2xl object-cover cursor-pointer"
            />
            <div className="flex gap-1 items-center flex-wrap">
              {collections.map((collection) => (
                <Badge variant={'outline'} className="px-2.5 py-1 flex gap-1">
                  {collection}
                </Badge>
              ))}
            </div>
          </div>
          {isLocked ? (
            <Badge
              variant={'outline'}
              className="border-themedestructive text-themedestructive flex gap-1 mr-5"
            >
              <Lock className="w-3 h-3" /> Pro
            </Badge>
          ) : null}
        </div>
      ) : (
        <img
          src={
            item.imageUrl ??
            'https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs'
          }
          alt={`${item.brandName ?? 'CreativeOs'} Image`}
          onClick={isLocked ? undefined : () => setDetailsShow(true)}
          loading="eager"
          className="min-w-[11.4063rem] w-[11.4063rem] h-[11.4063rem]  rounded-2xl object-cover cursor-pointer hvr-grow"
        />
      )}
    </div>
  );
};

export default SearchDialogEmailLanderTemplateImage;
