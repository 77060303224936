import { PRO_PLAN } from '@/utils/data/plans';
import { trpc } from '@/utils/trpc';
import { ArrowRight, Search } from '@mynaui/icons-react';
import { Link } from '@tanstack/react-router';
import SearchDialogAdInspirationImage from './SearchDialogAdInspirationImage';
import SearchDialogAdTemplateImage from './SearchDialogAdTemplateImage';
import SearchDialogBrandImage from './SearchDialogBrandImage';
import SearchDialogEmailInspirationImage from './SearchDialogEmailInspirationImage';
import SearchDialogEmailLanderTemplateImage from './SearchDialogEmailTemplateImage';
import SearchDialogExpertImage from './SearchDialogExpertImage';
import SearchDialogLanderInspirationImage from './SearchDialogLanderInspirationImage';
import { Stack, Text } from './custom-components';
import { Loader } from './custom-components/Loader';
import { ScrollArea } from './shadcn/scroll-area';

const TypingSearchView = ({
  searchTerm,
  debouncedSearchTerm,
}: {
  searchTerm: string;
  debouncedSearchTerm: string;
}) => {
  const { data, isLoading } = trpc.globalSearch.useQuery(
    {
      query: searchTerm,
      limit: 3,
    },
    {
      enabled: searchTerm.length > 0,
    },
  );
  const [
    ad_templates,
    email_templates,
    lander_templates,
    brands,
    experts,
    email_inspirations,
    ad_inspirations,
    lander_inspirations,
  ] = data || [];

  const { data: templateCollections } = trpc.getAllCollections.useQuery(
    {},
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
  const { data: permissionData } = trpc.permissions.useQuery(null, {});

  return (
    <ScrollArea type="always" className="w-full max-h-[80vh] flex-1 pb-5">
      {debouncedSearchTerm !== searchTerm || isLoading ? (
        <Stack className="gap-3 w-full py-40 items-center">
          <Loader />
        </Stack>
      ) : (data ?? []).every(
          (category) => (category?.data ?? []).length === 0,
        ) ? (
        <Stack className="gap-3 w-full py-60 items-center">
          <Search className="h-16 w-16 text-gray-300" aria-hidden="true" />
          <Text
            weight={'normal'}
            className="text-gray-300 text-5xl text-center w-full"
          >
            No results found
          </Text>
        </Stack>
      ) : (
        <Stack className="gap-3 w-full">
          {(ad_templates?.data ?? []).length > 0 ? (
            <Text size={'sm'} weight={'medium'} className="text-gray-400">
              Ad Templates
            </Text>
          ) : null}
          {ad_templates?.data.map((item) => (
            <SearchDialogAdTemplateImage
              key={item['Creative ID']}
              userIsTyping
              collections={
                item.collections?.map((col) => ({
                  collectionId:
                    templateCollections?.find((tc) => tc.Title === col)?.atID ??
                    '',
                  collectionName:
                    templateCollections?.find((tc) => tc.Title === col)
                      ?.Title ?? '',
                })) ?? []
              }
              item={{
                ...item,
                form: '',
                'Example Image': [],
                'Template Image': [],
                Status: '',
                AdDbId: null,
                Expert: undefined,
                Tags: undefined,
                Liked: item.Liked ?? false,
                'Canva Template LInk': item['Canva Template LInk'] ?? '',
              }}
            />
          ))}
          {(ad_templates?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <Link
                className="text-slate-500"
                to="/feeds/search/templates/ads"
                search={{ searchTerm, cacheBuster: Date.now() }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={'1rem'} />
                </span>
              </Link>
            </div>
          ) : null}
          {(email_templates?.data ?? []).length > 0 ? (
            <Text size={'sm'} weight={'medium'} className="text-gray-400">
              Email Templates
            </Text>
          ) : null}
          {email_templates?.data.map((item) => (
            <SearchDialogEmailLanderTemplateImage
              key={item['Email ID']}
              item={{
                Screenshot: item.imageUrl,
                ID: item['Email ID'].toString(),
                created: item.Created,
                category: item.Category ?? '',
                isSaved: item.isSaved,
                imageUrl: item.imageUrl,
                brandId: item.brandId,
                brandName: item.brandName,
                brandImage: item.brandImage,
              }}
              userIsTyping
              collections={item.Category ? [item.Category] : []}
              type="email"
              isLocked={
                !(
                  permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                  permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
                )
              }
              permissions={permissionData}
            />
          ))}
          {(email_templates?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <Link
                className="text-slate-500"
                to="/feeds/search/templates/emails"
                onClick={(e) => {
                  if (
                    !(
                      permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                      permissionData?.usersPlans.includes(
                        PRO_PLAN.no_trial_plan_id,
                      )
                    )
                  )
                    e.preventDefault();
                }}
                search={{ searchTerm, cacheBuster: Date.now() }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={'1rem'} />
                </span>
              </Link>
            </div>
          ) : null}
          {(lander_templates?.data ?? []).length > 0 ? (
            <Text size={'sm'} weight={'medium'} className="text-gray-400">
              Lander Templates
            </Text>
          ) : null}
          {lander_templates?.data.map((item) => (
            <SearchDialogEmailLanderTemplateImage
              key={item['LP ID']}
              item={{
                Screenshot: item.imageUrl,
                ID: item['LP ID'].toString(),
                created: item.Created,
                category: item.Category ?? '',
                isSaved: item.isSaved,
                imageUrl: item.imageUrl,
                brandId: item.brandId,
                brandName: item.brandName,
                brandImage: item.brandImage,
              }}
              collections={item.Category ? [item.Category] : []}
              userIsTyping
              type="landing-page"
              isLocked={
                !(
                  permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                  permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
                )
              }
            />
          ))}
          {(lander_templates?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <Link
                className="text-slate-500"
                to="/feeds/search/templates/landing-pages"
                onClick={(e) => {
                  if (
                    !(
                      permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                      permissionData?.usersPlans.includes(
                        PRO_PLAN.no_trial_plan_id,
                      )
                    )
                  )
                    e.preventDefault();
                }}
                search={{ searchTerm }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={'1rem'} />
                </span>
              </Link>
            </div>
          ) : null}
          {(experts?.data ?? []).length > 0 ? (
            <Text size={'sm'} weight={'medium'} className="text-gray-400">
              Experts
            </Text>
          ) : null}
          {experts?.data.map((item) => (
            <SearchDialogExpertImage
              key={item.name}
              name={item.name}
              expertImage={item.image}
              speciality={item.speciality}
              totalAdCount={item.totalAdCount}
              totalEmailCount={item.totalEmailCount}
              description={item.description ?? ''}
              atId={item.atId}
              isLocked={
                !(
                  permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                  permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
                )
              }
              permissions={permissionData}
            />
          ))}
          {(experts?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <Link
                className="text-slate-500"
                to="/feeds/search/experts"
                onClick={(e) => {
                  if (
                    !(
                      permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                      permissionData?.usersPlans.includes(
                        PRO_PLAN.no_trial_plan_id,
                      )
                    )
                  )
                    e.preventDefault();
                }}
                search={{ searchTerm }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={'1rem'} />
                </span>
              </Link>
            </div>
          ) : null}
          {(ad_inspirations?.data ?? []).length > 0 ? (
            <Text size={'sm'} weight={'medium'} className="text-gray-400">
              Ad Inspiration
            </Text>
          ) : null}
          {ad_inspirations?.data.map((item) => (
            <SearchDialogAdInspirationImage
              key={item.id}
              userIsTyping
              collections={[]}
              item={{
                id: item.id,
                reach: null,
                earliestView: '',
                adRunningDays: 0,
                imageUrl: item.imageUrl ?? '',
                videoUrl: item.videoUrl ?? '',
                brandId: item.brandId ?? null,
                brandName: item.brandName ?? null,
                brandImage: item.brandImage ?? null,
                isSaved: item.isSaved ?? false,
              }}
            />
          ))}
          {(ad_inspirations?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <Link
                className="text-slate-500"
                to="/feeds/search/inspiration/ads"
                search={{ searchTerm }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={'1rem'} />
                </span>
              </Link>
            </div>
          ) : null}
          {(email_inspirations?.data ?? []).length > 0 ? (
            <Text size={'sm'} weight={'medium'} className="text-gray-400">
              Email Inspiration
            </Text>
          ) : null}
          {email_inspirations?.data.map((item) => (
            <SearchDialogEmailInspirationImage
              userIsTyping
              collections={[]}
              item={{
                ...item,
                requestCount: 0,
                template: null,
                to: '',
                imageUrl: item.imageUrl ?? '',
                subject: item.subject ?? '',
                homepage: item.homepage ?? '',
                brandId: item.brandId ?? null,
                brandName: item.brandName ?? null,
                brandImage: item.brandImage ?? null,
              }}
            />
          ))}
          {(email_inspirations?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <Link
                className="text-slate-500"
                to="/feeds/search/inspiration/emails"
                search={{ searchTerm }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={'1rem'} />
                </span>
              </Link>
            </div>
          ) : null}
          {(lander_inspirations?.data ?? []).length > 0 ? (
            <Text size={'sm'} weight={'medium'} className="text-gray-400">
              Lander Inspiration
            </Text>
          ) : null}
          {lander_inspirations?.data.map((item) => (
            <SearchDialogLanderInspirationImage
              userIsTyping
              collections={[]}
              item={{
                ...item,
                requestCount: 0,
                template: null,
                landerUrl: item.landerUrl ?? '',
                desktopScreenshot: item.desktopScreenshot ?? '',
                mobileScreenshot: item.mobileScreenshot ?? '',
                brandId: item.brandId ?? null,
                brandName: item.brandName ?? null,
                brandImage: item.brandImage ?? null,
              }}
            />
          ))}
          {(lander_inspirations?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <Link
                className="text-slate-500"
                to="/feeds/search/inspiration/landing-pages"
                search={{ searchTerm }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={'1rem'} />
                </span>
              </Link>
            </div>
          ) : null}

          {(brands?.data ?? []).length > 0 ? (
            <Text size={'sm'} weight={'medium'} className="text-gray-400">
              Brands
            </Text>
          ) : null}
          {brands?.data.map((item) => (
            <SearchDialogBrandImage
              key={item.brandId}
              brandName={item.brandName}
              totalAdCount={item.totalAdCount}
              totalEmailCount={item.totalEmailCount}
              totalLanderCount={item.totalLanderCount}
              brandImage={item.brandImage}
              brandId={item.brandId}
            />
          ))}
          {(brands?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <Link
                className="text-slate-500"
                to="/feeds/search/brands"
                search={{ searchTerm }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={'1rem'} />
                </span>
              </Link>
            </div>
          ) : null}
        </Stack>
      )}
    </ScrollArea>
  );
};

export default TypingSearchView;
