export const featureFlagKeys = {
  emailFeeds: 'email-feeds',
  brandEmails: 'brand-emails',
  intercom: 'intercom',
  captcha: 'turnstile',
  annualPlans: 'annual-plans',
  search: 'search',
  userback: 'user-feedback',
  darkMode: 'dark-mode',
};
