import {
  PERSUASION_ELEMENTS,
  PersuasionElement,
  PersuasionLevel,
  persuasionData,
  reabilityscores,
} from "@/components/CopyGrader.tsx";
import { syllable } from "syllable";

export function useCopyGrader({ copyText }: { copyText: string }) {
  const getReadingLevel = (score: number): string => {
    if (score >= 90) return "5th Grade";
    if (score >= 80) return "6th Grade";
    if (score >= 70) return "7th Grade";
    if (score >= 60) return "8th-9th Grade";
    if (score >= 50) return "10th-12th Grade";
    return "College";
  };

  // Function to determine persuasion level based on score
  function getPersuasionLevel(
    score: number,
  ): PersuasionLevel | { level: string; description: string } {
    if (score >= 90) {
      return persuasionData[0];
    } else if (score >= 80 && score <= 89) {
      return persuasionData[1];
    } else if (score >= 70 && score <= 79) {
      return persuasionData[2];
    } else if (score >= 60 && score <= 69) {
      return persuasionData[3];
    } else if (score >= 40 && score <= 59) {
      return persuasionData[4];
    } else if (score >= 0 && score <= 39) {
      return persuasionData[5];
    } else {
      return {
        level: "Invalid Score",
        description: "Score must be between 0 and 100.",
      };
    }
  }

  const getContextualTips = (
    contentType: string,
    contentSubtype: string,
  ): string[] => {
    const tips: Record<string, Record<string, string[]>> = {
      email: {
        promotional: [
          "Include clear CTA button",
          "Use compelling subject line",
          "Add social proof",
          "Optimize for mobile",
        ],
      },
      landing: {
        product: [
          "Clear value proposition",
          "Product benefits above fold",
          "Social proof section",
          "Multiple CTAs",
        ],
      },
      ad: {
        display: [
          "Clear value proposition",
          "Strong visual hierarchy",
          "Single focused CTA",
          "Brand consistency",
        ],
      },
    };
    return tips[contentType]?.[contentSubtype] || [];
  };

  const formatElementName = (element: string) => {
    return element.replace(/([A-Z])/g, " $1").trim();
  };

  const analyzePersuasion = ({
    text,
    contentType,
    contentSubtype,
  }: {
    text: string;
    contentType: string;
    contentSubtype: string;
  }) => {
    let totalScore = 0;
    const elements: PersuasionElement[] = [];
    const strengths: string[] = [];
    const suggestions: string[] = [];

    Object.entries(PERSUASION_ELEMENTS).forEach(([name, config]) => {
      let score = 0;

      const foundKeywords = config.keywords.filter(
        (keyword) => text.toLowerCase().includes(keyword.toLowerCase()), // included Case-insensitive search
      );

      if (foundKeywords.length) {
        score = config.weight * foundKeywords.length * config.multiplier;
        score = Math.min(score, config.weight * 2);
      }

      elements.push({
        name: formatElementName(name),
        score: Math.round(score),
        keywords: foundKeywords,
      });

      if (score > config.weight) {
        strengths.push(formatElementName(name));
      } else if (score === 0) {
        suggestions.push(`Add ${formatToReadableText(name)} elements`);
      }

      totalScore += score;
    });

    const maxScore = Object.values(PERSUASION_ELEMENTS).reduce(
      (sum, config) => sum + config.weight * 2,
      0,
    );

    return {
      score: Math.round((totalScore / maxScore) * 100),
      elements,
      strengths,
      suggestions,
      contextualTips: getContextualTips(contentType, contentSubtype),
    };
  };

  const formatToReadableText = (text: string): string => {
    return text.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
  };

  const formatNamesOfElements = (names: string[]): string => {
    const capitalize = (str: string) =>
      str.replace(/\b\w/g, (char) => char.toUpperCase());

    const capitalizedNames = names.map((name) => capitalize(name));
    if (capitalizedNames.length === 0) return "";
    if (capitalizedNames.length === 1) return capitalizedNames[0];
    const lastElement = capitalizedNames.pop();
    return `${capitalizedNames.join(", ")} and ${lastElement}`;
  };

  function getTotalWords(sentence: string): number {
    if (!sentence.trim()) return 0; // Handle empty or whitespace-only strings
    return sentence.trim().split(/\s+/).length;
  }

  function getTotalSentences(text: string): number {
    // Split text into sentences using regex and filter out empty entries
    const sentences = text
      .split(/[.!?]+/)
      .filter((sentence) => sentence.trim() !== "");
    return sentences.length;
  }

  function getTotalCharacters(text: string): number {
    return text.length;
  }

  function getSummary(score: number): string {
    if (score > 100) {
      // Use the 90-100 summary for scores above 100
      const aboveMax = reabilityscores.find((range) => range.max === 100);
      return aboveMax ? aboveMax.summary : "Very easy to read";
    }

    if (score <= 0) {
      // Use the 0 summary for scores 0 or below
      const zeroOrLess = reabilityscores.find((range) => range.max === 0);
      return zeroOrLess ? zeroOrLess.summary : "No Score";
    }

    const match = reabilityscores.find(
      (range) => score >= range.min && score <= range.max,
    );
    return match ? match.summary : "Score out of range";
  }

  const totalWords = getTotalWords(copyText);
  const totalSentences = getTotalSentences(copyText);
  const totalCharacters = getTotalCharacters(copyText);
  const totalSyllables = syllable(copyText);

  const fleschScore =
    206.835 -
    1.015 * (totalWords / totalSentences) -
    84.6 * (totalSyllables / totalWords);

  const persuasionAnalysisResults = analyzePersuasion({
    text: copyText,
    contentSubtype: "display",
    contentType: "ad",
  });

  const readabilityScore = parseFloat(fleschScore.toFixed(1));

  return {
    readabilityScore:
      readabilityScore > 100
        ? 100
        : isNaN(readabilityScore) || readabilityScore <= 0
          ? 0
          : readabilityScore, // capping at 100
    persuasionAnalysisResults,
    totalCharacters,
    totalSentences,
    totalSyllables,
    totalWords,
    analyzePersuasion,
    getReadingLevel,
    getPersuasionLevel,
    formatElementName,
    formatNamesOfElements,
    getSummary,
    formatToReadableText,
  };
}
