import { cn } from "@/lib/utils.ts";
import { TrendingDown, TrendingUp } from "@mynaui/icons-react";
import { VariantProps, cva } from "class-variance-authority";
import { ComponentProps, forwardRef } from "react";

const adPublishStatusStyles = cva(
  "flex items-center gap-[0.313rem] mt-[0.063rem]",
);

type AdPublishStatusProps = ComponentProps<"div"> &
  VariantProps<typeof adPublishStatusStyles> & {
    date: string;
    status: string;
    showDate?: boolean;
    adRunningDays?: number;
  };

export const AdPublishStatus = forwardRef<HTMLDivElement, AdPublishStatusProps>(
  (
    { className, date, showDate = false, adRunningDays, status, ...props },
    ref,
  ) => {
    const isPublishedToday =
      date && new Date(date).toDateString() === new Date().toDateString();
    const publishedDaysAgo = adRunningDays;
    const adStatus = status && status.toLowerCase();

    const publishedText = isPublishedToday
      ? "PUBLISHED TODAY"
      : `${publishedDaysAgo} Days ${adStatus.toLowerCase() === "active" ? "Active" : "Inactive"}`;

    return (
      <div
        ref={ref}
        className={cn(adPublishStatusStyles({ className }))}
        {...props}
      >
        {adStatus.toLowerCase() === "active" ? (
          <TrendingUp className={"text-green-500 w-4.5 h-4.5"} />
        ) : (
          <TrendingDown className={"text-amber-500 w-4.5 h-4.5"} />
        )}
        <span className="text-xs text-themeinput">
          {showDate
            ? `PUBLISHED ON ${new Date(date).toLocaleDateString()}`
            : publishedText}
        </span>
      </div>
    );
  },
);
