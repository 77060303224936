import { cn } from "@/lib/utils.ts";
import { VariantProps, cva } from "class-variance-authority";
import { ComponentProps, forwardRef } from "react";

const inputStyles = cva([
  "w-full",
  "bg-[#ECECEC]",
  "flex",
  "border-[0.063rem]",
  "border-black",
  "rounded-[0.5rem]",
  "px-[1.25rem]",
  "py-[1rem]",
  "text-[0.813rem]",
  "duration-100",
  "transition-all",
  "outline-none",
  "placeholder:text-[1rem] placeholder:text-[#BCBCBC] placeholder:leading-[1.173rem]",
]);

type InputProps = ComponentProps<"input"> &
  VariantProps<typeof inputStyles> & {
    label?: string;
  };

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, label, ...props }, ref) => {
    return (
      <>
        {label && <label>{label}</label>}

        <input
          ref={ref}
          type={"text"}
          autoComplete={"off"}
          className={cn(inputStyles({ className }))}
          {...props}
        />
      </>
    );
  },
);
