import { featureFlagKeys } from '@/utils/data/featureFlags';
import { trpc } from '@/utils/trpc';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import FeedbackToggle from './FeedbackToggle';
import { Text } from './custom-components';
import { Checkbox } from './shadcn/checkbox';

export type WorkOSUser = {
  object: string;
  id: string;
  email: string;
  emailVerified: boolean;
  firstName: string | null;
  profilePictureUrl: string | null;
  lastName: string | null;
  createdAt: string;
  updatedAt: string;
  avatar?: string | null;
  subscribed?: boolean;
};

const UserProfileSettingDetail = ({
  userData,
  setEdit,
}: {
  userData: WorkOSUser;
  setEdit: (status: boolean) => void;
}) => {
  const userbackFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.userback);

  const [newsLetterSubscribed, setNewsLetterSubscribed] =
    useState<boolean>(false);

  const {
    mutateAsync: changeNewsLetterSubscription,
    isPending: isChangingSub,
  } = trpc.changeNewsLetterSubscription.useMutation();

  useEffect(() => {
    if (userData) {
      setNewsLetterSubscribed(userData.subscribed ?? false);
    }
  }, [userData]);

  return (
    <div className="gap-3 flex flex-col">
      <div className={'flex justify-start items-start gap-1.5'}>
        <img
          alt={userData?.email}
          src={userData?.avatar || '/bg_gradient.jpg'}
          className={
            'rounded-full cursor-pointer shrink-0 bg-[#A259FF] w-9 h-9'
          }
        />
        <span className="font-semibold text-2xl leading-8 ">
          {`${
            userData?.firstName || userData?.lastName
              ? `${userData.firstName ?? ''} ${userData.lastName ?? ''}`
              : ''
          }`.trim()}
        </span>
      </div>
      <div className={'flex justify-between items-center gap-1.5 h-9'}>
        <span className="font-semibold text-base leading-6 ">
          Account
        </span>
        <span
          onClick={() => setEdit(true)}
          className="font-medium text-sm leading-5 underline underline-offset-1 cursor-pointer tracking-wide	"
        >
          Edit
        </span>
      </div>
      <div className={'flex justify-start items-start gap-1.5'}>
        <span className="font-normal text-base leading-6 text-thememutedforeground w-20 min-w-20">
          Name
        </span>
        <span className="font-medium text-base leading-5 ">
          {`${
            userData?.firstName || userData?.lastName
              ? `${userData.firstName ?? ''} ${userData.lastName ?? ''}`
              : ''
          }`.trim()}
        </span>
      </div>
      <div className={'flex justify-start items-start gap-1.5'}>
        <span className="font-normal text-base leading-6 text-thememutedforeground w-20 min-w-20">
          Email
        </span>
        <span className="font-medium text-base leading-5 ">
          {`${userData?.email ?? ''}`.trim()}
        </span>
      </div>
      <div className={'flex justify-between items-center gap-1.5 h-9'}>
        <span className="font-semibold text-base leading-6 ">
          Communication
        </span>
      </div>
      {userData && (
        <div className="flex gap-2 items-center">
          <Checkbox
            checked={newsLetterSubscribed}
            onCheckedChange={(checked) => {
              setNewsLetterSubscribed(!!checked);
              changeNewsLetterSubscription({
                subscribed: !!checked,
              });
            }}
            disabled={isChangingSub}
          />
          <Text weight={'medium'} size={'sm'}>
            Receive useful tips, newsletters, and promotions via e-mail
          </Text>
        </div>
      )}
      {userbackFlagEnabled ? (
        <div>
          <FeedbackToggle />
        </div>
      ) : null}
    </div>
  );
};

export default UserProfileSettingDetail;
