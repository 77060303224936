import { Button } from "@/components/shadcn/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/shadcn/dialog.tsx";
import { useNavigate } from "@tanstack/react-router";

export default function FirstTimeWelcomeDialog({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) {
  const navigate = useNavigate();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px] py-6">
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold">
            Let's get started
          </DialogTitle>
        </DialogHeader>
        <div>
          <p className={"text-sm"}>
            You’re all set! Get inspired by thousands of creatives to kickstart
            your next campaign, and try out our wide variety of ad templates,
            landing page designs and email flows. You can find any creative, for
            any test, at anytime.
          </p>
        </div>
        <DialogFooter>
          <Button
            onClick={() => {
              navigate({
                to: "/feeds/templates",
                search: {
                  orderFilter: "Recent",
                  cacheBuster: Math.random(),
                  sidebarCollapsed: false,
                },
              });
              onOpenChange();
            }}
          >
            Start Creating
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
