import { defaultUseQueryRefetchOptions } from "@/_shared/constants";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { AdFilter, Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { InspirationRequestNotifier } from "@/components/custom-components/InspirationRequestNotifier";
import { Loader } from "@/components/custom-components/Loader";
import NotFoundBox from "@/components/custom-components/NotFound";
import { ErrorDisplay } from "@/components/error.tsx";
import { EmailFeedAdCard } from "@/components/templates/LandingAdCard";
import {
  AdFeedFilterOption,
  AdLanderSelectedFilters,
  LandingAdSearchParams,
} from "@/hooks/useFilterFeed.tsx";
import { trpc } from "@/utils/trpc.ts";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { landerFeedOrderFilter } from "../../../../src/shared/airtable";

export const Route = createFileRoute("/feeds/search/inspiration/emails")({
  component: All,
  validateSearch: (search: Record<string, unknown>): LandingAdSearchParams => {
    const industry =
      typeof search?.industry === "string" ? search.industry : undefined;
    const brands =
      typeof search?.brands === "string" ? search.brands : undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;
    const searchTerm: string | undefined =
      typeof search.searchTerm === "string" ? search.searchTerm : undefined;

    const orderFilter = search?.orderFilter as
      | z.infer<typeof landerFeedOrderFilter>
      | undefined;

    return {
      industry,
      brands,
      sideBarOpen,
      orderFilter,
      searchTerm,
    };
  },
});

function All() {
  const navigate = useNavigate();

  const {
    industry: queryIndustry,
    brands: queryBrands,
    searchTerm: querySearchTerm,
    orderFilter,
  } = Route.useSearch();

  const [filterOptions, setFilterOptions] = useState<AdFeedFilterOption[]>([]);

  const [selectedFilters, setSelectedFilters] =
    useState<AdLanderSelectedFilters>({
      categories: queryIndustry ? queryIndustry.split(",") : undefined,
      brands: queryBrands ? (queryBrands.split(",") as string[]) : undefined,
    });

  // Get the page categories to be passed to the filter
  const { data: pageIndustry } = trpc.getPageCategories.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  const { data: brands } = trpc.getBrands.useQuery(
    undefined,
    defaultUseQueryRefetchOptions
  );

  useEffect(() => {
    if (pageIndustry && brands && filterOptions.length === 0) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Prevent redundant updates
        return [
          {
            title: "Industry",
            counter: 0,
            optionItems: pageIndustry.map((i) => ({
              label: i.name,
              value: false,
            })),
          },
          {
            title: "Brand",
            counter: 0,
            optionItems: (brands ?? []).map((i) => ({
              label: i.brandName,
              logo: i.brandImage,
              value: false,
              id: i.brandId,
            })),
          },
        ];
      });
    }
  }, [pageIndustry, brands, filterOptions.length]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => ({
      ...option,
      optionItems: option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === "Industry" &&
            selectedFilters.categories?.includes(item.label)) ||
          (option.title === "Brand" &&
            selectedFilters.brands?.includes(item.id ?? "")),
      })),
    }));

    setFilterOptions((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(updatedOptions)) return prev; // Prevent unnecessary updates
      return updatedOptions;
    });
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: { industry?: string; brands?: string; searchTerm?: string }) => {
      const searchParams = new URLSearchParams();

      if (params.industry) searchParams.set("industry", params.industry);
      if (params.brands) searchParams.set("industry", params.brands);
      if (params.searchTerm) searchParams.set("searchTerm", params.searchTerm);

      navigate({
        to: "/feeds/search/inspiration/emails",
        replace: true,
        search: (old) => {
          return { ...old, ...params, orderFilter };
        },
      });
    },
    [navigate]
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedIndustry: string[] = [];
      const selectedBrands: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Industry") selectedIndustry.push(item.label);
            if (group.title === "Brand") selectedBrands.push(`${item.id}`);
          }
        });
      });

      setSelectedFilters({
        categories: selectedIndustry.length > 0 ? selectedIndustry : undefined,
        brands: selectedBrands.length > 0 ? selectedBrands : undefined,
      });

      updateQueryString({
        industry:
          selectedIndustry.length > 0 ? selectedIndustry.join(",") : undefined,
        brands:
          selectedBrands.length > 0 ? selectedBrands.join(",") : undefined,
      });
    },
    [updateQueryString]
  );

  useEffect(() => {
    if (!selectedFilters) return;

    const params: Record<string, string | undefined> = {};

    if (selectedFilters.categories)
      params.categories = selectedFilters.categories.join(",");
  }, [selectedFilters]);

  const {
    data: emails,
    isLoading,
    isError,
    isRefetching,
  } = trpc.searchEmailInspiration.useQuery(
    {
      limit: 20,
      query: querySearchTerm ?? "",
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="gap-3 lg:gap-8">
        <FeatureTabs
          defaultOrderValue={"Random"}
          tabItems={[
            {
              name: "Ads",
              link: "/feeds/search/inspiration/ads",
              searchParams: { searchTerm: querySearchTerm },
            },
            {
              name: "Emails",
              link: "/feeds/search/inspiration/emails",
              searchParams: { searchTerm: querySearchTerm },
            },
            {
              name: "Landers",
              link: "/feeds/search/inspiration/landing-pages",
              searchParams: { searchTerm: querySearchTerm },
            },
          ]}
        />
        <Stack className="gap-3 lg:gap-6">
          <div
            className={
              "flex justify-between lg:justify-start gap-5 items-center"
            }
          >
            <Text weight="semibold" size={"xxl"} className="w-fit">
              Your Discovery Results {emails ? `(${emails?.totalHits})`:""}:
              <span className="opacity-45">“{querySearchTerm}”</span>
            </Text>
            <div className={"hidden gap-2"}>
              <div className="lg:hidden">
                <OrderFilterSelect
                  defaultFilter={orderFilter || "Random"}
                  options={["Random", "Newest", "Most Requested"]}
                />
              </div>
            </div>
          </div>
        </Stack>
      </Stack>
      <Stack className="w-full gap-3">
        <Text weight="medium" size={"sm"} className="w-fit hidden">
          Narrow your search using filters:
        </Text>

        <div
          className={`lg:sticky lg:top-0 lg:z-10 lg:bg-themebackground dark:lg:bg-themebackgrounddark lg:py-2 hidden ${pageIndustry && filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-center gap-1.5`}
        >
          {pageIndustry && filterOptions && filterOptions.length > 0 && (
            <AdFilter
              initialOptions={filterOptions}
              onOptionsChange={handleOptionsChange}
            />
          )}

          <div className={"hidden lg:flex"}>
            <OrderFilterSelect
              defaultFilter={orderFilter || "Random"}
              options={["Random", "Newest", "Most Requested"]}
            />
          </div>
        </div>
      </Stack>

      <InspirationRequestNotifier />
      {isLoading || isRefetching ? (
        <div className="flex justify-center items-center w-full h-screen">
          <Loader />
        </div>
      ) : (
        <div>
          {emails && emails.data.length === 0 ? (
            <NotFoundBox />
          ) : (
            <div
              className={
                "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
              }
            >
              {emails &&
                emails.data.length > 0 &&
                emails.data.map((card) => (
                  <EmailFeedAdCard
                    key={card.id}
                    adData={{
                      ...card,
                      requestCount: 0,
                      template: null,
                      to: "",
                      imageUrl: card.imageUrl ?? "",
                      subject: card.subject ?? "",
                      homepage: card.homepage ?? "",
                      brandId: card.brandId ?? null,
                      brandName: card.brandName ?? null,
                      brandImage: card.brandImage ?? null,
                    }}
                  />
                ))}
            </div>
          )}
        </div>
      )}
    </Stack>
  );
}

export default All;
