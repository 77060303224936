import { Button } from '@/components/shadcn/button.tsx';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip.tsx';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import showToastNotification from '@/hooks/useShowToast';
import { Download } from '@mynaui/icons-react';
import { Link as LinkIcon } from '@mynaui/icons-react';
import { Link } from '@tanstack/react-router';
import { EmailFeedContent } from '../../../../src/server/services/emails/definitions';
import { ManuallySaveFeedEmailToBoard } from './templates/LandingAdCard';

export const EmailInspirationDetailsMedia = ({
  data,
  saveOpen,
  setSaveOpen,
}: {
  data: EmailFeedContent;
  saveOpen: boolean;
  setSaveOpen: (x: boolean) => void;
}) => {
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <div className="p-3 lg:p-4 w-full flex flex-col items-center">
      <div className={'flex gap-5 justify-between w-full'}>
        <div className={`flex gap-1 items-center`}>
          {data.brandImage && data.brandId && (
            <Link
              to={`/feeds/brands/$brandID`}
              params={{ brandID: data.brandId }}
            >
              <img
                src={data.brandImage}
                alt={data.brandName || 'Brand name'}
                className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain min-w-8`}
              ></img>
            </Link>
          )}
          <div>
            {data.brandName && data.brandId && (
              <Link
                to={`/feeds/brands/$brandID`}
                params={{ brandID: data.brandId }}
              >
                <h4 className={`truncate font-[500] text-sm`}>
                  {data.brandName}
                </h4>
              </Link>
            )}
          </div>
        </div>

        <div className={'flex gap-2 items-center flex-wrap justify-end'}>
          {data && (
            <ManuallySaveFeedEmailToBoard
              open={saveOpen}
              onOpenChange={setSaveOpen}
              data={data}
              variant={'outline'}
            />
          )}

          {data.imageUrl && (
            <Tooltip>
              <TooltipTrigger asChild>
                <a href={data.imageUrl} download={'image.png'}>
                  <Button variant={'outline'} size={'icon'}>
                    <Download className="w-5 h-5" />
                  </Button>
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <p>Download as image</p>
              </TooltipContent>
            </Tooltip>
          )}

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => {
                  copyToClipboard(
                    `${window.location.origin}/share/inspiration/${data.id}`,
                  );
                  showToastNotification('success', {
                    message: 'Copied!',
                  });
                }}
                variant={'outline'}
                size={'icon'}
              >
                <LinkIcon />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Share a public link</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
      <div
        className={
          'lg:max-w-[23.25rem] w-full border border-themeborder dark:border-darkthemeinput rounded my-4'
        }
      >
        <div>
          <div className={'p-3'}>
            <div className={'flex gap-5 justify-between'}>
              <div>
                {data.brandName && data.brandId && (
                  <Link
                    to={`/feeds/brands/$brandID`}
                    params={{ brandID: data.brandId }}
                  >
                    <h4 className={`truncate font-[500] text-sm`}>
                      {data.brandName}
                    </h4>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          {data.imageUrl && (
            <img
              className="w-full"
              alt="ad display"
              loading="lazy"
              src={data.imageUrl}
            />
          )}
        </div>
      </div>
    </div>
  );
};
