import { Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs";
import { Loader } from "@/components/custom-components/Loader";
import { ErrorDisplay } from "@/components/error.tsx";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn/select";
import {
  EmailFeedAdCard,
  LandingAdCard,
} from "@/components/templates/LandingAdCard";
import { trpc } from "@/utils/trpc.ts";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { EmailFeedContent } from "../../../../src/server/services/emails/definitions";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/saved/emails")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [selectValue, setSelectValue] = useState<
    "All Saved" | "Only Templates"
  >("All Saved");
  const [allData, setAllData] = useState<EmailFeedContent[] | undefined>(
    undefined
  );
  const cursor = 0;
  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  const {
    data: savedEmailTemplates,
    isLoading: loadingEmailTemplates,
    isError: emailError,
    isRefetching: fetchingEmailTemplates,
  } = trpc.getAllSavedEmailTemplates.useQuery(undefined, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const {
    data: savedEmailAds,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = trpc.getAllSavedEmails.useInfiniteQuery(
    {
      limit: 20, // Example input
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      initialCursor: cursor,
    }
  );

  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      savedEmailAds &&
      allData &&
      allData.length &&
      savedEmailAds.pages[savedEmailAds.pages.length - 1].emails.length >= 20
    ) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, savedEmailAds]);

  useEffect(() => {
    if (!savedEmailAds) return;
    setAllData(() => undefined);
    const records = [] as EmailFeedContent[];
    for (const page of savedEmailAds.pages) {
      records.push(...page.emails);
    }
    setAllData(() =>
      records.filter(
        (item, index) =>
          index === records.findIndex((obj) => obj.id === item.id)
      )
    );
  }, [savedEmailAds]);

  if (emailError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (loadingEmailTemplates || fetchingEmailTemplates) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-8">
      <Stack className="gap-3 lg:gap-8">
        <div className="flex justify-between gap-2">
          <Text weight="semibold" size={"xxl"}>
            All Saved Ad Inspiration
          </Text>

          <div className="flex items-center gap-2 justify-end flex-wrap">
            <FeatureTabs
              tabItems={[
                {
                  name: "Ads",
                  link: "/feeds/saved/ads",
                },
                {
                  name: "Landers",
                  link: "/feeds/saved/ad-landers",
                },
                {
                  name: "Emails",
                  link: "/feeds/saved/emails",
                },
              ]}
            />
            <Select
              value={selectValue}
              onValueChange={(value: "All Saved" | "Only Templates") =>
                setSelectValue(value)
              }
            >
              <SelectTrigger className="w-33 h-8 max-h-8">
                <SelectValue placeholder="Ad Type" className="rounded-md" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {["All Saved", "Only Templates"].map((item) => (
                    <SelectItem value={item} className="py-1.5">
                      {item}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            {/* )} */}
          </div>
        </div>
      </Stack>

      <div className={""}>
        {(
          selectValue == "All Saved"
            ? [...(savedEmailTemplates ?? []), ...(allData ?? [])].length == 0
            : (savedEmailTemplates ?? []).length == 0
        ) ? (
          <div className={"h-[80vh] flex justify-center items-center"}>
            <p className={"text-center"}>
              You do not have any ad email inspiration saved
            </p>
          </div>
        ) : (
          <div
            className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2.5"}
          >
            {savedEmailTemplates &&
              savedEmailTemplates.map((card) => (
                <LandingAdCard
                  key={card["Email ID"]}
                  adData={{
                    Screenshot: card["Email Screenshot"],
                    ID: `${card["Email ID"]}`,
                    created: card.Created,
                    category: card.Category,
                    isSaved: card.isSaved,
                    imageUrl: card.imageUrl,
                  }}
                  showTemplateButton
                  type={"email"}
                />
              ))}
            {allData &&
              selectValue == "All Saved" &&
              allData.map((card) => (
                <EmailFeedAdCard key={card.id} adData={card} />
              ))}
          </div>
        )}
      </div>
      <div ref={scrollRef} className="flex justify-center w-full min-h-14 h-14">
        {isFetchingNextPage && <Loader />}
      </div>
    </Stack>
  );
}
