import { cn } from "@/lib/utils.ts";
import { VariantProps, cva } from "class-variance-authority";
import { forwardRef } from "react";

const templatesHeaderStyles = cva("w-full");

export interface TemplatesFooterProps
  extends React.ComponentPropsWithoutRef<"div">,
    VariantProps<typeof templatesHeaderStyles> {
  footerTitle: string;
  titleColor: string;
  footerType?: string;
  brandImage?: string | null;
  creativeOsTextColor?: string;
  rhsComponents?: JSX.Element;
}

export const TemplatesFooter = forwardRef<HTMLDivElement, TemplatesFooterProps>(
  (
    {
      className,
      footerTitle,
      titleColor,
      brandImage,
      creativeOsTextColor,
      footerType,
      ...props
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={cn(templatesHeaderStyles({ className }))}
        {...props}
      >
        <div className="flex justify-between">
          <div className={`flex gap-[0.625rem] items-center w-auto`}>
            <>
              <img
                alt={"CreativeOS"}
                src={brandImage ? brandImage : "/images/icon_dark.png"}
                className={
                  "dark:block hidden bg-[#BCBCBC] rounded-full w-8 h-8 object-fill"
                }
              />
              <img
                alt={"CreativeOS"}
                src={brandImage ? brandImage : "/images/icon_light.png"}
                className={
                  " dark:hidden block bg-[#BCBCBC] rounded-full w-8 h-8 object-fill"
                }
              />
            </>
            <div>
              {/**This check is being used here for the text color because this component is being used on pages that will be designed later. When the pages are being designed, we will take out the condition for the popover color */}
              <h4
                className={`truncate font-semibold text-sm leading-5 text-themeinput text-wrap`}
              >
                {footerTitle ? footerTitle : "CreativeOS"}
              </h4>

              <span
                className={`font-normal text-xs leading-4 text-${creativeOsTextColor ?? "themeinput"}`}
              >
                by CreativeOS
              </span>
            </div>
          </div>
          {props.rhsComponents ?? (
            <span className="font-normal text-xs leading-4 text-themeinput w-fit">
              {footerType}
            </span>
          )}
        </div>
      </div>
    );
  }
);
