import { useState } from 'react';
import AdInspirationDetails from './AdInspirationDetails';
import { Badge } from './shadcn/badge';
import { Ad } from './templates/DiscoverGridView';

const SearchDialogAdInspirationImage = ({
  item,
  userIsTyping = false,
  collections = [],
}: {
  item: Ad;
  collections?: string[];
  userIsTyping?: boolean;
}) => {
  const [detailsShow, setDetailsShow] = useState<boolean>(false);

  return (
    <div
      className={`${userIsTyping ? '' : 'bg-slate-100'} rounded-2xl`}
      key={item.id}
    >
      {detailsShow && item && (
        <AdInspirationDetails
          data={item}
          open={detailsShow}
          IsPublic={false}
          onOpenChange={() => setDetailsShow(false)}
        />
      )}
      {userIsTyping ? (
        <div className="flex gap-3 items-center justify-between w-full ">
          <div className="flex gap-2 items-center">
            {item.imageUrl ? (
              <img
                src={
                  item.imageUrl ??
                  'https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs'
                }
                alt={`${item.brandName ?? 'CreativeOs'} Image`}
                onClick={() => setDetailsShow(true)}
                loading="eager"
                className="min-w-[5rem] w-[5rem] h-[5rem] rounded-2xl object-cover cursor-pointer"
              />
            ) : item.videoUrl ? (
              <video
                className="min-w-[5rem] w-[5rem] h-[5rem] rounded-2xl object-cover cursor-pointer"
                muted
                loop
                controls={false}
                onClick={() => setDetailsShow(true)}
                autoPlay // Ensure video autoplays
                playsInline // For mobile devices (iOS in particular)
                src={item.videoUrl}
              ></video>
            ) : null}
            <div className="flex gap-1 items-center flex-wrap">
              {collections.map((collection) => (
                <Badge variant={'outline'} className="px-2.5 py-1 flex gap-1">
                  {collection}
                </Badge>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <img
          src={
            item.imageUrl ??
            'https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs'
          }
          alt={`${item.brandName ?? 'CreativeOs'} Image`}
          onClick={() => setDetailsShow(true)}
          loading="eager"
          className="min-w-[11.4063rem] h-[11.4063rem] rounded-2xl object-cover cursor-pointer hvr-grow"
        />
      )}
    </div>
  );
};

export default SearchDialogAdInspirationImage;
