import { createFileRoute } from '@tanstack/react-router';

import { Stack, Text } from '@/components/custom-components';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/shadcn/breadcrumb.tsx';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Link } from '@tanstack/react-router';
import { Calendar } from 'lucide-react';
import { useState } from 'react';

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute(
  '/feeds/creative-toolkit/ad-test-calculator/',
)({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;
    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [dailyAdSpend, setDailyAdSpend] = useState('');
  const [aov, setAOV] = useState('');

  const calculateMetrics = () => {
    const spend = parseFloat(dailyAdSpend);
    const averageOrderValue = parseFloat(aov);

    if (!spend || !averageOrderValue) return null;

    const monthlySpend = spend * 30;
    const spendFactor = Math.log10(monthlySpend) * 2;
    const aovFactor = Math.log10(averageOrderValue);
    const baseTests = 3;

    // Calculate recommended monthly tests
    const recommendedTests = Math.round(baseTests + spendFactor - aovFactor);
    const monthlyTests = Math.min(Math.max(recommendedTests, 2), 15);

    // Calculate weekly test volume (rounded to nearest 0.5)
    const weeklyTests = Math.round((monthlyTests / 4) * 2) / 2;

    // Calculate budget needed per ad for statistical significance
    // Using 7x AOV target and 50 conversions
    const targetCPA = averageOrderValue / 7; // Target Cost Per Acquisition
    const minConversions = 50; // Minimum conversions needed for significance
    const budgetPerTest = targetCPA * minConversions;

    // Calculate max concurrent tests (limited by both budget and 2 months of new ads)
    const maxByBudget = Math.floor(
      (monthlySpend * 0.15) / (budgetPerTest / 30),
    );
    const maxByCreativeVolume = monthlyTests * 2; // 2 months worth of new ads
    const maxConcurrentTests = Math.min(maxByBudget, maxByCreativeVolume);

    return {
      monthlyTests,
      weeklyTests,
      budgetPerTest,
      monthlyTestingBudget: monthlySpend * 0.15,
      maxConcurrentTests,
      maxByBudget,
      maxByCreativeVolume,
    };
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const getRecommendationText = (metrics: {
    monthlyTests: number;
    weeklyTests: number;
    budgetPerTest?: number;
    monthlyTestingBudget?: number;
    maxConcurrentTests?: number;
    maxByBudget: number;
    maxByCreativeVolume: number;
  }) => {
    if (!metrics) return '';

    const limitingFactor =
      metrics.maxByBudget < metrics.maxByCreativeVolume
        ? 'Budget is your limiting factor for concurrent tests. Consider increasing test budget to run more tests simultaneously.'
        : 'Creative production capacity is your limiting factor. This ensures good test coverage while maintaining fresh creative rotation.';

    if (metrics.monthlyTests <= 4) {
      return `Focus on major concept changes with ${metrics.weeklyTests} new tests per week. ${limitingFactor}`;
    } else if (metrics.monthlyTests <= 8) {
      return `Balance new concepts and iterations with ${metrics.weeklyTests} new tests weekly. ${limitingFactor}`;
    } else {
      return `Maintain high-velocity testing with ${metrics.weeklyTests} new tests per week. ${limitingFactor}`;
    }
  };

  const metrics = calculateMetrics();

  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-2 px-3 lg:px-6 pt-3 lg:pt-6">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to={'/feeds/creative-toolkit'}>Toolkit</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Ad Creative Test Calculator</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <div className="flex justify-between">
            <Text size={'xxl'} weight={'semibold'}>
              Test Smarter. Spend Better.
            </Text>
          </div>
          <Text
            size={'lg'}
            weight={'normal'}
            className="text-thememutedforeground"
          >
            Pinpoint the ideal testing volume and budget to elevate every ad
            campaign.
          </Text>
        </Stack>
        <div className="flex justify-between gap-5 px-3 lg:px-6 flex-wrap lg:flex-nowrap">
          <Stack className="w-full gap-2">
            <Label htmlFor="spend">Daily Ad Spend</Label>
            <div className="relative">
              <Text
                size={'sm'}
                weight={'normal'}
                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-thememutedforeground"
              >
                $
              </Text>
              <Input
                id="spend"
                type="number"
                value={dailyAdSpend}
                onChange={(e) => setDailyAdSpend(e.target.value)}
                className="pl-7"
              />
            </div>
          </Stack>

          <Stack className="w-full gap-2">
            <Label htmlFor="aov">Average Order Value</Label>
            <div className="relative">
              <Text
                size={'sm'}
                weight={'normal'}
                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-thememutedforeground"
              >
                $
              </Text>
              <Input
                id="aov"
                type="number"
                value={aov}
                onChange={(e) => setAOV(e.target.value)}
                className="pl-7"
              />
            </div>
          </Stack>
        </div>

        <hr className={'border-themeinput'} />

        <Stack className="px-3 lg:px-6 gap-9">
          <Text
            size={'sm'}
            weight={'medium'}
            className="flex items-center gap-2"
          >
            <Calendar className="w-5 h-5" />
            Testing Pace
          </Text>

          {!metrics && (
            <Stack className="gap-9">
              <Text size={'4xl'} weight={'medium'}>
                -
              </Text>
              <Text
                size={'base'}
                weight={'thin'}
                className="text-thememutedforeground"
              >
                Enter your info to get started
              </Text>
            </Stack>
          )}

          {metrics && (
            <div className="flex justify-between gap-9 flex-wrap lg:flex-nowrap pb-3 lg:pb-6">
              <Stack className="gap-6">
                <Stack className="gap-2">
                  <Text
                    size={'4xl'}
                    weight={'bold'}
                    className="text-themedestructive text-4xl"
                  >
                    {formatCurrency(metrics.budgetPerTest)}
                  </Text>
                  <Text size={'4xl'} weight={'medium'} className="text-4xl">
                    Budget per test
                  </Text>
                  <Text
                    size={'base'}
                    weight={'thin'}
                    className="text-thememutedforeground"
                  >
                    Based on 50 conversions at 7x AOV
                  </Text>
                </Stack>
                <Text
                  size={'sm'}
                  weight={'thin'}
                  className="text-thememutedforeground"
                >
                  {getRecommendationText(metrics)}
                </Text>
              </Stack>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 text-sm w-full">
                <Stack className="border border-themeinput rounded-lg p-4 h-min gap-2">
                  <Text size={'xxl'} weight={'semibold'}>
                    {metrics.monthlyTests}
                  </Text>
                  <Text
                    size={'base'}
                    weight={'thin'}
                    className="text-thememutedforeground"
                  >
                    Monthly New Tests
                  </Text>
                </Stack>
                <Stack className="border border-themeinput rounded-lg p-4 h-min gap-2">
                  <Text size={'xxl'} weight={'semibold'}>
                    {metrics.weeklyTests}
                  </Text>
                  <Text
                    size={'base'}
                    weight={'thin'}
                    className="text-thememutedforeground"
                  >
                    New Tests per Week
                  </Text>
                </Stack>
                <Stack className="border border-themeinput rounded-lg p-4 h-min gap-2">
                  <Text size={'xxl'} weight={'semibold'}>
                    {metrics.maxConcurrentTests}
                  </Text>
                  <Text
                    size={'base'}
                    weight={'thin'}
                    className="text-thememutedforeground"
                  >
                    Max Concurrent Tests
                  </Text>
                </Stack>
                <Stack className="border border-themeinput rounded-lg p-4 h-min gap-2">
                  <Text size={'xxl'} weight={'semibold'}>
                    {formatCurrency(metrics.monthlyTestingBudget)}
                  </Text>
                  <Text
                    size={'base'}
                    weight={'thin'}
                    className="text-thememutedforeground"
                  >
                    Monthly Test Budget
                  </Text>
                </Stack>
              </div>
            </div>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
