import { CheckoutUpgradeSubscription } from '@/components/CheckoutSubscriptionPlan.tsx';
import { Loader } from '@/components/custom-components/Loader';
import { Badge } from '@/components/shadcn/badge.tsx';
import { Button } from '@/components/shadcn/button.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/shadcn/dialog.tsx';
import { Tabs, TabsList, TabsTrigger } from '@/components/shadcn/tabs.tsx';
import { PermissionsData } from '@/routes/feeds.tsx';
import { featureFlagKeys } from '@/utils/data/featureFlags';
import {
  PRO_PLAN,
  PlanDetail,
  STANDARD_PLAN,
  plans,
} from '@/utils/data/plans.ts';
import { trpc } from '@/utils/trpc.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { CheckCircle } from '@mynaui/icons-react';
import { useNavigate } from '@tanstack/react-router';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import UpgradeBox from './UpgradeBox';
import { Form, FormControl, FormField, FormItem } from './shadcn/form';
import { RadioGroup, RadioGroupItem } from './shadcn/radio';

export default function FreeTrialEnding({
  open,
  onOpenChange,
  permissionData,
}: {
  open: boolean;
  onOpenChange: () => void;
  permissionData: PermissionsData;
}) {
  const annualFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.annualPlans);
  const navigate = useNavigate();
  const [period, setPeriod] = useState<'monthly' | 'annually'>('monthly');

  const [subscriptionStatus, setSubscriptionStatus] = useState<
    'onFreeTrial' | 'hasPlan' | 'hasNoPlan' | undefined
  >(undefined);

  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(
    undefined,
  );

  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);

  const [newSelectedPlan, setNewSelectedPlan] = useState<
    PlanDetail | undefined
  >(undefined);

  const { data: chargebeeSyncStatus, isLoading: isLoadingChargebeeSync } =
    trpc.getIsUserSynced.useQuery(undefined, {
      enabled: open,
    });

  const { mutate: openPortalSessionUrl, isPending: fetchingPortalSessionUrl } =
    trpc.getPortalSessionUrl.useMutation({
      onSuccess: async (data) => {
        if (data) {
          window.location.href = data;
        }
      },
    });

  const { mutateAsync: logoutUser, isPending: loggingOut } =
    trpc.logoutUser.useMutation();

  useEffect(() => {
    if (!isLoadingChargebeeSync && !chargebeeSyncStatus) {
      navigate({ to: '/checkout' });
    }
  }, [chargebeeSyncStatus]);

  useEffect(() => {
    if (permissionData && plans) {
      if (
        permissionData.userCanAccessEverything &&
        permissionData.usersPlans.length === 0
      )
        setSubscriptionStatus('onFreeTrial');
      else if (
        permissionData.usersPlans.length > 0 &&
        permissionData.usersPlans.some((plan) =>
          [
            STANDARD_PLAN.plan_id,
            STANDARD_PLAN.yearly_plan_id,
            STANDARD_PLAN.no_trial_plan_id,
            PRO_PLAN.plan_id,
            PRO_PLAN.yearly_plan_id,
            PRO_PLAN.no_trial_plan_id,
          ].includes(plan),
        )
      ) {
        setSubscriptionStatus('hasPlan');
      } else {
        setSubscriptionStatus('hasNoPlan');
      }
    }
  }, [permissionData]);

  const selectPlanFormSchema = z.object({
    plan: z.string({ required_error: 'Select a plan' }),
  });

  type SelectPlanFormValues = z.infer<typeof selectPlanFormSchema>;

  const defaultValues: Partial<SelectPlanFormValues> = {};

  const form = useForm<SelectPlanFormValues>({
    resolver: zodResolver(selectPlanFormSchema),
    defaultValues,
  });

  async function checkoutPlan(selectedPlan: string) {
    if (selectedPlan) {
      setUpgradeOpen(true);
      setNewSelectedPlan(plans.find((p) => p.plan_id === selectedPlan));
    }
  }

  async function onSubmit() {
    const selectedPlan = form.getValues('plan');

    if (selectedPlan) {
      setUpgradeOpen(true);
      setNewSelectedPlan(plans.find((p) => p.plan_id === selectedPlan));
    }
  }

  const plan = form.watch('plan');

  useEffect(() => {
    setNewSelectedPlan(plans.find((p) => p.plan_id === plan));
  }, [plan]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {annualFlagEnabled ? (
        <DialogContent
          className={`bg-themebackground dark:bg-themebackgrounddark ${annualFlagEnabled ? 'md:max-w-xl' : 'md:max-w-3xl'} overflow-y-auto max-h-screen ${subscriptionStatus === 'hasNoPlan' ? '[&>button]:hidden' : ''}`}
          onInteractOutside={(e) => e.preventDefault()}
          onEscapeKeyDown={(e) => e.preventDefault()}
        >
          {isLoadingChargebeeSync ? (
            <div
              className={
                'flex w-full justify-center items-center h-[20vh] m-auto'
              }
            >
              <Loader />
            </div>
          ) : upgradeOpen && newSelectedPlan ? (
            <CheckoutUpgradeSubscription
              userHasPlan={subscriptionStatus === 'hasPlan'}
              selectedPlan={newSelectedPlan}
            />
          ) : (
            <div>
              <DialogHeader>
                <div className={'flex flex-col gap-2'}>
                  <DialogTitle
                    className={
                      'text-2xl text-left font-semibold'
                    }
                  >
                    {subscriptionStatus === 'hasPlan'
                      ? 'Upgrade your plan'
                      : 'Your free trial has expired'}
                  </DialogTitle>
                  <DialogDescription className={'font-normal text-base'}>
                    {subscriptionStatus === 'hasPlan'
                      ? 'Level up your ad workflow with CreativeOS Pro'
                      : 'We hope you enjoyed it! Upgrade to pro to keep all the features you’ve been using.'}
                  </DialogDescription>
                </div>
              </DialogHeader>
              <div className="gap-3 flex flex-col">
                <div
                  className={'flex justify-between items-center gap-1.5 h-9'}
                >
                  {subscriptionStatus === 'hasNoPlan' &&
                    chargebeeSyncStatus &&
                    (fetchingPortalSessionUrl ? (
                      <span className="font-medium text-sm leading-5 text-thememutedforeground tracking-wide	">
                        Loading...
                      </span>
                    ) : (
                      <div className="w-full flex justify-center md:justify-start gap-4">
                        <span
                          onClick={() => openPortalSessionUrl()}
                          className="font-medium text-sm leading-5 underline underline-offset-1 cursor-pointer tracking-wide"
                        >
                          Manage Account
                        </span>
                        <span
                          onClick={() => {
                            logoutUser().then(() => {
                              if (typeof localStorage !== 'undefined') {
                                localStorage.clear();
                              }
                              navigate({ to: '/login' });
                            });
                          }}
                          className="font-medium text-sm leading-5 underline underline-offset-1 cursor-pointer tracking-wide"
                        >
                          {loggingOut ? 'Logging out...' : 'Logout'}
                        </span>
                      </div>
                    ))}
                </div>
                {subscriptionStatus === 'hasPlan' ? (
                  <div className="gap-3 flex flex-col">
                    {plans &&
                      plans.map((plan, index) => (
                        <UpgradeBox
                          boxData={plan}
                          key={index}
                          period={period}
                          userPlans={permissionData?.usersPlans ?? []}
                          descriptionDetailsToShow={'description'}
                          setSelected={setNewSelectedPlan}
                          setUpgradeOpen={setUpgradeOpen}
                        />
                      ))}
                  </div>
                ) : (
                  <div className={'space-y-3'}>
                    <div
                      className={
                        'flex  flex-col md:flex-row justify-between gap-3 items-center'
                      }
                    >
                      <p className={'font-bold'}>Available Plans</p>
                      <Tabs defaultValue={period}>
                        <TabsList className={'rounded-full'}>
                          <TabsTrigger
                            className={'rounded-full'}
                            value={'monthly'}
                            onClick={() => setPeriod('monthly')}
                          >
                            Pay Monthly
                          </TabsTrigger>
                          <TabsTrigger
                            className={'rounded-full flex gap-1.5'}
                            value={'annually'}
                            onClick={() => setPeriod('annually')}
                          >
                            <span>Pay Yearly</span>{' '}
                            <span className={'text-themedestructive'}>
                              Save 50%
                            </span>
                          </TabsTrigger>
                        </TabsList>
                      </Tabs>
                    </div>
                    <div className={'grid grid-cols-1 lg:grid-cols-2 gap-3'}>
                      {STANDARD_PLAN && (
                        <div className={'space-y-3 p-4 border-2 rounded-lg'}>
                          <div>
                            <h3 className={'font-medium'}>
                              {STANDARD_PLAN.name}
                            </h3>
                            <p className={'mt-1'}>
                              <span className={'text-2xl font-semibold'}>
                                {period === 'monthly'
                                  ? STANDARD_PLAN.price
                                  : STANDARD_PLAN.annual_monthly_price}
                              </span>{' '}
                              / month, billed {period}
                            </p>
                            <p
                              className={
                                'text-sm font-light text-thememutedforeground'
                              }
                            >
                              {period === 'monthly' ? (
                                'Save 50% when billed annually'
                              ) : (
                                <span className={'line-through'}>
                                  {STANDARD_PLAN.price} / billed monthly
                                </span>
                              )}
                            </p>
                          </div>
                          <Button
                            size={'sm'}
                            className={'w-full'}
                            onClick={() =>
                              checkoutPlan(
                                period === 'monthly'
                                  ? STANDARD_PLAN.plan_id
                                  : STANDARD_PLAN.yearly_plan_id,
                              )
                            }
                          >
                            Select Plan
                          </Button>
                          <div>
                            <p
                              className={
                                'text-sm font-light text-thememutedforeground'
                              }
                            >
                              {STANDARD_PLAN.summary}
                            </p>
                            <ul className={'space-y-3 mt-2'}>
                              {STANDARD_PLAN.features.map((feature, index) => (
                                <li
                                  key={index}
                                  className="flex gap-1 justify-start items-center text-thememutedforeground"
                                >
                                  <CheckCircle className="w-4 h-4" />
                                  <span className="text-sm font-normal leading-5 ">
                                    {feature}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                      {PRO_PLAN && (
                        <div
                          className={
                            'space-y-3 p-4 border-2 border-themedestructive rounded-lg'
                          }
                        >
                          <div>
                            <div className={'flex gap-2 items-center'}>
                              <h3 className={'font-medium'}>{PRO_PLAN.name}</h3>
                              <Badge variant={'secondaryDestructive'}>
                                Recommended
                              </Badge>
                            </div>
                            <p>
                              <span className={'text-2xl font-semibold'}>
                                {period === 'monthly'
                                  ? PRO_PLAN.price
                                  : PRO_PLAN.annual_monthly_price}
                              </span>{' '}
                              / month, billed {period}
                            </p>
                            <p
                              className={
                                'text-sm font-light text-thememutedforeground'
                              }
                            >
                              {period === 'monthly' ? (
                                'Save 50% when billed annually'
                              ) : (
                                <span className={'line-through'}>
                                  {PRO_PLAN.price} / billed monthly
                                </span>
                              )}
                            </p>
                          </div>
                          <Button
                            variant={'destructivePurple'}
                            size={'sm'}
                            className={'w-full'}
                            onClick={() =>
                              checkoutPlan(
                                period === 'monthly'
                                  ? PRO_PLAN.plan_id
                                  : PRO_PLAN.yearly_plan_id,
                              )
                            }
                          >
                            Select Plan
                          </Button>
                          <div>
                            <p
                              className={
                                'text-sm font-light text-thememutedforeground'
                              }
                            >
                              {PRO_PLAN.summary}
                            </p>
                            <ul className={'space-y-3 mt-2'}>
                              {PRO_PLAN.features.map((feature, index) => (
                                <li
                                  key={index}
                                  className="flex gap-1 justify-start items-center text-thememutedforeground"
                                >
                                  <CheckCircle className="w-4 h-4" />
                                  <span className="text-sm font-normal leading-5 ">
                                    {feature}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className={'mt-3'}>
                <div className={'flex justify-center'}>
                  <a target={'_blank'} href={'https://www.creativeos.io/#sign'}>
                    <span
                      className={'underline font-medium text-sm text-center'}
                    >
                      View full plan comparison
                    </span>
                  </a>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      ) : (
        <DialogContent
          className={`bg-themebackground dark:bg-themebackgrounddark sm:max-w-lg overflow-y-auto max-h-screen ${subscriptionStatus === 'hasNoPlan' ? '[&>button]:hidden' : ''}`}
          onInteractOutside={(e) => e.preventDefault()}
          onEscapeKeyDown={(e) => e.preventDefault()}
        >
          {isLoadingChargebeeSync ? (
            <div
              className={
                'flex w-full justify-center items-center h-[20vh] m-auto'
              }
            >
              <Loader />
            </div>
          ) : upgradeOpen && newSelectedPlan ? (
            <CheckoutUpgradeSubscription
              userHasPlan={subscriptionStatus === 'hasPlan'}
              selectedPlan={newSelectedPlan}
            />
          ) : (
            <div>
              <DialogTitle>
                <div className={'flex flex-col gap-2'}>
                  <h4
                    className={
                      'text-2xl text-left font-semibold'
                    }
                  >
                    Upgrade to Unlock
                  </h4>
                  <p className={'text-left text-thememutedforeground mb-6'}>
                    Level up your ad workflow with our paid plans
                  </p>
                </div>
              </DialogTitle>
              <div>
                <>
                  {subscriptionStatus === 'hasNoPlan' && (
                    <div className={'space-y-3 mb-6'}>
                      {plans &&
                        plans
                          .find((item) => item.plan_id === selectedPlan)
                          ?.features.map((feature, index) => (
                            <div
                              key={index}
                              className="flex gap-1 justify-start items-center text-thememutedforeground"
                            >
                              <CheckCircle className="w-4 h-4" />
                              <span className="text-sm font-normal leading-5 ">
                                {feature}
                              </span>
                            </div>
                          ))}
                    </div>
                  )}

                  {subscriptionStatus === 'hasPlan' &&
                    permissionData.usersPlans.find(
                      (p) => p === STANDARD_PLAN.plan_id,
                    ) && (
                      <div className={'space-y-3 mb-6'}>
                        {PRO_PLAN.features.map((feature, index) => (
                          <div
                            key={index}
                            className="flex gap-1 justify-start items-center text-thememutedforeground"
                          >
                            <CheckCircle className="w-4 h-4" />
                            <span className="text-sm font-normal leading-5 ">
                              {feature}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                </>
              </div>
              <div className="gap-3 flex flex-col">
                <div
                  className={'flex justify-between items-center gap-1.5 h-9'}
                >
                  <h5>Select your plan</h5>

                  {subscriptionStatus === 'hasNoPlan' &&
                    chargebeeSyncStatus &&
                    (fetchingPortalSessionUrl ? (
                      <span className="font-medium text-sm leading-5 text-thememutedforeground tracking-wide	">
                        Loading...
                      </span>
                    ) : (
                      <div className="flex gap-4">
                        <span
                          onClick={() => openPortalSessionUrl()}
                          className="font-medium text-sm leading-5 underline underline-offset-1 cursor-pointer tracking-wide"
                        >
                          Manage Account
                        </span>
                        <span
                          onClick={() => {
                            logoutUser().then(() => {
                              if (typeof localStorage !== 'undefined') {
                                localStorage.clear();
                              }
                              caches.keys().then((names) => {
                                names.forEach((name) => {
                                  caches.delete(name);
                                });
                              });
                              navigate({ to: '/login' });
                            });
                          }}
                          className="font-medium text-sm leading-5 underline underline-offset-1 cursor-pointer tracking-wide"
                        >
                          {loggingOut ? 'Logging out...' : 'Logout'}
                        </span>
                      </div>
                    ))}
                </div>
                {subscriptionStatus === 'hasPlan' ? (
                  <div className="gap-3 flex flex-col">
                    {plans &&
                      plans.map((plan, index) => (
                        <UpgradeBox
                          boxData={plan}
                          key={index}
                          userPlans={permissionData?.usersPlans ?? []}
                          descriptionDetailsToShow={'description'}
                          setSelected={setNewSelectedPlan}
                          setUpgradeOpen={setUpgradeOpen}
                        />
                      ))}
                  </div>
                ) : (
                  <div>
                    <Form {...form}>
                      <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-6"
                      >
                        <div className={'space-y-6'}>
                          <FormField
                            control={form.control}
                            name="plan"
                            render={({ field }) => (
                              <RadioGroup
                                onValueChange={field.onChange}
                                value={field.value}
                                className="flex flex-col space-y-1"
                              >
                                {plans &&
                                  plans.map((item) => (
                                    <FormControl key={item.plan_id} >
                                      <FormItem >
                                        <RadioGroupItem
                                          checked={field.value === item.plan_id}
                                          value={item.plan_id}
                                          onChange={() =>
                                            field.onChange(item.plan_id)
                                          }
                                          className='bg-themebackground dark:bg-themebackgrounddark'
                                          id={item.name}
                                          plan={item}
                                          showTrial={false}
                                          onClick={() =>
                                            setSelectedPlan(item.plan_id)
                                          }
                                        >
                                          {item.name}
                                        </RadioGroupItem>
                                      </FormItem>
                                    </FormControl>
                                  ))}
                              </RadioGroup>
                            )}
                          />
                          <Button
                            className={'w-full'}
                            variant={'destructivePurple'}
                            size={'lg'}
                            type={'submit'}
                          >
                            Upgrade
                          </Button>
                        </div>
                      </form>
                    </Form>
                  </div>
                )}
              </div>

              <div className={'mt-3'}>
                <div className={'flex justify-center'}>
                  <a target={'_blank'} href={'https://www.creativeos.io/#sign'}>
                    <span
                      className={'underline font-medium text-sm text-center'}
                    >
                      View full plan comparison
                    </span>
                  </a>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
}
