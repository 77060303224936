import { featureFlagKeys } from '@/utils/data/featureFlags';
import { useUserback } from '@userback/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { createContext, useContext, useEffect, useState } from 'react';

type UserbackContextType = {
  isVisible: boolean;
  toggleUserback: (visible: boolean) => void;
};

const UserbackContext = createContext<UserbackContextType | undefined>(
  undefined,
);

export const CustomUserbackProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { show, hide } = useUserback();
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [, setIsLoaded] = useState<boolean>(false);

  const userbackFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.userback);

  useEffect(() => {
    if (!userbackFlagEnabled && typeof userbackFlagEnabled !== 'undefined') {
      localStorage.setItem('userbackVisible', 'false');
    }
  }, [userbackFlagEnabled]);

  useEffect(() => {
    // Load visibility state from localStorage
    const savedState = localStorage.getItem('userbackVisible');
    const shouldBeVisible = savedState === 'true';

    setIsVisible(shouldBeVisible);

    // Ensure Userback loads correctly
    const checkUserback = setInterval(() => {
      if (window.Userback) {
        clearInterval(checkUserback);
        setIsLoaded(true);

        if (shouldBeVisible) {
          show();
          window.Userback?.show();
        } else {
          hide();
          window.Userback?.hide();
        }
      }
    }, 100);

    return () => clearInterval(checkUserback);
  }, [show, hide]);

  const toggleUserback = (visible: boolean) => {
    setIsVisible(visible);
    localStorage.setItem('userbackVisible', visible.toString());

    if (visible) {
      show();
      window.Userback?.show();
    } else {
      hide();
      window.Userback?.hide();
    }
  };

  return (
    <UserbackContext.Provider value={{ isVisible, toggleUserback }}>
      {children}
    </UserbackContext.Provider>
  );
};

export const useUserbackContext = () => {
  const context = useContext(UserbackContext);
  if (!context) {
    throw new Error(
      'useUserbackContext must be used within a UserbackProvider',
    );
  }
  return context;
};
