import { defaultUseQueryRefetchOptions } from '@/_shared/constants';
import { trpc } from '@/utils/trpc';
import { Lock } from '@mynaui/icons-react';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import FreeTrialEnding from './FreeTrialEnding';
import { PermissionData } from './SearchDialog';
import { Badge } from './shadcn/badge';
import { Skeleton } from './shadcn/skeleton';

const SearchDialogExpertsTab = ({
  permissions,
}: {
  permissions: PermissionData;
}) => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: experts, isLoading: expertsIsLoading } =
    trpc.listExperts.useQuery({}, defaultUseQueryRefetchOptions);

  const navigate = useNavigate();

  return (
    <div className="space-y-2.5">
      {upgradeDialogOpen && permissions && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissions}
        />
      )}
      <div className="space-y-1.5">
        <h6 className="text-gray-500 font-medium text-sm">All Experts</h6>
      </div>
      <div>
        {expertsIsLoading ? (
          <div className="space-y-3">
            {[...Array(3)].map((_, index) => (
              <div
                key={index}
                className="flex justify-between items-center gap-3.5 p-3.5"
              >
                <div className="flex items-center gap-3">
                  <Skeleton className="w-16 h-16 rounded-full" />
                  <div className="space-y-1">
                    <Skeleton className="h-4 w-24" />
                  </div>
                </div>
                <div className="text-xs text-slate-500 text-right flex flex-col justify-between space-y-1">
                  <Skeleton className="h-3 w-12" />
                  <Skeleton className="h-3 w-12" />
                </div>
              </div>
            ))}
          </div>
        ) : (
          experts?.data.map((item) => (
            <div
              onClick={
                !permissions.userCanAccessEverything
                  ? () => setUpgradeDialogOpen(true)
                  : () =>
                      navigate({
                        to: `/feeds/collections/experts/ad/$expert`,
                        params: { expert: item.name },
                      })
              }
              key={item.name}
              className={`cursor-pointer flex items-center justify-between gap-3.5 p-3.5`}
            >
              <div className={`flex items-center gap-3 w-auto`}>
                <img
                  src={item.image ?? ''}
                  className={`w-16 h-16 bg-slate-100 rounded-full object-cover  ${!permissions.userCanAccessEverything ? 'filter blur-sm pointer-events-none relative' : ''}`}
                  key={item.name}
                  alt={item.name}
                />
                <div>
                  <h5 className="text-sm">{item.name}</h5>
                </div>
                {!permissions.userCanAccessEverything ? (
                <Badge
                  variant={'outline'}
                  className=" text-themedestructive border-themedestructive"
                >
                  <Lock size={'1rem'} /> PRO
                </Badge>
              ) : null}
              </div>

              <div className="text-xs text-slate-500 text-right flex flex-col pr-3 w-fit h-fit">
                {item.totalAdCount == 0 ? null : <p>{item.totalAdCount} Ads</p>}
                {item.totalEmailCount == 0 ? null : (
                  <p>{item.totalEmailCount} Emails</p>
                )}
              </div>
              
              
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SearchDialogExpertsTab;
