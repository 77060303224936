import { defaultUseQueryRefetchOptions } from '@/_shared/constants.ts';
import {
  MasonryComponent,
  TypedResponsiveMasonry,
} from '@/components/ResponsiveMasonryWrapper.tsx';
import { OrderFilterSelect } from '@/components/ad-inspiration/OrderFilterSelect.tsx';
import { Stack, Text } from '@/components/custom-components';
import { FeatureTabs } from '@/components/custom-components/FeatureTabs/index.tsx';
import { Loader } from '@/components/custom-components/Loader';
import { ErrorDisplay } from '@/components/error.tsx';
import { Badge } from '@/components/shadcn/badge.tsx';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/shadcn/breadcrumb.tsx';
import { Button } from '@/components/shadcn/button.tsx';
import { AdCard } from '@/components/templates/AdCard';
import useCardLayoutWithImpressions from '@/hooks/useCardLayoutWithImpressions.tsx';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard.tsx';
import showToastNotification from '@/hooks/useShowToast.tsx';
import { trpc } from '@/utils/trpc.ts';
import {
  BrandInstagram,
  BrandLinkedin,
  BrandX,
  Link as LinkIcon,
} from '@mynaui/icons-react';
import { createFileRoute } from '@tanstack/react-router';
import { Link } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { z } from 'zod';
import {
  AirTableAdRecord,
  Expert,
  orderFilter as OrderFilterType,
  orderFilter as OrderFilterTypes,
} from '../../../../src/shared/airtable/types.ts';

type SearchParams = {
  sideBarOpen?: boolean;
  orderFilter?: z.infer<typeof OrderFilterType>;
};

export const Route = createFileRoute('/feeds/collections/experts/ad/$expert')({
  component: AdExpertCollectionTemplates,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      sideBarOpen,
      orderFilter,
    };
  },
});

function AdExpertCollectionTemplates() {
  const { orderFilter } = Route.useSearch();
  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined,
  );
  const { expert } = Route.useParams();
  const [, copyToClipboard] = useCopyToClipboard();

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const { data: experts } = trpc.getExpertsWithTemplates.useQuery(
    {},
    defaultUseQueryRefetchOptions,
  );

  const {
    data: expertTemplates,
    fetchNextPage,
    isLoading,
    isRefetching,
    error,
  } = trpc.posts.useInfiniteQuery(
    {
      Expert: expert as Expert,
      Tags: undefined,
      loadAdsCreatedAfter: undefined,
      Ready: true,
      limit: 20,
      sortingOptions: orderFilter as z.infer<typeof OrderFilterTypes>,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !isLoadingPermission && permissionData?.userCanAccessEverything,
    },
  );

  // Ref for trigger to fetch next page
  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(
    function fetchNextPageWhenElemInView() {
      if (!inView || !expertTemplates) {
        return;
      }
      if (!allData) {
        return;
      }
      if (!allData.length) {
        return;
      }
      // We've fetched all the data if these are equal
      if (expertTemplates.pages[0].TotalRecords <= allData?.length) {
        return;
      }
      fetchNextPage();
    },

    [inView, expertTemplates, allData, fetchNextPage],
  );

  // Syncs react state with trpc state
  useEffect(
    function addFetchedDataToReactState() {
      if (!expertTemplates) return;
      setAllData(() => undefined);
      const records = [] as AirTableAdRecord[];
      for (const page of expertTemplates.pages) {
        records.push(...page.ATRecords);
      }
      setAllData(() => records);
    },
    [expertTemplates],
  );

  const { squareRef, desiredCardWidth, columns, gutterWidth } =
    useCardLayoutWithImpressions();

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading || isRefetching) {
    return (
      <div
        className={'flex justify-center items-center w-full h-screen m-auto'}
      >
        <Loader />
      </div>
    );
  }
  const OrderFilterRow = ({ className }: { className: string }) => (
    <div className={className}>
      <Text
        className="text-thememutedforeground w-fit text-nowrap"
        size={'base'}
        weight={'normal'}
      >
        {expertTemplates?.pages[0].TotalRecords ?? 0}{' '}
        {(expertTemplates?.pages[0].TotalRecords ?? 0) > 1 ? ' Ads' : ' Ad'}
      </Text>
      <Button
        onClick={() => {
          copyToClipboard(
            `${window.location.origin}/feeds/collections/experts/ad/${expert}`,
          );
          showToastNotification('success', { message: 'Copied!' });
        }}
        className="lg:hidden flex"
        variant={'outline'}
        size={'iconSm'}
      >
        <LinkIcon className="w-5 h-5" />
      </Button>

      <OrderFilterSelect
        defaultFilter={orderFilter || 'Random'}
        options={['Random', 'Recent', 'Popular', 'Oldest']}
      />
    </div>
  );

  return (
    <Stack className="gap-3 lg:gap-8">
      <FeatureTabs
        defaultOrderValue={'Random'}
        urlSegmentCountToCompare={4}
        tabItems={[
          {
            name: 'Ads',
            link: `/feeds/collections/experts/ad/${expert}/`,
          },
          {
            name: 'Emails',
            link: `/feeds/collections/experts/email/${expert}/`,
          },
        ]}
      />
      <Stack className="gap-3 lg:gap-2">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to={'/feeds/collections/experts'}>Collections</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Expert</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className={'flex justify-between gap-6 items-center'}>
          <div className="flex gap-2 items-center">
            <img
              className={'rounded-full w-9 h-9 bg-themeaccent'}
              src={
                (experts &&
                  experts.data.find((i) => i.name == expert)?.image) ||
                '/bg_gradient-min.png'
              }
              alt={'CreativeOs'}
            />
            <Text weight="semibold" size={'xxl'}>
              {expert}
            </Text>
          </div>
          <Button
            onClick={() => {
              copyToClipboard(
                `${window.location.origin}/feeds/collections/experts/ad/${expert}`,
              );
              showToastNotification('success', { message: 'Copied!' });
            }}
            className={'flex'}
            variant={'outline'}
            size={'iconSm'}
          >
            <LinkIcon />
          </Button>
        </div>
        <div className={`flex gap-1 items-center justify-between`}>
          <div className={`flex gap-4 items-center`}>
            <div className={`flex gap-1.5 items-center`}>
              <Text
                className="text-thememutedforeground w-fit text-nowrap"
                size={'base'}
                weight={'normal'}
              >
                Templates Created by
              </Text>
              <Text
                className="text-themeforeground w-fit text-nowrap"
                size={'base'}
                weight={'normal'}
              >
                {expert}
              </Text>
              <Badge variant={'destructivePurple'}>Expert</Badge>
            </div>
            <div className={`flex gap-1.5 items-center`}>
              <Button
                className="hidden lg:flex"
                variant={'outline'}
                size={'iconXSm'}
              >
                <BrandInstagram className="w-5 h-5" />
              </Button>
              <Button
                className="hidden lg:flex"
                variant={'outline'}
                size={'iconXSm'}
              >
                <BrandX className="w-5 h-5" />
              </Button>
              <Button
                className="hidden lg:flex"
                variant={'outline'}
                size={'iconXSm'}
              >
                <BrandLinkedin className="w-5 h-5" />
              </Button>
            </div>
          </div>
          <OrderFilterRow className="items-center gap-3 hidden lg:flex w-fit" />
        </div>
        <OrderFilterRow className="items-center gap-3 lg:hidden flex w-fit" />
      </Stack>
      <div ref={squareRef} className={'relative w-full pb-10'}>
        {!isLoadingPermission &&
          permissionData?.userCanAccessEverything &&
          expertTemplates && (
            <>
              {expertTemplates.pages[0].TotalRecords === 0 ? (
                <div className={'lg:h-96 flex justify-center items-center'}>
                  <p>No templates added to this collection</p>
                </div>
              ) : (
                allData && (
                  <div>
                    <TypedResponsiveMasonry
                      columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                    >
                      <MasonryComponent gutter={gutterWidth + 'px'}>
                        {allData.map((ad) => {
                          return (
                            <AdCard
                              key={ad.atID}
                              adData={{
                                Ad: ad,
                                IsLocked: false,
                                IsPublic: false,
                                brandName: undefined,
                                shouldInvalidateCache: false,
                                DesiredWidth: desiredCardWidth,
                              }}
                            />
                          );
                        })}
                      </MasonryComponent>
                    </TypedResponsiveMasonry>
                  </div>
                )
              )}
            </>
          )}
        <div className={'relative'}>
          <div
            className={
              'absolute w-[10px] h-[1500px] transform translate-y-[-1500px]' // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          ></div>
        </div>
        <div className={'relative'}>
          <div
            className={
              'absolute w-[10px] h-[1500px] transform translate-y-[-1500px]' // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          ></div>
        </div>
      </div>
    </Stack>
  );
}
