import { ReadabilityScore, reabilityscores } from "@/components/CopyGrader.tsx";
import { Text } from "@/components/custom-components";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/shadcn/dialog.tsx";
import { ScrollArea } from "@/components/shadcn/scroll-area.tsx";

export function CopyGraderChecker({
  open,
  onOpenChange,
  flesch,
}: {
  open: boolean;
  onOpenChange: () => void;
  flesch: number;
}) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={"gap-6 sm:min-w-[60svw]"}>
        <DialogHeader>
          <DialogTitle>
            <Text weight={"semibold"} size={"lg"} className="leading-7">
              {"Readability Score"}
            </Text>
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className={"max-h-[80svh]"}>
          <div>
            <div>
              <h4 className={"text-sm font-medium"}>
                Flesch-Kincaid readability scores
              </h4>
              <ReadabilityScore data={reabilityscores} fleschScore={flesch} />
            </div>
          </div>
          <div>
            <h4 className={"text-sm font-medium"}>
              What is a Flesch Reading Ease Score?
            </h4>
            <p
              className={
                "text-thememutedforeground font-light mt-1.5 leading-snug"
              }
            >
              Most of the time, a readability score helps evaluate how easy it
              is to read a piece of content or a passage. This score indicates
              the educational level required to read the text without
              difficulty, making it a helpful tool for assessing accessibility.
              <br />
              <br />
              The Flesch reading ease test provides a score that ranges from 0
              to 100, reflecting how understandable a passage is for an average
              adult. A higher score means the content is easier to read and
              comprehend. For instance, a score closer to 100 indicates
              simplicity, while a lower score suggests the text might be
              challenging and better suited for professionals or specialized
              readers.
              <br />
              <br />A Flesch reading score of 70 or above is considered good,
              signifying that the content is fairly easy to read for most
              people. On the other hand, a score below 30 might indicate complex
              text requiring advanced reading skills. <br />
              <br />
              By using readability scores, writers can tailor their content to
              better suit their audience's comprehension level.
            </p>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
