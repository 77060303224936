import OnboardingSteppers from '@/components/onboarding/OnboardingSteppers.tsx';
import { Button } from '@/components/shadcn/button.tsx';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/shadcn/form.tsx';
import {
  OnboardingRadioGroupItem,
  RadioGroup,
} from '@/components/shadcn/radio.tsx';
import showToastNotification from '@/hooks/useShowToast';
import {
  LeadType,
  OnboardingFormSchema,
  SectionProps,
  leads,
} from '@/routes/get-started.tsx';
import { trpc } from '@/utils/trpc.ts';
import { useNavigate } from '@tanstack/react-router';
import { ArrowLeftIcon } from 'lucide-react';
import { z } from 'zod';

export default function ClientLeadSource(props: SectionProps) {
  const navigate = useNavigate();

  const { mutate, isPending } = trpc.completeOnboardingStep.useMutation();

  async function onSubmit(data: z.infer<typeof OnboardingFormSchema>) {
    const selected = data.leads as LeadType;

    try {
      mutate(
        {
          lead: selected ? selected : undefined,
        },
        {
          onSuccess: (data) => {
            if (data && data.id) {
              navigate({
                to: '/feeds/templates',
                search: {
                  orderFilter: 'Recent',
                  cacheBuster: Math.random(),
                  getStarted: 'ready',
                }, // Unique cacheBuster },
              });
            }
          },
          onError: (error) => {
            showToastNotification('error', {
              message: error.message,
              description:
                'Please select one of the options above or skip to proceed',
            });
          },
        },
      );
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div
      className={
        'rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border'
      }
    >
      <div className={'flex flex-col gap-6'}>
        <div className={'flex gap-5 justify-between'}>
          <Button
            onClick={() =>
              navigate({
                to: '/get-started',
                search: { step: 'brief' },
              })
            }
            variant={'ghost'}
            className={'flex gap-1.5 items-center'}
          >
            <ArrowLeftIcon size={'1rem'} />
            <span>Back</span>
          </Button>
          <OnboardingSteppers stepIndex={2} />
        </div>
        <div>
          <h4 className={'text-2xl text-themeforeground font-semibold mb-2'}>
            How did we meet?
          </h4>
          <p>Where did you first discover CreativeOS?</p>
        </div>
      </div>
      <div>
        <Form {...props.form}>
          <form
            onSubmit={props.form.handleSubmit(onSubmit)}
            className="space-y-6"
          >
            <div className={'flex flex-col gap-5'}>
              <FormField
                control={props.form.control}
                name="leads"
                render={({ field }) => (
                  <RadioGroup
                    onValueChange={field.onChange}
                    value={field.value}
                    className="flex flex-col space-y-1"
                  >
                    {leads &&
                      leads.map((item) => (
                        <FormControl key={item.id}>
                          <FormItem>
                            <OnboardingRadioGroupItem
                              checked={field.value === item.value}
                              value={item.value}
                              onChange={() => field.onChange(item.value)}
                              id={item.label}
                            >
                              {item.label}
                            </OnboardingRadioGroupItem>
                          </FormItem>
                        </FormControl>
                      ))}
                  </RadioGroup>
                )}
              />
            </div>
            <div className={'flex justify-end items-center space-x-2'}>
              <Button
                type={'button'}
                className={'px-8'}
                variant={'outline'}
                size={'lg'}
                onClick={() =>
                  navigate({
                    to: '/feeds/templates',
                    search: {
                      orderFilter: 'Recent',
                      cacheBuster: Math.random(),
                      getStarted: 'ready',
                    },
                  })
                }
                disabled={isPending}
              >
                {'Skip'}
              </Button>
              <Button
                type={'submit'}
                className={'px-8'}
                variant={'default'}
                size={'lg'}
                disabled={isPending}
                loading={isPending}
              >
                {isPending ? 'Submitting...' : 'Finish'}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
