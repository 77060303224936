import FreeTrialEnding from '@/components/FreeTrialEnding';
import SearchDialog from '@/components/SearchDialog';
import { Text } from '@/components/custom-components';
import { Button } from '@/components/shadcn/button';
import { featureFlagKeys } from '@/utils/data/featureFlags';
import { PRO_PLAN, STANDARD_PLAN } from '@/utils/data/plans';
import { trpc } from '@/utils/trpc';
import { Search } from '@mynaui/icons-react';
import { useRouter } from '@tanstack/react-router';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
export const CreativeOsHeader = ({
  sidebarCollapsed,
}: {
  sidebarCollapsed: boolean;
}) => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const searchFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.search);

  const { data: permissionData } = trpc.permissions.useQuery(null, {});

  const showProButton =
    permissionData?.usersPlans &&
    !(
      permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
      permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
    );

  const router = useRouter();

  useEffect(() => {
    const unsubscribe = router.history.subscribe(() => {
      setSearchOpen(false);
    });

    return () => unsubscribe();
  }, [router]);

  return (
    <>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      {searchOpen && permissionData && (
        <SearchDialog
          open={searchOpen}
          onOpenChange={() => setSearchOpen(false)}
          permissions={permissionData}
        />
      )}
      <div
        className={`flex justify-between items-center flex-wrap gap-3 fixed top-[3rem] py-[0.5rem] lg:top-0 lg:pt-4 lg:pb-3 w-[calc(100vw-1.5rem)] ${sidebarCollapsed ? 'lg:w-[calc(100vw-6rem)]' : 'lg:w-[calc(100vw-17rem)]'} z-20 bg-themebackground dark:bg-themebackgrounddark `}
      >
        <div className={'hidden lg:flex flex-1'}></div>
        {searchFlagEnabled ? (
          <Button
            variant={'secondary'}
            className="px-2 py-0 flex gap-4 items-center justify-between bg-searchBg h-8 rounded-full lg:max-w-72 w-full"
            onClick={() => setSearchOpen(true)}
          >
            <Search className="h-5 w-5 " />
            <Text weight={'medium'} size="sm">
              Discovery
            </Text>
          </Button>
        ) : null}
        <div className={'hidden lg:flex lg:flex-1'}>
          {showProButton && (
            <div className="flex gap-3 items-center justify-end w-full">
              <Text className="w-fit sm:flex hidden">
                {permissionData?.usersPlans.includes(STANDARD_PLAN.plan_id)
                  ? 'Go Pro. Supercharge your creativity.'
                  : ''}
              </Text>
              <Button
                onClick={() => setUpgradeDialogOpen(true)}
                variant={'destructivePurple'}
                size={'sm'}
              >
                Get Pro
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
